import store from "store";
import isNil from "lodash/isNil";

const clientStorage = {
    setItem: (key, value) => {
        if (isNil(key) || isNil(value)) {
            console.trace("Error: Invalid key or and value", key, value);
            return false;
        }

        store.set(key, value);

        return { key: value };
    },

    removeItem: (key) => {
        if (typeof key !== "string") {
            console.trace("Error: Invalid or missing key", key);
            return false;
        }

        store.remove(key);

        return key;
    },

    getItem: (key) => {
        if (typeof key !== "string") {
            console.trace("Error: Invalid or missing key", key);
            return false;
        }

        return store.get(key);
    },
};

const clientSessionStorage = {
    setItem: (key, value) => {
        if (isNil(key) || isNil(value)) {
            console.trace("Error: Invalid key or and value", key, value);
            return false;
        }

        sessionStorage.setItem(key, value);

        return { key: value };
    },

    removeItem: (key) => {
        if (typeof key !== "string") {
            console.log("Error: Invalid or missing key", key);
            return false;
        }

        sessionStorage.remove(key);

        return key;
    },

    getItem: (key) => {
        if (typeof key !== "string") {
            console.log("Error: Invalid or missing key", key);
            return false;
        }

        return sessionStorage.getItem(key);
    },
};

export { clientStorage as default, clientSessionStorage };
