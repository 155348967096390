<template>
    <modal id="life-time-warranty-modal" hide-footer hide-header>
        <div class="header-section">
            <b-img alt="Carsaver LTW logo" :src="carsaverLtwLogo" class="logo" />
            <primary-link class="position-relative link" @click.native="closeWindow">
                <b-img :src="close" class="close-icon" />
            </primary-link>
            <div class="header">
                <p class="header-title">{{ $t("home.lifeTimeWarranty.modal.title") }}</p>
                <p class="header-sub-title">{{ $t("home.lifeTimeWarranty.modal.subtitle") }}</p>
            </div>
        </div>

        <div class="body-content d-flex flex-column">
            <div>
                <p class="content-title">Benefits</p>
                <ul class="content">
                    <li v-for="(valueProp, index) in $t('home.lifeTimeWarranty.items')" :key="index">
                        {{ valueProp }}
                    </li>
                </ul>
            </div>
            <div class="mt-2">
                <p class="content-title">We've got you covered, for life!</p>
                <p class="content">
                    As vehicle technology has become more complex, repair costs have increased. A new engine can cost
                    more than $3,000, and a new transmission can cost over $2,500 - not to mention how much it costs to
                    fix or replace seals and gaskets, engine mounts, exhaust manifolds, wheel bearings, drive shafts,
                    and many other components.
                </p>
                <p class="content content-ltw-covers">
                    CarSaver's Lifetime Warranty* covers these repairs and more on your engine, transmission, and
                    drivetrain for unlimited miles and unlimited time with no deductible.
                </p>
                <p class="content non-mobile">
                    For questions, please call a CarSaver Personal Shopper at
                    <primary-link class="highlight-text" :href="'tel:' + supportPhoneNo">
                        {{ supportPhoneNo }}
                    </primary-link>
                    or visit our
                    <primary-link class="highlight-text" :href="faqLink" target="_blank">
                        Frequently Asked Questions
                    </primary-link>
                    page.
                </p>
            </div>
            <secondary-btn class="w-100 mt-4" @click.native="closeWindow">Close</secondary-btn>
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal";
import SecondaryBtn from "Components/Buttons/SecondaryBtn";
import PrimaryLink from "Components/Links/primaryLink";
import Close from "./images/close.png";
import CarsaverLtwLogo from "./images/carsaver-ltw-logo.png";
import { get } from "vuex-pathify";

export default {
    name: "LifetimeWarrantyModal",
    components: { SecondaryBtn, Modal, PrimaryLink },
    data() {
        return {
            close: Close,
            carsaverLtwLogo: CarsaverLtwLogo,
        };
    },
    computed: {
        campaignDomain: get("campaign/domain"),
        campaignURL() {
            return "https://" + this.campaignDomain;
        },
        faqLink() {
            return this.campaignURL + "/warranty/faq";
        },
        supportPhoneNo: get("campaign/phoneNumberFormatted"),
    },
    methods: {
        closeWindow() {
            this.$bvModal.hide("life-time-warranty-modal");
        },
    },
};
</script>
<style lang="scss">
#life-time-warranty-modal {
    .modal-dialog {
        max-width: 500px !important;

        .modal-content {
            padding: 0 !important;

            .modal-body {
                padding: 0 32px 24px 32px !important;
                @include media-breakpoint-down(sm) {
                    padding: 0 16px 20px 16px !important;
                }
            }
        }
    }

    .header-section {
        position: absolute;
        width: 100%;
        height: 300px;
        left: 0;
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        padding: 0 32px 24px 32px;
        @include media-breakpoint-down(sm) {
            padding: 0 16px 20px 16px !important;
        }

        @include themify($themes) {
            background-image: themed("ltw-modal-hero-lg");

            @include media-breakpoint-down(sm) {
                background-image: themed("ltw-modal-hero-sm");
                height: 240px !important;
            }
        }

        .logo {
            max-width: 205px;
            height: auto;
            left: 0;
            position: absolute;
            z-index: 3;

            @include media-breakpoint-down(sm) {
                width: 173px !important;
            }
        }

        .close-icon {
            height: 14px;
            width: 14px;
            color: $white;

            @include media-breakpoint-down(sm) {
                height: 10px !important;
                width: 10px !important;
            }
        }

        .link {
            z-index: 3;
            top: 10px;
            right: 10px;
            position: absolute !important;
        }

        .header {
            height: 130px;
            padding: 20px 32px;
            position: absolute;
            bottom: 0;
            left: 0;
            color: $white;
            background-color: rgb(0, 0, 0, 0.5);

            @include media-breakpoint-down(sm) {
                height: 122px !important;
                padding: 12px 16px 20px 16px !important;
            }

            .header-title {
                font-size: px2rem(16);
                line-height: 1.15;
                font-weight: 700;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 8px !important;
                }
            }
            .header-sub-title {
                font-size: px2rem(14);
                line-height: 1.15;
            }
        }
    }

    .body-content {
        line-height: 1.4;
        padding-top: 320px;
        color: $gray-700;

        @include media-breakpoint-down(sm) {
            padding-top: 260px !important;
        }

        .content-title {
            font-size: px2rem(16);
            line-height: 1.15;
            font-weight: 700;
            margin-bottom: 8px;

            @include media-breakpoint-down(sm) {
                font-size: px2rem(14);
                line-height: 1.15;
            }
        }

        .content {
            margin-top: 8px !important;
            margin-bottom: 0 !important;
            font-size: px2rem(14);
            line-height: 1.15;

            @include media-breakpoint-down(sm) {
                margin-bottom: 8px !important;
            }

            li {
                margin-bottom: 5px;
                margin-left: -18px;
                @include media-breakpoint-down(sm) {
                    margin-bottom: 0 !important;
                }
            }

            .highlight-text {
                color: #011e41;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .content-ltw-covers {
            @include media-breakpoint-down(sm) {
                margin-top: 16px !important;
            }
        }
    }
}
</style>
