<template>
    <modal id="new-promo-modal-bmw" divider hide-footer hide-header>
        <div class="lender-hero-image">
            <primary-link class="position-relative link" @click="closeWindow">
                <i aria-hidden="true" class="close-icon material-icons close-btn" @click="closeWindow"> close </i>
            </primary-link>
        </div>
        <div class="header">
            <p class="text-center title">Exclusive BMW Financial Services Loyalty Offers</p>
            <div class="sub-title">
                Enjoy programs specially designed for returning owners from BMW Financial Services.
            </div>
            <hr class="divider mt-3 mb-4" />
        </div>
        <div class="body-content">
            <div class="body-content-list-items">
                <div class="text-center">
                    <ExclusiveSavingsIcon class="icon" />
                    <div class="mt-3 sub-title-text">
                        <span><b>Exclusive Savings</b> on Select New and Pre-Owned BMWs.</span>
                    </div>
                </div>
                <div class="text-center">
                    <CostIcon class="icon" />
                    <div class="mt-3 sub-title-text">
                        <span><b>Discounted Loyalty Lease Rates</b> on Select New BMWs.</span>
                    </div>
                </div>
                <div class="text-center">
                    <LoyaltyIcon class="icon" />
                    <div class="mt-3 sub-title-text">
                        <span><b>Loyalty Credits</b> on Select New BMW’s Leased or Retail Financed.</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div v-if="!hidePromotionalModalCTAs" class="w-100">
                <!--        Show button if pin form is enabled-->
                <primary-btn
                    v-if="showGetStartedComponent"
                    class="get-started-btn w-100"
                    @click.native="onGetStartedBtn"
                >
                    View Your Upgrade Offers
                </primary-btn>
                <!--        Hide button if pin form is disabled-->
                <get-started-btn
                    v-else
                    class="get-started-btn w-100"
                    text="View Your Upgrade Offers"
                    @click.native="onGetStartedBtn"
                />
            </div>
            <secondary-btn class="mb-3" @click.native="closeWindow"> Close</secondary-btn>
        </div>
        <div class="disclaimer">
            <span>
                *Programs only apply to specific, new vehicles. Qualifying new vehicles must be leased or retail
                financed through BMW Financial Services, LLC. Interest will accrue during the payment deferral period.
                Customer needs to meet the credit requirements as specified for BMW Financial Services, LLC .
            </span>
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal";
import PrimaryLink from "Components/Links/primaryLink";
import SecondaryBtn from "Components/Buttons/SecondaryBtn";
import ExclusiveSavingsIcon from "../PromotionsModal/images/ExclusiveSavingsIcon";
import CostIcon from "../PromotionsModal/images/CostIcon";
import { get } from "vuex-pathify";
import LoyaltyIcon from "../PromotionsModal/images/LoyaltyIcon";
import GetStartedBtn from "./../../Home/GetStartedBtn";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";
import MfaRedirectionMixin from "Modules/Home/components/Home/MfaRedirectionMixin.vue";

export default {
    name: "NewPromoModalBmw",
    components: {
        PrimaryBtn,
        GetStartedBtn,
        Modal,
        PrimaryLink,
        ExclusiveSavingsIcon,
        CostIcon,
        SecondaryBtn,
        LoyaltyIcon,
    },
    mixins: [MfaRedirectionMixin],
    computed: {
        programId: get("campaign/programId"),
        isBMWProgram() {
            return CampaignProgramEnum.BMW.indexOf(this.programId) !== -1;
        },
        showGetStartedComponent: get("context/displayPinForm"),
        isListingsPage() {
            return this.$route.name === "listings";
        },
        hidePromotionalModalCTAs() {
            // Hide Get started btn for BMW in listings page
            return this.isBMWProgram && this.isListingsPage;
        },
    },
    methods: {
        closeWindow() {
            this.$bvModal.hide("new-promo-modal-bmw");
        },
        onGetStartedBtn() {
            const filters = { stockTypeConditions: ["NEW"] };
            this._mixin_handleRedirectionsWithFilters(filters);
        },
    },
};
</script>
<style lang="scss">
#new-promo-modal-bmw {
    .modal-dialog {
        margin: 8.75rem auto !important;
        @include media-breakpoint-down(sm) {
            margin: 90px 16px 0 16px !important;
        }
        .modal-content {
            padding: 0 !important;

            .modal-body {
                padding: 0 16px 30px 16px !important;
                @include media-breakpoint-down(sm) {
                    padding: 0 16px 16px 16px !important;
                }
            }
        }
    }
    .lender-hero-image {
        position: absolute;
        width: 100%;
        height: 120px;
        left: 0;
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        padding-left: 16px;
        @include themify($themes) {
            background-image: themed("promotional-banner-lg");

            @include media-breakpoint-down(sm) {
                background-image: themed("promotional-banner-md");
            }
        }
    }

    .lender-hero-image:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        background: linear-gradient(89.94deg, #ffffff 0.04%, rgba(217, 217, 217, 0) 79.85%);
    }

    .header {
        padding-top: 140px;
        @include media-breakpoint-down(sm) {
            padding-top: 134px;
        }
        .title {
            font-size: px2rem(28);
            @include media-breakpoint-down(sm) {
                font-size: px2rem(26);
            }
        }
        .sub-title {
            text-align: center;
            line-height: 1.3;
            font-size: px2rem(15);
            @include media-breakpoint-down(sm) {
                line-height: 1.2;
            }
        }
    }

    .body-content {
        line-height: 1.4;
        margin-bottom: px2rem(32);
        @include media-breakpoint-down(sm) {
            margin-bottom: px2rem(28);
        }

        .body-content-list-items {
            display: grid;
            grid-template-columns: repeat(3, minmax(0, 1fr));
            gap: 16px;
        }
    }

    .sub-title-text {
        text-align: center;
        line-height: 1.3;

        @include themify($themes) {
            font-size: themed("promotional-font-size");
        }
    }

    .logo {
        max-width: 186px;
        max-height: 60px;
        height: auto;
        position: relative;
        z-index: 3;
    }

    .icon {
        @include themify($themes) {
            height: themed("promotional-icon-size");
            width: themed("promotional-icon-size");
        }
    }

    .get-started-btn {
        margin-bottom: px2rem(5) !important;
    }

    .disclaimer {
        font-size: px2rem(12);
        color: $gray-600;
        font-style: italic;
        line-height: 1.25;
        text-align: justify;
        @include media-breakpoint-down(sm) {
            right: 0;
            left: 0;
            height: auto;
            border: 0;
            overflow: unset;
        }
    }

    .divider {
        width: 178px;
        height: 2px;
        @include themify($themes) {
            background-color: themed("primary-color");
        }
    }

    .link {
        z-index: 3;
        top: 16px;
        right: 16px;
        position: absolute !important;
    }

    .button {
        margin: 0 10px;
    }

    .close-btn {
        height: 24px;
        width: 24px;
        color: black;
    }
}
</style>
