import { render, staticRenderFns } from "./CostIcon.vue?vue&type=template&id=d4d5d544&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/.pnpm/vue-loader@15.10.2_babel-core@6.26.3_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_prett_26klfa342gafietiguuukji5n4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports