<template>
    <b-modal
        ref="modal"
        v-bind="$attrs"
        :centered="isCentered"
        :hide-header-close="hideHeaderClose"
        :aria-label="normAriaLabel"
        :no-close-on-esc="noCloseOnEsc"
        :no-close-on-backdrop="noCloseOnBackdrop"
        v-on="$listeners"
        @hidden="onHiddenHandler"
    >
        <i slot="modal-header-close" class="material-icons" aria-hidden="true"> close </i>
        <template slot="modal-header">
            <div class="modal-titles">
                <slot name="title">
                    <h5 v-if="title" class="modal-title">{{ title }}</h5>
                </slot>
                <slot name="subtitle">
                    <h6 v-if="subtitle" class="modal-subtitle">
                        {{ subtitle }}
                    </h6>
                </slot>
                <slot name="divider">
                    <div v-if="divider" class="d-flex justify-content-center mt-2">
                        <div class="modal-divider"></div>
                    </div>
                </slot>
            </div>
            <button v-if="!hideHeaderClose" type="button" aria-label="Close" class="close" @click="close">
                <i class="material-icons" aria-hidden="true">close</i>
            </button>
            <slot name="header-close"></slot>
        </template>
        <slot />
    </b-modal>
</template>

<script>
export default {
    inheritAttrs: false,

    props: {
        centered: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        hideHeaderClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        divider: {
            type: Boolean,
            required: false,
            default: false,
        },
        ariaLabel: {
            type: String,
            required: false,
            default: null,
        },
        eventOnHidden: {
            type: String,
            required: false,
            default: null,
        },
        noCloseOnEsc: {
            type: Boolean,
            required: false,
            default: false,
        },
        noCloseOnBackdrop: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    computed: {
        isCentered() {
            return this.$mq === "sm" ? true : this.centered;
        },
        normAriaLabel() {
            const label = this.ariaLabel ? this.ariaLabel : this.title;

            return label;
        },
    },
    methods: {
        close() {
            const trigger = "closed";
            this.$refs.modal.hide(trigger);
        },
        hide() {
            this.$refs.modal.hide();
        },
        show() {
            this.$refs.modal.show();
        },
        onHiddenHandler() {
            if (this.eventOnHidden) {
                this.$root.$emit(this.eventOnHidden);
            }
        },
    },
};
</script>
<style lang="scss">
.modal-divider {
    height: 2px;
    width: 128px;
    @include themify($themes) {
        background-color: themed("primary-color");
    }
}
</style>
