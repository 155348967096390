import includes from "lodash/includes";
import isNil from "lodash/isNil";
import lodashGet from "lodash/get";
import api from "@/api";
import { make } from "vuex-pathify";
import loader from "@/api/loader";
import lodashIsNil from "lodash/isNil";

const decoder = (uri) => {
    if (lodashIsNil(uri)) {
        return uri;
    } else {
        return decodeURIComponent(uri);
    }
};

const state = {
    firstName: lodashGet(window, "_CONTEXT.firstName", null),
    lastName: lodashGet(window, "_CONTEXT.lastName", null),
    email: lodashGet(window, "_CONTEXT.email", null),
    phoneNumber: lodashGet(window, "_CONTEXT.phoneNumber", null),
    zipcode: lodashGet(window, "_CONTEXT.zipcode", null),
    noPinProspect: lodashGet(window, "_CONTEXT.noPinProspect", false),
    fullName: lodashGet(window, "_CONTEXT.fullName", null),
    userId: lodashGet(window, "_CONTEXT.userId", null),
    prospectId: lodashGet(window, "_CONTEXT.prospectId", null),
    tradeVehicleId: lodashGet(window, "_CONTEXT.tradeVehicleId", null),
    tradeVehicleClass: lodashGet(window, "_CONTEXT.vehicleClass", null),
    tradeVehicleSubclasses: lodashGet(window, "_CONTEXT.vehicleSubclass", null),
    vehicleQuoteId: lodashGet(window, "_CONTEXT.vehicleQuoteId", null),
    terminalErrorName: lodashGet(window, "_CONTEXT.terminalErrorType.name", null),
    terminalErrorCode: lodashGet(window, "_CONTEXT.terminalErrorType.code", null),
    navBrand: lodashGet(window, "_CONTEXT.navBrand", null),
    disabledPages: lodashGet(window, "_CONTEXT.disabledPages", []),
    environments: lodashGet(window, "_APP_CONFIG.env", []),
    showBackgroundOverlay: false,
    pendingTourPoints: [],
    registeredTourPoints: [],
    isTourPointsRunning: null,
    isSessionTourRunning: null,
    activeTourPoint: "",
    dealCount: {
        data: null,
        loader: loader.defaultState(),
    },
    refererUrl: decoder(lodashGet(window, "_CONTEXT.refererUrl", null)),
    ssoUser: lodashGet(window, "_CONTEXT.ssoUser", null),
    idProvider: lodashGet(window, "_CONTEXT.identityProviderConfigModel.provider", null),
    vehicleOfInterestId: lodashGet(window, "_VEHICLE_OF_INTEREST_ID", null),
    deepLinkPath: lodashGet(window, "_CONTEXT.deepLinkPath", null),
    noProfileDealPreferences: lodashGet(window, "_CONTEXT.noProfileDealPreferences", null),
    pinValidated: lodashGet(window, "_CONTEXT.pinValidated", false),
    userExistForProspect: lodashGet(window, "_CONTEXT.userExistForProspect", false),
    campaign: lodashGet(window, "_CAMPAIGN.name", null),
    dealerId: lodashGet(window, "_CONTEXT.dealerId"),
    dealerPhoneNumber: lodashGet(window, "_CONTEXT.dealerPhoneNumber"),
    selfSelectCreditEnabled: lodashGet(window, "_CONTEXT.selfSelectCreditEnabled", null),
    creditAgency: lodashGet(window, "_CONTEXT.creditAgency", null),
    creditAgencyURI: lodashGet(window, "_CONTEXT.creditAgencyURI", null),
    leaseAppEnabled: lodashGet(window, "_CONTEXT.leaseAppEnabled", null),
    usesApprovedStatus: lodashGet(window, "_CONTEXT.usesApprovedStatus", null),
    autoSendMFA: lodashGet(window, "_CONTEXT.autoSendMFA", false),
    featureFlags: lodashGet(window, "_CONTEXT.featureFlags", null),
    noProfileTradeFlowLogin: lodashGet(window, "_CONTEXT.noProfileTradeLogin", null),
    financierId: lodashGet(window, "_CONTEXT.financierId", null),
    financierName: lodashGet(window, "_CONTEXT.financierName", null),
    financierPayOffPhoneNumber: lodashGet(window, "_CONTEXT.financierPayOffPhoneNumber", null),
    vehicleClasses: lodashGet(window, "_CONTEXT.vehicleClass", null),
    vehicleSubclasses: lodashGet(window, "_CONTEXT.vehicleSubclass", null),
    isMember: lodashGet(window, "_CONTEXT.member", false),
    isMemberDisabled: lodashGet(window, "_CONTEXT.memberDisabled", false),
    chatConfig: {
        data: null,
        loader: loader.defaultState(),
    },
    overlayEnabled: lodashGet(window, "_CONTEXT.overlayEnabled", false) && window.self !== window.top,
    isSellAtHome: lodashGet(window, "_CONTEXT.isSellAtHome", false),
    isLeasePayoffEnabled: lodashGet(window, "_CONTEXT.isLeasePayoffEnabled", false),
    displayPinForm: false,
    dealersWebsite: lodashGet(window, "_CONTEXT.dealersWebsite", false),
    payoffUpdatedInfo: lodashGet(window, "_CONTEXT.payoffUpdatedInfo", null),
};

const mutations = {
    ...make.mutations(state),

    SET_DEAL_COUNT_LOADER: (state, payload) => {
        state.dealCount.loader = payload;
    },

    SET_DEAL_COUNT: (state, payload) => {
        state.dealCount.data = payload;
    },
    SET_CHAT_CONFIG_LOADER: (state, payload) => {
        state.chatConfig.loader = payload;
    },
    SET_CHAT_CONFIG: (state, payload) => {
        state.chatConfig.data = payload;
    },
    SET_DISPLAY_PIN_FORM: (state, payload) => {
        state.displayPinForm = payload;
    },
};

const getters = {
    ...make.getters(state),
    isProspect: (state) => !isNil(state.prospectId),
    isLoggedIn: (state) => !isNil(state.userId),
    pinValidated: (state) => state.pinValidated,
    isPageEnabled: (state) => (pageId) => {
        return !includes(state.disabledPages, pageId);
    },
    displayPinForm: (state) => {
        return state.displayPinForm;
    },
};

const actions = {
    ...make.actions(state),

    login({ commit }, { email, password }) {
        return api
            .post("/auth/login", {
                email,
                password,
            })
            .then((response) => {
                const success = lodashGet(response, "data.success", false);

                if (success) {
                    commit("SET_USER_ID", lodashGet(response, "data.user.id"));
                    commit("SET_FIRST_NAME", lodashGet(response, "data.user.firstName"));

                    return true;
                }

                return false;
            });
    },

    fetchSavedDealCount({ commit, getters }) {
        commit("SET_DEAL_COUNT_LOADER", loader.started());
        if (getters.isLoggedIn) {
            api.get("/garage/deals/count")
                .then((response) => {
                    const dealCount = lodashGet(response, "data");
                    commit("SET_DEAL_COUNT", dealCount);
                    commit("SET_DEAL_COUNT_LOADER", loader.successful());
                })
                .catch((error) => {
                    commit("SET_DEAL_COUNT_LOADER", loader.error(error));
                });
        } else {
            commit("SET_DEAL_COUNT_LOADER", loader.successful());
        }
    },

    updateLoginIndicator({ state }, value) {
        return api.post(`/trade/no-profile/user/account?indicator=${value}`);
    },

    updateSellAtHomeIndicator({ state }, value) {
        return api.post(`/trade/no-profile/sell-indicator?indicator=${value}`);
    },

    fetchChatConfig({ commit, state }, dealerId) {
        commit("SET_CHAT_CONFIG_LOADER", loader.started());
        const result = api
            .get(`/dealer_chat_config/dealer/${dealerId}`)
            .then((response) => {
                commit("SET_CHAT_CONFIG", response.data);
                commit("SET_CHAT_CONFIG_LOADER", loader.successful());
            })
            .catch((error) => {
                console.error(error);
                commit("SET_CHAT_CONFIG", loader.error(error));
            });

        return result;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
