<!--
  * This is the Tour Point Orchestrator / Controller
  * tour points are displayed in order by adding using this.addTourPoints(["name1", "name2", ...]) or
  * use this.activateTourPoints(["name1", "name2", ...]) to start tour immediately
  * available tour points: (keep current)
  *     ["contact-carsaver","my-deal","post-save-slide-down-menu","filters","deal-down-payment-recalculate]
  * To start tour, use this.activateTourPoints([list of tour points])
  *
  * note: use prefix _session on the tour point name (ex. _session_chat_tourpoint)
  *       to add to Session Storage instead of Localstorage
  * Example for activating tour points:
  * data() {
  *      return {
  *          tourPoints: ["deal-down-payment-recalculate"],
  *      };
  *  },
  * mounted() {
  *     this.activateTourPoints(this.tourPoints);
  *  },
  *  beforeDestroy() {
  *      this.removeTourPoints(this.tourPoints);
  *  },
 -->
<script>
import { clientSessionStorage, default as clientStorage } from "@/libs/clientStorage";
import { sync } from "vuex-pathify";
import lodashIsEmpty from "lodash/isEmpty";
import lodashRemove from "lodash/remove";
import lodashUniq from "lodash/uniq";
import lodashDifference from "lodash/difference";

export default {
    name: "TourPointMixin",
    data() {
        return {
            //utilities do not override
            clientStorageTourPointsName: "completedTourPoints",
            TourPointMixin_sessionPrefixRegex: /^_session/,
        };
    },

    computed: {
        showBackgroundOverlay: sync("context/showBackgroundOverlay"),
        activeTourPoint: sync("context/activeTourPoint"),
        isTourPointsRunning: sync("context/isTourPointsRunning"),

        //internal use only
        registeredTourPoints: sync("context/registeredTourPoints"),

        //add to enable tour point using addTourPoints()
        //populate with tour names ie. ["my-deal","post-save-slide-down-menu"]
        pendingTourPoints: sync("context/pendingTourPoints"),
    },

    watch: {
        activeTourPoint: function (newVal, oldVal) {
            if (newVal) {
                this.showBackgroundOverlay = true;
            } else {
                this.showBackgroundOverlay = false;
            }
        },
    },

    methods: {
        // This is all that's needed to start tour points
        // list param is optional, you can use the methods
        // addTourPoint("name") or addTourPoints([..."names"])
        activateTourPoints(list) {
            if (list) {
                this.addTourPoints(list);
            }

            if (this.isTourPointsRunning) {
                // already running

                if (lodashIsEmpty(this.activeTourPoint)) {
                    this.nextTourPoint();
                }
                return;
            }
            setTimeout(this.initTourPoints(), 2000);
        },

        // for debugging ONLY
        registerTourPoint(tourPointName) {
            if (typeof tourPointName !== "string") {
                console.trace("Error registerTourPoint() expected type String instead received: ", tourPointName);
            }

            this.registeredTourPoints = [...this.registeredTourPoints, tourPointName];
        },
        addTourPoints(newTourPoints) {
            let tourPoints;

            if (Array.isArray(newTourPoints) === false) {
                console.trace("addTourPoints expected type Array but instead received: ", newTourPoints);
                return false;
            }

            tourPoints = lodashUniq(this.pendingTourPoints.concat(newTourPoints));
            tourPoints = this.removeActiveTourPoint(tourPoints);
            this.pendingTourPoints = this.removeCompletedTourPoints(tourPoints);
        },

        addTourPoint(newTourPoint) {
            if (typeof newTourPoint !== "string") {
                console.trace("addTourPoint expected type String but instead received: ", newTourPoint);

                return false;
            }

            if (this.pendingTourPoints.indexOf(newTourPoint) === -1 && newTourPoint !== this.activeTourPoint) {
                // currentList.unshift(newTourPoint);
                this.pendingTourPoints = this.pendingTourPoints.concat(newTourPoint);
            }
        },

        initTourPoints() {
            const previouslyCompletedTourPoints = this.getPreviouslyCompletedTourPoints();
            this.isTourPointsRunning = true;

            if (!lodashIsEmpty(previouslyCompletedTourPoints)) {
                //updates list of pending tour points
                this.pendingTourPoints = this.getUndoneTourPoints();
            }

            this.startTourPoints();
        },

        startTourPoints() {
            let pendingList = [...this.pendingTourPoints];

            if (lodashIsEmpty(this.pendingTourPoints)) {
                //no pending tour points
            } else {
                //starts remaining tour points
                this.activeTourPoint = pendingList.shift();
                this.pendingTourPoints = pendingList;

                // for debugging purposes
                if (!this.isTourPointRegistered(this.activeTourPoint)) {
                    console.log("activeTourPoint not yet registered ", this.activeTourPoint, this.registeredTourPoints);
                }
            }
        },

        isTourPointRegistered(name) {
            return this.registeredTourPoints.indexOf(name) !== -1;
        },

        nextTourPoint() {
            let pendingList = [...this.pendingTourPoints];

            if (!lodashIsEmpty(this.activeTourPoint)) {
                if (this.TourPointMixin_sessionPrefixRegex.test(this.activeTourPoint)) {
                    this.setInSessionStorage(this.activeTourPoint);
                } else {
                    this.setInClientStorage(this.activeTourPoint);
                }
            }

            if (lodashIsEmpty(this.pendingTourPoints)) {
                //finished
                this.activeTourPoint = "";
                this.showBackgroundOverlay = false;
                this.isTourPointsRunning = false;
            } else {
                //move to the next tour point
                this.activeTourPoint = pendingList.shift();
                this.pendingTourPoints = pendingList;
            }
        },
        removeTourPoints(list) {
            if (typeof list !== "undefined") {
                list.forEach((tourPoint) => {
                    this.removeTourPoint(tourPoint);
                });
            }
        },
        removeTourPoint(name) {
            let pending = [...this.pendingTourPoints];

            lodashRemove(pending, function (tourPoint) {
                return tourPoint === name;
            });

            if (this.activeTourPoint === name) {
                this.activeTourPoint = pending.shift();
            }

            this.pendingTourPoints = pending;
        },

        //checks both localstorage and session for previous completed tour points
        getPreviouslyCompletedTourPoints() {
            let completedTourPoints = clientStorage.getItem(this.clientStorageTourPointsName);
            let completedSessionTourPoints = this.getPreviouslyCompletedSessionTourPoints();

            completedTourPoints = completedTourPoints ? completedTourPoints : [];

            return [...completedTourPoints, ...completedSessionTourPoints];
        },

        //checks session storage for previous completed tour points
        getPreviouslyCompletedSessionTourPoints() {
            // A string of tourpoints separated by commas
            // ex "_session-contact-us,_session-downpayment,_session-save-btn"
            const completedTourPoints = clientSessionStorage.getItem(this.clientStorageTourPointsName);
            let currentList = [];

            if (completedTourPoints) {
                currentList = completedTourPoints.split(",");
            }

            return currentList;
        },

        setInClientStorage(completedTourPoint) {
            const currentList = this.getPreviouslyCompletedTourPoints();

            clientStorage.setItem("completedTourPoints", [...currentList, completedTourPoint]);
        },

        setInSessionStorage(completedTourPoint) {
            const sessionTourPoints = this.getPreviouslyCompletedSessionTourPoints();
            let sessionListToStr;

            sessionTourPoints.push(completedTourPoint);
            sessionListToStr = sessionTourPoints.join(",");
            clientSessionStorage.setItem("completedTourPoints", sessionListToStr);
        },

        getUndoneTourPoints() {
            const completedTourPoints = this.getPreviouslyCompletedTourPoints();

            return lodashDifference(this.pendingTourPoints, completedTourPoints);
        },

        removeCompletedTourPoints(tourPoints) {
            return lodashDifference(tourPoints, this.getPreviouslyCompletedTourPoints());
        },

        removeActiveTourPoint(tourPoints) {
            return lodashDifference(tourPoints, [this.activeTourPoint]);
        },

        tourUtilScrollToTop() {
            window.scrollTo(0, 0);
        },
    },
};
</script>
