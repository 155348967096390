<template>
    <div>
        <modal :id="id" size="sm" title="How can we help?" centered hide-footer no-stacking @show="onShow">
            <personal-shopper
                name="Claire R."
                :employment-start-year="2016"
                :photo="personalShopperPhoto"
                spanish-speaking
            />

            <div v-if="userIsLoggedIn" class="pb-2">
                <primary-btn
                    block
                    type="submit"
                    class="d-flex align-items-center justify-content-center"
                    :disabled="submittingAny"
                    :loading="submittingCallMeNow"
                    @click.native="handleCallMeNow"
                >
                    <i class="icon material-icons md-16" aria-hidden="true">call</i>
                    <span>Call Now</span>
                </primary-btn>
            </div>
            <div v-if="userIsLoggedIn" class="pb-2">
                <primary-btn
                    block
                    type="submit"
                    class="d-flex align-items-center justify-content-center"
                    :disabled="submittingAny"
                    :loading="submittingCallMeLater"
                    @click.native="handleCallMeLater"
                >
                    <i class="icon material-icons md-16" aria-hidden="true">call</i>
                    <span>Schedule a Call</span>
                </primary-btn>
            </div>
            <div class="pb-2">
                <b-button
                    v-b-modal.email-modal
                    variant="primary"
                    :disabled="submittingAny"
                    block
                    class="d-flex align-items-center justify-content-center"
                >
                    <i class="icon material-icons md-16" aria-hidden="true">email</i>
                    <span>Email</span>
                </b-button>
            </div>
            <div v-if="isError" class="d-block invalid-feedback text-center">
                Sorry, there was an unexpected error, please try again shortly.
            </div>
            <!-- TODO: implement chat function -->
            <div v-if="chatEnabled">
                <b-button
                    variant="secondary"
                    block
                    class="d-flex align-items-center justify-content-center white-text"
                    @click.prevent="chat"
                >
                    <i class="icon material-icons md-16" aria-hidden="true">chat_bubble</i>
                    <span>Chat</span>
                </b-button>
            </div>
        </modal>
        <contact-shopper-modal id="email-modal" :certificate-id="certificateId" />
        <call-me-now-modal id="call-me-now" :certificate-id="certificateId" />
        <call-me-now-modal-not-logged-in id="call-me-now-not-logged-in" />
        <call-me-later-modal id="call-me-later" :queue-id="upcomingCallBackId" :certificate-id="certificateId" />
        <call-already-scheduled-modal
            v-if="upcomingCallBack"
            id="call-already-scheduled"
            :upcoming-call-back="upcomingCallBack"
            @reschedule="handleReschedule"
        />
    </div>
</template>

<script>
import Modal from "Components/Modal";
import PersonalShopper from "./components/PersonalShopper";
import PersonalShopperImage from "./images/Claire.png";
import ContactShopperModal from "./components/ContactShopperModal";
import CallMeNowModal from "./components/CallMeNowModal";
import CallMeNowModalNotLoggedIn from "./components/CallMeNowModalNotLoggedIn";
import CallMeLaterModal from "./components/CallMeLaterModal";
import CallAlreadyScheduledModal from "./components/CallAlreadyScheduledModal";
import api from "@/api";
import { get } from "vuex-pathify";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import lodashGet from "lodash/get";
import lodashIsEmpty from "lodash/isEmpty";

export default {
    name: "ChatModal",
    components: {
        PrimaryBtn,
        CallAlreadyScheduledModal,
        CallMeNowModal,
        CallMeNowModalNotLoggedIn,
        CallMeLaterModal,
        Modal,
        PersonalShopper,
        ContactShopperModal,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: "chat-modal",
        },
        certificateId: {
            type: Number,
            required: false,
            default: null,
        },
    },
    data: function () {
        return {
            personalShopperPhoto: PersonalShopperImage,
            upcomingCallBack: null,
            chatEnabled: false,
            isError: false,
            submittingCallMeNow: false,
            submittingCallMeLater: false,
        };
    },
    computed: {
        upcomingCallBackId() {
            return lodashGet(this.upcomingCallBack, "queueId", null);
        },
        submittingAny() {
            return this.submittingCallMeNow || this.submittingCallMeLater;
        },
        userIsLoggedIn: get("context/isLoggedIn"),
    },
    methods: {
        chat() {
            this.announceNoOp();
        },
        announceNoOp() {
            alert("Not implemented");
        },
        onShow() {
            this.reset();
        },
        reset() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        loadUpcomingCall() {
            const userTimeZone = this.$moment.tz.guess(true);
            return api
                .get("/lead/contact-shopper/call-me-back/upcoming-call", {
                    userTimeZone,
                })
                .then((resp) => {
                    this.upcomingCallBack = resp.data;
                });
        },
        handleCallMeNow() {
            this.submittingCallMeNow = true;
            if (this.userIsLoggedIn) {
                this.loadUpcomingCall()
                    .then(() => {
                        if (!lodashIsEmpty(this.upcomingCallBack)) {
                            this.$bvModal.show("call-already-scheduled");
                        } else {
                            this.$bvModal.show("call-me-now");
                        }
                    })
                    .catch((err) => {
                        this.isError = true;
                        console.error("error in call me now handler", err);
                    })
                    .finally(() => (this.submittingCallMeNow = false));
            } else {
                this.$bvModal.show("call-me-now-not-logged-in");
            }
        },
        handleCallMeLater() {
            this.submittingCallMeLater = true;
            this.loadUpcomingCall()
                .then(() => {
                    if (!lodashIsEmpty(this.upcomingCallBack)) {
                        this.$bvModal.show("call-already-scheduled");
                    } else {
                        this.$bvModal.show("call-me-later");
                    }
                })
                .catch((err) => {
                    this.isError = true;
                    console.error("error in call me now handler", err);
                })
                .finally(() => (this.submittingCallMeLater = false));
        },
        handleReschedule() {
            this.$bvModal.hide("call-already-scheduled");
            this.$bvModal.show("call-me-later");
        },
    },
};
</script>

<style lang="scss" scoped>
.material-icons.md-16 {
    font-size: px2rem(16);
    padding-right: 2px;
}
.white-text {
    color: white;
}
</style>
