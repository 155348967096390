<template>
    <base-btn :size="size" variant="secondary" :block="block" :loading="loading">
        <slot> message</slot>
    </base-btn>
</template>

<script>
import baseBtn from "Components/Buttons/BaseBtn";

export default {
    name: "SecondaryBtn",
    components: { baseBtn },
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        size: {
            type: String,
            required: false,
            default: "",
        },
        block: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style lang="scss" scoped></style>
