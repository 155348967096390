<template>
    <div v-if="show" class="header-banner">
        <b-container>
            <slot></slot>
            <button type="button" title="Close Banner" class="close-btn px-lg-4" @click="closeBanner">
                <i class="material-icons icon" aria-hidden="true">close</i>
            </button>
        </b-container>
    </div>
</template>

<script>
export default {
    name: "PromotionsBannerContainer",
    props: {
        name: {
            type: String,
            required: true,
        },
        showBanner: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            show: this.showBanner,
        };
    },
    methods: {
        closeBanner() {
            this.show = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.header-banner {
    font-size: px2rem(14);
    line-height: 1.3;
    padding: 12px;
    z-index: 1000;

    .close-btn {
        display: flex;
        position: absolute;
        top: 10px;
        right: 0;
        background: 0 0;
        border: none;
        padding-right: 10px;
        color: #444;

        .icon {
            font-size: px2rem(16);
            @include themify($themes) {
                color: themed("promotions-banner-text-color");
            }
        }
    }
}
</style>
