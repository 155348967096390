<template>
    <div v-if="isGoogleOneTapFeatureEnabled">
        <div
            id="g_id_onload"
            data-client_id="720618161865-828kr9r3vlio70p9lvqvp6hl5ov49bsp.apps.googleusercontent.com"
            data-context="signin"
            data-ux_mode="popup"
            data-login_uri="/login/oauth2/code/google"
            data-itp_support="true"
            :data-redirect_path="redirectPath"
        ></div>

        <div
            class="g_id_signin"
            data-type="standard"
            data-shape="rectangular"
            data-theme="outline"
            data-text="signin_with"
            data-size="large"
            data-logo_alignment="left"
        ></div>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
import lodashGet from "lodash/get";

export default {
    name: "GoogleOneTap",
    props: {
        redirectPath: {
            type: String,
            required: false,
            default: "",
        },
    },
    computed: {
        featureFlags: get("context/featureFlags"),
        isGoogleOneTapFeatureEnabled() {
            const result = lodashGet(this.featureFlags, "GOOGLE_ONE_TAP_FEATURE", false) || false;
            return result;
        },
    },
    mounted() {
        this.loadGoogleSignInScript();
    },
    destroyed() {
        this.removeGoogleSignInScript();
    },
    methods: {
        loadGoogleSignInScript() {
            // Create a script element
            const script = document.createElement("script");

            // Set the src attribute to the Google Sign-In script URL
            script.src = "https://accounts.google.com/gsi/client";

            // Set the async attribute
            script.async = true;

            // Append the script to the document body
            document.body.appendChild(script);
        },
        removeGoogleSignInScript() {
            // Remove the script element if it exists
            const scriptElement = document.querySelector('script[src="https://accounts.google.com/gsi/client"]');
            if (scriptElement) {
                scriptElement.parentNode.removeChild(scriptElement);
            }
        },
    },
};
</script>
