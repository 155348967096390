<template>
    <div v-if="showInventoryImage" class="deal-image">
        <b-img :src="src" v-bind="$attrs" :alt="alt" @error="handleInventoryImageError" />
    </div>

    <div v-else-if="showStockImage" class="deal-image">
        <b-img :src="stockSrc" v-bind="$attrs" :alt="alt" @error="handleStockImageError" />
    </div>

    <div v-else class="deal-image">
        <body-style-icon :body-style="bodyStyle" />
    </div>
</template>
<script>
/**
 * Used to display vehicle images:
 *
 * - Use Homenet Image (src) if provided. If not provided or there is an error loading it then
 * - Fall back to Stock Image (stockSrc). If not provided or there is an error loading it then
 * - Fall back to body style icon
 */
import BodyStyleIcon from "@carsaver/body-style-icon";
import lodashIsNil from "lodash/isNil";
export default {
    name: "VehicleImage",
    components: {
        BodyStyleIcon,
    },

    inheritAttrs: false,

    props: {
        alt: {
            type: String,
            required: false,
            default: "vehicle image",
        },
        src: {
            type: String,
            required: false,
            default: null,
        },
        stockSrc: {
            type: String,
            required: false,
            default: null,
        },
        bodyStyle: {
            type: String,
            required: false,
            default: "sedan",
        },
    },

    data() {
        return {
            inventoryImageHasError: false,
            stockImageHasError: false,
        };
    },

    computed: {
        showInventoryImage() {
            return !lodashIsNil(this.src) && !this.inventoryImageHasError;
        },
        showStockImage() {
            return !lodashIsNil(this.stockSrc) && !this.stockImageHasError;
        },
    },

    methods: {
        handleStockImageError() {
            this.stockImageHasError = true;
        },
        handleInventoryImageError() {
            this.inventoryImageHasError = true;
        },
    },
};
</script>
<style lang="scss">
.deal-image {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    img {
        width: 100%;
    }

    i {
        display: inline;
        height: auto;
        max-width: 100%;
        font-size: px2rem(35);
    }
}
</style>
