import lodashIsNil from "lodash/isNil";
import Vue from "vue";

Vue.filter("phoneFormatter", function (phoneNumber) {
    if (!phoneNumber || lodashIsNil(phoneNumber)) return "";

    // remove non numeric characters from phone number
    const numericPhoneNumber = phoneNumber.replace(/\D/g, "");

    if (numericPhoneNumber.length < 10) return "";

    const areaCode = numericPhoneNumber.substring(0, 3);
    const exchangeCode = numericPhoneNumber.substring(3, 6);
    const subscriberNumber = numericPhoneNumber.substring(6, 10);

    return `(${areaCode}) ${exchangeCode}-${subscriberNumber}`;
});
