const CampaignProgramEnum = Object.freeze({
    BMW: [
        "d3a45b49-4387-4221-9055-24db52bf2368",
        "b257486d-9b9a-46f6-85fd-601c452f7983",
        "37ec98e2-c9e3-4a62-8e96-c883c3b91bed",
        "eaed72e0-77a8-11ee-b962-0242ac120002",
        "1e01da17-8c6b-40f8-bc1b-44093e49866d", // BMW Upgrade
    ],
    NISSAN_UPGRADE: ["04a4751a-f19a-11ee-a951-0242ac120002"], // Gordie boucher - Nissan Upgrade
    NISSAN_UPGRADE_ROOT: ["3241a4be-7ad0-4c89-90b3-71ee90bbc53a"], // Nissan Upgrade Root Site
});

/**
 * Checks if a given program ID corresponds to the Nissan Upgrade campaign.
 * @param {string} programId - The ID of the program to check.
 * @returns {boolean} - Returns true if the program ID belongs to the Nissan Upgrade campaign, otherwise false.
 */
export const isNissanUpgradeCampaignProgram = (programId) => {
    return (
        CampaignProgramEnum.NISSAN_UPGRADE.includes(programId) ||
        CampaignProgramEnum.NISSAN_UPGRADE_ROOT.includes(programId)
    );
};

export default CampaignProgramEnum;
