<template>
    <div class="content-loader-wrapper">
        <content-loader :width="width" :height="height" :speed="2" primary-color="#f3f3f3" secondary-color="#ecebeb">
            <rect x="0" y="0" rx="0" ry="0" :width="width" :height="height" />
        </content-loader>
    </div>
</template>
<script>
import { ContentLoader } from "vue-content-loader";

export default {
    components: { ContentLoader },
    props: {
        width: {
            type: Number,
            required: true,
        },
        height: {
            type: Number,
            required: true,
        },
    },
};
</script>
<style lang="scss">
.content-loader-wrapper {
    margin: 0 auto;
}
</style>
