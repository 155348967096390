<template>
    <form ref="form" method="post" action="/logout">
        <input type="hidden" name="_csrf" :value="csrf" />
    </form>
</template>

<script>
function getMeta(metaName) {
    const metas = document.getElementsByTagName("meta");

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") === metaName) {
            return metas[i].getAttribute("content");
        }
    }

    return null;
}

export default {
    name: "Logout",
    computed: {
        csrf() {
            return getMeta("_csrf");
        },
    },
    methods: {
        logout() {
            this.$refs.form.submit();
        },
    },
};
</script>
