<template>
    <div>
        <h2 class="subtitle-text text-center" :class="subtitleClass">
            <slot name="subtitle"></slot>
        </h2>

        <h1 class="title-text text-center pb-1" :class="titleClass">
            <slot name="title"></slot>
        </h1>

        <div class="bottom-subtitle-text text-center">
            <slot name="bottom-subtitle"></slot>
        </div>

        <hr v-if="displaySeparator" class="separator" />
    </div>
</template>

<script>
export default {
    props: {
        titleClass: {
            type: String,
            required: false,
            default: "",
        },
        displaySeparator: {
            required: false,
            type: Boolean,
            default: false,
        },
        subtitleClass: {
            type: String,
            required: false,
            default: "",
        },
    },
};
</script>

<style lang="scss" scoped>
.title-text,
.subtitle-text,
.bottom-subtitle-text {
    color: $gray-800;
    padding: 0;
    margin: 0;
}

.title-text {
    font-size: px2rem(24);
    font-weight: normal;
    line-height: 1.3;

    @include themify($themes) {
        color: themed("title-color");
    }

    @include media-breakpoint-up("md") {
        font-size: px2rem(28);
    }
}

.subtitle-text {
    font-size: px2rem(14);
    line-height: 1.3;
}

.bottom-subtitle-text {
    color: $gray-700;
    font-size: px2rem(14);
    line-height: 1.3;
}

.separator {
    width: px2rem(128);
    margin-bottom: px2rem(40);
    @include themify($themes) {
        border: solid px2rem(1) themed("primary-color");
    }
}
</style>
