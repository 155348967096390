import Vue from "vue";
import VueGtag from "vue-gtag";
import campaign from "@/store/modules/campaign";
import carsaverTagManager from "../../../../carsaverTagManager/carsaverTagManager";
import { dataLayerManager } from "../../../../carsaverTagManager/dataLayerManager";
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";
import lodashFilter from "lodash/filter";
import lodashIsArray from "lodash/isArray";
import lodashMapValues from "lodash/mapValues";
import lodashMap from "lodash/map";

const { pathToRegexp } = require("path-to-regexp");
const URL = require("url-parse");

/**
 * set which analytics and tag management to use
 **/
let USE_GOOGLE_TAGS = true;
let USE_ADOBE_TAGS = false;

function ifEcommerceProductEnabledAdobeTags(environment) {
    // Only Nissan Ecommerce uses adobe tags
    if (window._CAMPAIGN.productId === 102) {
        USE_ADOBE_TAGS = true;
    }
}

export function configureRouter(router, pathPrefixes) {
    if (!lodashIsArray(pathPrefixes)) {
        pathPrefixes = [pathPrefixes];
    }
    const environment = lodashGet(window, "_APP_CONFIG.env[0]", "local") || "local";
    const gtmConfig = lodashGet(window, "_CAMPAIGN.gtmConfig", null);

    ifEcommerceProductEnabledAdobeTags(environment);

    const containers = [];
    let debug = true;
    if (environment === "prod") {
        containers.push({
            id: "GTM-T2VVQV3",
            queryParams: {
                gtm_auth: "u0lU9afZPhh6-L7XBZ-ZZw",
                gtm_preview: "env-2",
                gtm_cookies_win: "x",
            },
        });
        containers.push({
            id: "GTM-KKFXZZN",
            queryParams: {
                gtm_auth: "2K5hOYRiHRMovHWLJtQSmA",
                gtm_preview: "env-1",
                gtm_cookies_win: "x",
            },
        });
        debug = false;
    } else {
        containers.push({
            id: "GTM-T2VVQV3",
            queryParams: {
                gtm_auth: "2AFS7fZ_31EQrd2ApGNXzg",
                gtm_preview: "env-5",
                gtm_cookies_win: "x",
            },
        });
        containers.push({
            id: "GTM-KKFXZZN",
            queryParams: {
                gtm_auth: "2K5hOYRiHRMovHWLJtQSmA",
                gtm_preview: "env-1",
                gtm_cookies_win: "x",
            },
        });
    }

    if (!lodashIsNil(gtmConfig)) {
        containers.push(gtmConfig);
    }

    /**
     * initialize plugin with minimum properties needed
     */
    Vue.use(carsaverTagManager, {
        adobeTagsEnabled: USE_ADOBE_TAGS,
        googleTagsProps: {
            id: containers,
            enabled: USE_GOOGLE_TAGS,
            debug,
            loadScript: true,
            vueRouter: router,
            ignoredViews: ["deal-finance-app-post"],
        },
    });

    // Configure Dealer Google Analytics if exists
    const gaTrackingId = lodashGet(window, "window._GA_TRACKING_ID", null);
    if (!lodashIsNil(gaTrackingId) && USE_GOOGLE_TAGS) {
        Vue.use(
            VueGtag,
            {
                config: { id: gaTrackingId },
            },
            router
        );
    }

    // Guards again no campaign and redirects to /page-not-found
    router.beforeEach((to, from, next) => {
        let campaignTheme = campaign.state.theme;
        const errorPagePath = "/page-not-found";

        if (lodashIsNil(campaignTheme) && to.path !== errorPagePath) {
            next(errorPagePath);
        } else {
            next();
        }
    });

    router.beforeEach((to, from, next) => {
        if (to.path === "/error") {
            next();
            return;
        }

        const paths = lodashMap(router.options.routes, "path");
        const filteredPaths = lodashFilter(paths, (p) => p !== "*");

        const routeRegex = lodashMapValues(filteredPaths, (route) => pathToRegexp(route, []));

        const results = lodashFilter(routeRegex, (route) => {
            const regexRes = route.exec(to.path);
            return regexRes !== null && regexRes.length >= 1;
        });

        // If the route is not within this entrypoint's "routes", do a document.location page change.
        if (results.length > 0) {
            next();
        } else {
            let customPathPrefixFound = false;
            for (const pathPrefix in pathPrefixes) {
                if (pathPrefix === "/") {
                    next();
                    customPathPrefixFound = true;
                    break;
                } else if (to.path.startsWith(pathPrefix)) {
                    next(false);
                    document.location = "/error";
                    customPathPrefixFound = true;
                    break;
                }
            }

            if (!customPathPrefixFound) {
                const url = new URL(to.path, true);
                url.query = to.query;
                document.location = url.toString();
            }
        }
    });

    router.beforeEach((to, from, next) => {
        // This goes through the matched routes from last to first, finding the closest route with a title.
        // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
        const nearestWithTitle = to.matched
            .slice()
            .reverse()
            .find((r) => r.meta && r.meta.title);

        // Find the nearest route element with meta tags.
        const nearestWithMeta = to.matched
            .slice()
            .reverse()
            .find((r) => r.meta && r.meta.metaTags);
        const previousNearestWithMeta = from.matched
            .slice()
            .reverse()
            .find((r) => r.meta && r.meta.metaTags);

        // If a route with a title was found, set the document (page) title to that value.
        if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

        // Remove any stale meta tags from the document using the key attribute we set below.
        Array.from(document.querySelectorAll("[data-vue-router-controlled]")).forEach((el) =>
            el.parentNode.removeChild(el)
        );

        // Skip rendering meta tags if there are none.
        if (!nearestWithMeta) return next();

        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags
            .map((tagDef) => {
                const tag = document.createElement("meta");

                Object.keys(tagDef).forEach((key) => {
                    tag.setAttribute(key, tagDef[key]);
                });

                // We use this to track which meta tags we create, so we don't interfere with other ones.
                tag.setAttribute("data-vue-router-controlled", "");

                return tag;
            })
            // Add the meta tags to the document head.
            .forEach((tag) => document.head.appendChild(tag));

        next();
    });

    router.afterEach((to, from) => {
        // create and update the digitalData and dataLayer
        dataLayerManager(USE_ADOBE_TAGS, USE_GOOGLE_TAGS);
    });
}

export const routerOptions = {
    // changing to a new page will scroll to the top
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
};
