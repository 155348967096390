<template>
    <tooltip title="Sale Price Disclaimer" :aria-label="'sale price disclaimer'">
        <span v-if="stockType === 'NEW'">
            If allowed by applicable law, the Sale Price for this NEW VEHICLE is an estimated sales price offered
            exclusively to registered users. However, where required by applicable law, this price is the same sale
            price available to any buyer.
            <br />
            Actual availability of the vehicle must be confirmed with dealer as vehicles may be sold at any time.
            <br />
            The estimated Sale Price excludes the freight/destination fee, dealer (preparation, document or service)
            fees, taxes, and registration fees, if applicable state law permits the exclusion of such fees from the
            advertised price.
            <br />
            The purchase price may differ if the vehicle you buy has different options, you qualify for a rebate or
            incentive that is not in this price or for another reason beyond the dealer’s control.
            <br />
            Dealer sets and controls its own pricing. Only a dealer can provide you with an actual price for a specific
            vehicle. CarSaver does not sell, finance or lease motor vehicles.
        </span>
        <span v-else>
            If allowed by applicable law, the Sale Price for this USED VEHICLE is an estimated sales price offered
            exclusively to registered users. However, where required by applicable law, this price is the same sale
            price available to any buyer.
            <br />
            Actual availability of the vehicle must be confirmed with dealer as vehicles may be sold at any time.
            <br />
            The estimated Sale Price excludes dealer (preparation, document or service) fees, taxes, and registration
            fees, if applicable state law permits the exclusion of such fees from the advertised price.
            <br />
            The purchase price may differ if the vehicle you buy has different features or for another reason beyond the
            dealer’s control.
            <br />
            Dealer sets and controls its own pricing. Only a dealer can provide you with an actual price for a specific
            vehicle. CarSaver does not sell, finance or lease motor vehicles.
        </span>
    </tooltip>
</template>
<script>
import Tooltip from "Components/Tooltip";

export default {
    name: "SalePriceTooltip",
    components: { Tooltip },
    props: {
        stockType: {
            type: String,
            required: true,
        },
    },
};
</script>
