<template>
    <div class="badge d-flex justify-content-center align-items-center">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Badge",
};
</script>

<style lang="scss" scoped>
.badge {
    border: 1px solid white;
    border-radius: 8px;
    padding: 0px 3px;
    font-size: px2rem(10);
    font-weight: bold;
    line-height: 17px;
    height: 17px;
    min-width: 17px;
    color: $white;
    @include themify($themes) {
        background: themed("primary-color");
    }
}
</style>
