<template>
    <vue-slider
        v-model="updateValue"
        :min="min"
        :max="max"
        :interval="interval"
        :disabled="disabled"
        :tooltip="enableTooltip"
        class="range-slider-comp"
    />
</template>

<script>
import VueSlider from "vue-slider-component";
export default {
    name: "RangeSlider",
    components: { VueSlider },
    props: {
        value: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            required: true,
        },
        max: {
            type: Number,
            required: true,
        },
        interval: {
            type: Number,
            required: false,
            default: 1,
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        enableTooltip: {
            type: String,
            required: false,
            default: "none",
        },
    },
    computed: {
        updateValue: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit("update", val);
            },
        },
    },
};
</script>
<style lang="scss">
.range-slider-comp {
    height: 6px !important;
    padding: 10px 0 !important;
    .vue-slider-disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }
    .vue-slider-rail {
        background-color: $gray-300;
        border-radius: 14px;
    }
    .vue-slider-process {
        background-color: $secondary-gray;
        border-radius: 14px;
    }
    .vue-slider-mark {
        z-index: 4;
    }
    .vue-slider-mark:first-child .vue-slider-mark-step,
    .vue-slider-mark:last-child .vue-slider-mark-step {
        display: none;
    }
    .vue-slider-mark-step {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.16);
    }
    .vue-slider-mark-label {
        font-size: 14px;
        white-space: nowrap;
    }
    .vue-slider-dot {
        height: 24px !important;
        width: 24px !important;
    }
    .vue-slider-dot-handle {
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        @include themify($themes) {
            background-color: themed("primary-color");
        }
        box-sizing: border-box;
        box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
    }
    .vue-slider-dot-handle-focus {
        box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
    }
    .vue-slider-dot-handle-disabled {
        cursor: not-allowed;
        background-color: #ccc;
    }
    .vue-slider-dot-tooltip-inner {
        font-size: 14px;
        white-space: nowrap;
        padding: 2px 5px;
        min-width: 20px;
        text-align: center;
        color: #fff;
        border-radius: 5px;
        @include themify($themes) {
            border-color: themed("primary-color");
            background-color: themed("primary-color");
        }
        box-sizing: content-box;
    }
    .vue-slider-dot-tooltip-inner::after {
        content: "";
        position: absolute;
    }
    .vue-slider-dot-tooltip-inner-top::after {
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        height: 0;
        width: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px;
        border-top-color: inherit;
    }
    .vue-slider-dot-tooltip-inner-bottom::after {
        bottom: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        height: 0;
        width: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px;
        border-bottom-color: inherit;
    }
    .vue-slider-dot-tooltip-inner-left::after {
        left: 100%;
        top: 50%;
        transform: translate(0, -50%);
        height: 0;
        width: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px;
        border-left-color: inherit;
    }
    .vue-slider-dot-tooltip-inner-right::after {
        right: 100%;
        top: 50%;
        transform: translate(0, -50%);
        height: 0;
        width: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 5px;
        border-right-color: inherit;
    }
    .vue-slider-dot-tooltip-wrapper {
        opacity: 0;
        transition: all 0.3s;
    }
    .vue-slider-dot-tooltip-wrapper-show {
        opacity: 1;
    }
}
</style>
