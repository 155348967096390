<template>
    <div class="question-indicator">
        <div class="circle">?</div>
        <div class="lower-corner"></div>
    </div>
</template>

<script>
export default {
    name: "QuestionIndicator",
};
</script>

<style lang="scss" scoped>
.question-indicator {
    color: white;
    width: px2rem(20);
    height: px2rem(20);
    position: relative;
    display: inline-block;

    &:hover,
    &:focus {
        outline-style: none;
    }

    .circle,
    .lower-corner {
        background: #d62828;

        &:hover,
        &:focus {
            outline-style: none;
        }
    }

    .circle {
        height: px2rem(20);
        width: px2rem(20);
        border-radius: px2rem(10);
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 2;
    }

    .lower-corner {
        height: px2rem(10);
        width: px2rem(10);
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
    }
}
</style>
