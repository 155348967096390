<template>
    <b-img :alt="alt" :src="themeLogoPath" v-bind="$attrs" />
</template>

<script>
import { get } from "vuex-pathify";

export default {
    inheritAttrs: false,
    props: {
        isDarkLogo: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        theme: get("campaign/theme"),
        alt() {
            return this.theme;
        },
        themeLogo() {
            return this.theme + (this.isDarkLogo === true ? "-logo-dark.png" : "-logo.png");
        },
        themeImages() {
            return require.context("Theme", true, /(-logo).*(.png)/);
        },
        themeLogoPath() {
            const logoImagePath = this.themeImages.keys().find((e) => {
                return e.toLowerCase() === "./" + this.theme.toLowerCase() + "/" + this.themeLogo.toLowerCase();
            });
            return this.themeImages(logoImagePath);
        },
    },
};
</script>
