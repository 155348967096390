<!--
    Using the mixin add the list of tour points per page using addTourPoints([...]) then activate
    the Tour by running activateTourPoints().

    If conditionally showing tour point you'll need to add tour point using the addTourPoint("string") method
    and you need to run activateTourPoints() again from mixin.

    note: Dynamic components must use v-if to hide and show for it to work properly and will
          will not work with v-show or display: none
-->
<template>
    <transition v-if="show" name="fade">
        <div :id="name" :ref="name" class="overlay-show-through">
            <div class="click-area tour-point d-flex align-items-center" aria-live="assertive">
                <div class="msg-container d-flex">
                    <slot></slot>
                </div>

                <div class="ok-btn">OK</div>

                <div class="arrow" :class="`${direction}-arrow`" :style="{ right: rightOffset }"></div>
            </div>
        </div>
    </transition>
</template>

<script>
import { sync } from "vuex-pathify";
import TourPointMixin from "./TourPointMixin";

export default {
    name: "TourPoint",
    mixins: [TourPointMixin],
    props: {
        name: {
            type: String,
            required: true,
        },
        autoAdd: {
            type: Boolean,
            default: true,
            required: false,
        },
        disableOverlay: {
            type: Boolean,
            default: false,
            required: false,
        },
        direction: {
            //options -> 'right', 'left', 'right-bottom', 'right-top'
            type: String,
            default: "right",
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        rightOffset: {
            type: String,
            default: null,
            required: false,
        },
        scrollToDisabled: {
            type: Boolean,
            default: false,
            required: false,
        },
    },

    computed: {
        showBackgroundOverlay: sync("context/showBackgroundOverlay"),
        isTourPointActive() {
            return this.activeTourPoint === this.name;
        },
        show() {
            return this.isTourPointActive && !this.disabled;
        },
    },

    watch: {
        show(newVal) {
            if (newVal === true) {
                this.scrollIntoView();

                if (this.disableOverlay === true) {
                    this.disableBackgroundOverlay();
                } else {
                    this.enableBackgroundOverlay();
                }
            }
        },
    },

    mounted() {
        this.initOverlay();
    },

    created() {
        this.registerTourPoint(this.name);
    },
    beforeDestroy() {
        this.cleanUp();
    },

    methods: {
        scrollIntoView() {
            const config = {
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            };

            this.$nextTick(() => this.$refs[this.name].scrollIntoView(config));
        },
        cleanUp() {
            this.removeTourPoint(this.name);

            if (this.isTourPointActive) {
                this.disableBackgroundOverlay();
            }
        },
        enableBackgroundOverlay() {
            this.showBackgroundOverlay = true;
        },
        disableBackgroundOverlay() {
            this.showBackgroundOverlay = false;
        },
        initOverlay() {
            if (this.show === true) {
                if (this.disableOverlay === true) {
                    this.disableBackgroundOverlay();
                } else {
                    this.enableBackgroundOverlay();
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.click-area {
    color: white;
    cursor: pointer;

    &:hover {
        filter: brightness(0.85);
    }
}

.msg-container,
.ok-btn {
    height: 100%;
}

.ok-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: px2rem(46);
    font-size: px2rem(12);
    font-weight: bold;
    border-radius: 0px 4px 4px 0px;
    @include themify($themes) {
        background-color: themed("primary-color");
    }
}

.msg-container {
    justify-content: center;
    align-items: center;
    padding: 0 px2rem(8);
    max-width: px2rem(300);
    font-size: px2rem(12);
    line-height: px2rem(17);
    border-radius: 4px 0px 0px 4px;
    letter-spacing: 0;
    @include media-breakpoint-up("md") {
        font-size: px2rem(14);
    }

    @include themify($themes) {
        background-color: themed("body-color");
    }
}

.arrow {
    border: px2rem(6) solid transparent;
    height: 10px;
    position: absolute;

    @include themify($themes) {
        color: themed("body-color");
    }
}

//arrow placement options
.top-center-arrow {
    position: absolute;
    top: px2rem(-10);
    right: calc(50%);
    border-bottom: px2rem(6) solid;
}

.bottom-left-arrow {
    position: absolute;
    bottom: px2rem(-10);
    left: px2rem(17);
    border-top: px2rem(6) solid;
}

.bottom-center-arrow {
    position: absolute;
    bottom: px2rem(-10);
    left: calc(50% - px2rem(2));
    border-top: px2rem(6) solid;
}

.right-bottom-arrow {
    bottom: px2rem(-10);
    right: px2rem(17);
    border-top: px2rem(6) solid;
    @include themify($themes) {
        color: themed("primary-color");
    }
}

.right-top-arrow {
    top: px2rem(-10);
    right: px2rem(17);
    border-bottom: px2rem(6) solid;
    @include themify($themes) {
        color: themed("primary-color");
    }
}

.right-arrow {
    border-left: px2rem(6) solid;
    right: px2rem(-10);
    @include themify($themes) {
        color: themed("primary-color");
    }
}

.left-arrow {
    border-right: px2rem(6) solid;
    left: px2rem(-10);
}

.tour-point {
    position: relative;
    height: px2rem(46);
}
</style>
