import { clientSessionStorage } from "./clientStorage";
import lodashIsNil from "lodash/isNil";

const namespace = "bannersViewed";

//Todo: add localstorage support
const bannersViewedStorage = {
    add: (name) => {
        const value = name;
        let currentList = bannersViewedStorage.get() ? bannersViewedStorage.get() : [];

        if (lodashIsNil(value) || typeof value !== "string") {
            console.trace("Error: Invalid banner name", name);
            return false;
        }

        if (currentList.indexOf(value) !== -1) {
            console.trace("duplicate value", value);
            return false;
        }

        clientSessionStorage.setItem(namespace, [...currentList, value]);

        return { namespace: value };
    },
    get: () => {
        const value = clientSessionStorage.getItem(namespace);

        if (lodashIsNil(value)) {
            console.log(`Error: No ${namespace} key found in localStorage `, value);
            return false;
        }

        return value.split(",");
    },
};

export { bannersViewedStorage as default };
