<template>
    <div>
        <nissan-footer v-if="isECommerceProduct"></nissan-footer>
        <upgrade-footer v-else></upgrade-footer>
    </div>
</template>
<script>
import NissanFooter from "Components/Footer/NissanFooter";
import UpgradeFooter from "Components/Footer/UpgradeFooter";
import CampaignProductEnum from "@/libs/CampaignProductEnum";
import { get } from "vuex-pathify";

export default {
    name: "Footer",
    components: { UpgradeFooter, NissanFooter },
    computed: {
        productId: get("campaign/productId"),
        isECommerceProduct() {
            const result = this.productId === CampaignProductEnum.ECOMMERCE;
            return result;
        },
    },
};
</script>
