<template>
    <modal id="campaign-promotions-modal" divider hide-footer hide-header>
        <div class="lender-hero-image">
            <theme-img v-if="!isBMWProgram" :is-dark-logo="isDarkLogo" class="logo-img img-fluid logo" />
            <primary-link class="position-relative link" @click="closeWindow">
                <b-img :src="close" class="close-btn" @click="closeWindow" />
            </primary-link>
        </div>
        <div class="header">
            <p class="text-center title">{{ $t("home.promotionBanner.title") }}</p>
            <div class="sub-title">
                {{ $t("home.promotionBanner.subtitle", [financierName]) }}
            </div>
            <hr class="divider mt-3 mb-4" />
        </div>
        <div class="body-content">
            <div class="row">
                <div class="col text-center">
                    <CalendarIcon class="icon" />
                    <div class="mt-3 sub-title-text">
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="$t('home.promotionBanner.calendarIconText', [homepagePromotionsNoPaymentsDays])">
                        </span>
                        <!-- eslint-enable vue/no-v-html -->
                    </div>
                </div>
                <div class="col text-center">
                    <CostIcon class="icon" />
                    <div class="mt-3 sub-title-text">
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="$t('home.promotionBanner.costIcon')"> </span>
                        <!-- eslint-enable vue/no-v-html -->
                    </div>
                </div>
                <div v-if="isBMWProgram" class="col text-center">
                    <LoyaltyIcon class="icon" />
                    <div class="mt-3 sub-title-text">
                        <!-- eslint-disable vue/no-v-html -->
                        <span v-html="$t('home.promotionBanner.loyaltyIcon')"> </span>
                        <!-- eslint-enable vue/no-v-html -->
                    </div>
                </div>
            </div>
            <div v-if="!isBMWProgram" class="row mt-4">
                <div class="col sub-text text-center">
                    <div>Upgrade to a new or pre-owned vehicle today with as little as $0 down.</div>
                    <div class="font-weight-bold mt-2 upgrade-today-label">Upgrade today!</div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div v-if="!hidePromotionalModalCTAs" class="w-100">
                <!--        Show button if pin form is enabled-->
                <primary-btn
                    v-if="showGetStartedComponent"
                    class="get-started-btn w-100"
                    @click.native="onGetStartedBtn"
                >
                    View Your Upgrade Offers
                </primary-btn>
                <!--        Hide button if pin form is disabled-->
                <get-started-btn v-else class="get-started-btn w-100" text="View Your Upgrade Offers" />
            </div>
            <secondary-btn class="mb-3" @click.native="closeWindow"> Close</secondary-btn>
        </div>
        <div class="disclaimer">
            <!-- eslint-disable vue/no-v-html -->
            <span
                v-html="
                    $t('home.promotionBanner.promotionalDisclaimer', [homepagePromotionsNoPaymentsDays, financierName])
                "
            >
            </span>
            <!-- eslint-enable vue/no-v-html -->
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal";
import ThemeImg from "Components/ThemeImg";
import PrimaryLink from "Components/Links/primaryLink";
import SecondaryBtn from "Components/Buttons/SecondaryBtn";
import Close from "./images/close.png";
import CalendarIcon from "./images/CalendarIcon";
import CostIcon from "./images/CostIcon";
import { get } from "vuex-pathify";
import LoyaltyIcon from "./images/LoyaltyIcon";
import GetStartedBtn from "./../../Home/GetStartedBtn";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";
import MfaRedirectionMixin from "Modules/Home/components/Home/MfaRedirectionMixin.vue";

export default {
    name: "PromotionsModal",
    components: {
        PrimaryBtn,
        GetStartedBtn,
        Modal,
        ThemeImg,
        PrimaryLink,
        CalendarIcon,
        CostIcon,
        SecondaryBtn,
        LoyaltyIcon,
    },
    mixins: [MfaRedirectionMixin],
    data() {
        return {
            close: Close,
        };
    },
    computed: {
        theme: get("campaign/theme"),
        homepagePromotionsNoPaymentsDays: get("campaign/noPaymentDays"),
        financierName: get("context/financierName"),
        isDarkLogo() {
            return this.theme === "teachers";
        },
        programId: get("campaign/programId"),
        isBMWProgram() {
            return CampaignProgramEnum.BMW.indexOf(this.programId) !== -1;
        },
        showGetStartedComponent: get("context/displayPinForm"),
        isListingsPage() {
            return this.$route.name === "listings";
        },
        hidePromotionalModalCTAs() {
            // Hide Get started btn for BMW in listings page
            return this.isBMWProgram && this.isListingsPage;
        },
    },
    methods: {
        closeWindow() {
            this.$bvModal.hide("campaign-promotions-modal");
        },
        onGetStartedBtn() {
            const filters = {};
            this._mixin_handleRedirectionsWithFilters(filters);
        },
    },
};
</script>
<style lang="scss" scoped>
.lender-hero-image {
    position: absolute;
    width: 100%;
    height: 120px;
    left: 0px;
    top: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    padding-left: 16px;
    @include themify($themes) {
        background-image: themed("promotional-banner-lg");

        @include media-breakpoint-down(sm) {
            background-image: themed("promotional-banner-md");
        }
    }
}

.lender-hero-image:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(89.94deg, #ffffff 0.04%, rgba(217, 217, 217, 0) 79.85%);
}

.header {
    padding-top: 105px;
}

.body-content {
    line-height: 1.4;
    margin-bottom: px2rem(30);
    @include media-breakpoint-up(sm) {
        margin: px2rem(30);
    }
}

.title {
    font-size: px2rem(28);
    @include media-breakpoint-down(sm) {
        font-size: px2rem(26);
    }
}

.sub-title {
    text-align: center;
    line-height: 1.3;
    font-size: px2rem(15);
    @include media-breakpoint-down(sm) {
        line-height: 1.2;
    }
}

.sub-title-text {
    text-align: center;
    line-height: 1.3;

    @include themify($themes) {
        font-size: themed("promotional-font-size");
    }
}

.logo {
    max-width: 186px;
    max-height: 60px;
    height: auto;
    position: relative;
    z-index: 3;
}

.icon {
    @include themify($themes) {
        height: themed("promotional-icon-size");
        width: themed("promotional-icon-size");
    }
}

.get-started-btn {
    margin-bottom: px2rem(5) !important;
}

.upgrade-today-label {
    font-size: px2rem(17);
    @include media-breakpoint-down(sm) {
        font-size: px2rem(16);
    }
}

.disclaimer {
    font-size: px2rem(12);
    color: $gray-600;
    font-style: italic;
    line-height: 1.25;
    text-align: justify;
    @include media-breakpoint-down(sm) {
        right: 0;
        left: 0;
        height: auto;
        border: 0px;
        overflow: unset;
    }
}

.divider {
    width: 178px;
    height: 2px;
    @include themify($themes) {
        background-color: themed("primary-color");
    }
}

.link {
    z-index: 3;
    top: 10px;
    right: 10px;
    position: absolute !important;
}

.button {
    margin: 0 10px;
}

.close-btn {
    height: 14px;
    width: 14px;
}
</style>
