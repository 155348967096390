import { addAdobeScripts } from "./adobeTagsSettings";
import lodashGet from "lodash/get";

/**
 * set a different "theme" to test the locale change
 **/
// _.set(window, "_CAMPAIGN.theme", "carsaver");
// _.set(window, "_CAMPAIGN.theme", "no-theme-set");

// check what the current theme is
const theme = lodashGet(window, "_CAMPAIGN.theme");

/**
 * load the correct data based on theme/locale
 **/
let globalTags;
async function getLocaleData() {
    let localTags = await import(`./themes/carsaver/tagsData`);
    try {
        localTags = await import(`./themes/${theme}/tagsData`);
    } catch (error) {
        console.log("Could not load the data tags. ", error);
    }
    globalTags = localTags;
}
getLocaleData();

/**
 * export the click to track data for the $carsaverEventTracker method to use
 **/
export let clickToTrackData;
const getClickToTrackProps = (data) => {
    clickToTrackData = data;
};

const getGenericPathFromCurrentPath = () => {
    let currentPath = window.location.pathname;

    if (currentPath.includes("/drive-at-home")) {
        currentPath = "/drive-at-home";
    }

    if (currentPath.includes("trade/appraisal")) {
        currentPath = "/trade/appraisal";
    }
    if (currentPath.includes("/contract")) {
        currentPath = "/contract/confirmation";

        if (currentPath.includes("next-steps")) {
            currentPath = "/contract/next-steps";
        }
    }
    if (currentPath.includes("/certificate")) {
        currentPath = "/certificate";
    }
    // @todo: change this to a switch
    if (currentPath.includes("/deal")) {
        if (currentPath.includes("contact-dealer/thank-you")) {
            currentPath = "/thank-you";
        } else if (currentPath.includes("contact-dealer")) {
            currentPath = "/contact-dealer";
        } else if (currentPath.includes("schedule-test-drive/thank-you")) {
            currentPath = "/schedule-test-drive/thank-you";
        } else if (currentPath.includes("schedule-test-drive")) {
            currentPath = "/schedule-test-drive";
        } else if (currentPath.includes("sign-online-verify")) {
            currentPath = "/sign-online-verify";
        } else if (currentPath.includes("/finance-options")) {
            currentPath = "/finance-options";
        } else if (currentPath.includes("/finance")) {
            currentPath = "/finance";
        } else if (currentPath.includes("accessories")) {
            currentPath = "/accessories";
        } else {
            currentPath = "/deal";
        }
    }
    return currentPath;
};

const getPageNameModelFromCurrentPath = (currentPath, googleTags, adobeTags) => {
    let pageNameModel = {};

    switch (currentPath.toLowerCase()) {
        case "/":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameHome,
                adobe: adobeTags.pageNameHome,
            };
            break;
        case "/account/login":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameLogin,
                adobe: adobeTags.pageNameLogin,
            };
            break;
        case "/account/register":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameAccountRegister,
                adobe: adobeTags.pageNameAccountRegister,
            };
            break;
        case "/garage":
        case "/garage/recently":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameGarage,
                adobe: adobeTags.pageNameGarage,
            };
            break;
        case "/trade":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTrade,
                adobe: adobeTags.pageNameTrade,
            };
            break;
        case "/trade/appraisal":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTradeAppraisal,
                adobe: adobeTags.pageNameTradeAppraisal,
            };
            break;
        case "/trade/info":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTradeInfo,
                adobe: adobeTags.pageNameTradeInfo,
            };
            break;
        case "/trade/features":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTradeFeatures,
                adobe: adobeTags.pageNameTradeFeatures,
            };
            break;
        case "/trade/history":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTradeHistory,
                adobe: adobeTags.pageNameTradeHistory,
            };
            break;
        case "/trade/no-offer":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTradeNoOffer,
                adobe: adobeTags.pageNameTradeNoOffer,
            };
            break;
        case "/trade/quote-expired":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTradeInQuoteExpired,
                adobe: adobeTags.pageNameTradeInQuoteExpired,
            };
            break;
        case "/soft-pull":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameSoftPull,
                adobe: adobeTags.pageNameSoftPull,
            };
            break;
        case "/soft-pull/verify":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameSoftPullVerifyPayment,
                adobe: adobeTags.pageNameSoftPullVerifyPayment,
            };
            break;
        case "/soft-pull/manual-payoff":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameSoftPullPayOffPayment,
                adobe: adobeTags.pageNameSoftPullPayOffPayment,
            };
            break;
        case "/soft-pull/max-attempts-exceeded":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameSoftPullMatchNotFound,
                adobe: adobeTags.pageNameSoftPullMatchNotFound,
            };
            break;
        case "/deal":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameDeal,
                adobe: adobeTags.pageNameDeal,
            };
            break;
        case "/drive-at-home":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameDriveAtHome,
                adobe: adobeTags.pageNameDriveAtHome,
            };
            break;
        case "/contact-dealer":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameContactDealer,
                adobe: adobeTags.pageNameContactDealer,
            };
            break;
        case "/accessories":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameAddAccessories,
                adobe: adobeTags.pageNameAddAccessories,
            };
            break;
        case "/thank-you":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameContactDealerThankYou,
                adobe: adobeTags.pageNameContactDealerThankYou,
            };
            break;
        case "/schedule-test-drive":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameScheduleTestDrive,
                adobe: adobeTags.pageNameScheduleTestDrive,
            };
            break;
        case "/schedule-test-drive/thank-you":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameTestDriveThankYou,
                adobe: adobeTags.pageNameTestDriveThankYou,
            };
            break;
        case "/contract/confirmation":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameCertificateOrder,
                adobe: adobeTags.pageNameCertificateOrder,
            };
            break;
        case "/contract/next-steps":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameOrderConfirmed,
                adobe: adobeTags.pageNameOrderConfirmed,
            };
            break;
        case "/certificate":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameCertificateOrder,
                adobe: adobeTags.pageNameCertificateOrder,
            };
            break;
        case "/sign-online-verify":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameSignOnlineVerify,
                adobe: adobeTags.pageNameSignOnlineVerify,
            };
            break;
        case "/finance":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameFinance,
                adobe: adobeTags.pageNameFinance,
            };
            break;
        case "/finance-options":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameFinanceOptions,
                adobe: adobeTags.pageNameFinanceOptions,
            };
            break;
        case "/profile/welcome-back":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameWelcomeBack,
                adobe: adobeTags.pageNameWelcomeBack,
            };
            break;
        case "/profile/get-started":
            pageNameModel[currentPath] = {
                google: googleTags.pageNameDriveAtHomeGetStartedPage,
                adobe: adobeTags.pageNameDriveAtHomeGetStartedPage,
            };
            break;
        default:
            pageNameModel[currentPath] = {
                google: "",
                adobe: "",
            };
            break;
    }

    return pageNameModel;
};

const setUpTagsData = (currentPath, googleTags, adobeTags, pageNameModel, isAdobeEnabled, isGoogleEnabled) => {
    // get dealer and vehicle information
    const contextData =
        window._CONTEXT.eventTrackingContext !== null
            ? window._CONTEXT.eventTrackingContext
            : { dealer: {}, vehicle: {} };
    const dealerData = "dealer" in contextData ? contextData.dealer : {};
    const vehicleData = "vehicle" in contextData ? contextData.vehicle : {};

    // check if current page is garage
    const getCarInfo = (prop) => {
        if (currentPath === "/garage") {
            return "";
        }
        const propertyExists = vehicleData.hasOwnProperty(prop);
        if (propertyExists) {
            return vehicleData[prop];
        }
        return "";
    };

    // fill different data depending on location
    const getFormInfo = () => {
        let info = {
            formName: "",
            formCategory: "",
            formType: "",
            formLeadId: "",
        };
        switch (currentPath) {
            case "/schedule-test-drive":
                info = {
                    formName: "bah-test-drive",
                    formCategory: "lead",
                    formType: "book a test drive",
                    formLeadId: window._CONTEXT.userId || "",
                };
                break;
            case "/contract/next-steps":
                info = {
                    formName: "bah-sign-and-drive",
                    formCategory: "other",
                    formType: "buy-at-home",
                    formLeadId: window._CONTEXT.userId || "",
                };
                break;
            case "/thank-you":
                info = {
                    formName: "bah-contact-dealer",
                    formCategory: "lead",
                    formType: "contact-dealer",
                    formLeadId: window._CONTEXT.userId || "",
                };
                break;
            case "/schedule-test-drive/thank-you":
                info = {
                    formName: "bah-test-drive",
                    formCategory: "lead",
                    formType: "book a test drive",
                    formLeadId: window._CONTEXT.userId || "",
                };
                break;

            default:
                break;
        }
        return info;
    };

    // added extra gtm event data
    const getGoogleFormSuccessData = () => {
        const googleFormData = {
            event: googleTags.googleEventFormSuccessName,
            [googleTags.googlePropName]: {
                formName: getFormInfo().formName,
                formCategory: getFormInfo().formCategory,
                formType: getFormInfo().formType,
                formLeadId: getFormInfo().formLeadId,
            },
        };
        return googleFormData;
    };

    let preferredType =
        window._CONTEXT.preferredPaymentType !== null ? window._CONTEXT.preferredPaymentType.toLowerCase() : "";

    // CS-5535: Nissan B&H Adobe site tagging to change Dealer Id value. Add
    const isModifyAdobeDealerId = "nnaDealerId" in dealerData;

    let isOverlayEnabled = false;
    if (window.self !== window.top && contextData.overlayEnabled) {
        isOverlayEnabled = true;
    }

    // update digitalData for Adobe
    const adobePageLoad = {
        pageName: pageNameModel[currentPath].adobe || "",
        toolName: adobeTags.toolName || "",
        countryCode: adobeTags.countryCode || "",
        languageCode: adobeTags.languageCode || "",
        dealerId: isModifyAdobeDealerId ? "NNA-" + dealerData.nnaDealerId : dealerData.id,
        dealerName: "name" in dealerData ? dealerData.name : "",
        dealerAddress: "address" in dealerData ? dealerData.address : "",
        dealerZipCode: "zipcode" in dealerData ? dealerData.zipcode : "",
        vehicleModel: getCarInfo("model"),
        vehicleVersion: getCarInfo("version"),
        vehicleYear: getCarInfo("year"),
        vehicleMsrp: getCarInfo("msrp"),
        paymentMethod: preferredType,
        formName: getFormInfo().formName,
        formCategory: getFormInfo().formCategory,
        formType: getFormInfo().formType,
        formLeadId: getFormInfo().formLeadId,
        overlayEnabled: isOverlayEnabled,
    };

    // update dataLayer for Google
    const googlePageLoad = {
        event: googleTags.googleEventName || "",
        [googleTags.googlePropName]: {
            pageName: pageNameModel[currentPath].google || "",
            toolName: googleTags.toolName || "",
            languageCode: googleTags.languageCode || "",
            countryCode: googleTags.countryCode || "",
            dealerId: "id" in dealerData ? dealerData.id : "",
            dealerName: "name" in dealerData ? dealerData.name : "",
            dealerAddress: "address" in dealerData ? dealerData.address : "",
            dealerZipCode: "zipcode" in dealerData ? dealerData.zipcode : "",
            vehicleModel: getCarInfo("model"),
            vehicleVersion: getCarInfo("version"),
            vehicleYear: getCarInfo("year"),
            vehiclePrice: getCarInfo("msrp"),
            paymentMethod: preferredType,
            formName: getFormInfo().formName,
            formCategory: getFormInfo().formCategory,
            formType: getFormInfo().formType,
            formLeadId: getFormInfo().formLeadId,
            overlayEnabled: isOverlayEnabled,
        },
    };

    /**
     * push the data to the objects if enabled
     **/
    if (isAdobeEnabled) {
        window.digitalData = window.digitalData || {};
        window.digitalData = adobePageLoad;
    }
    if (isGoogleEnabled) {
        window.dataLayer.push(googlePageLoad);
        if (
            currentPath === "/thank-you" ||
            currentPath === "/schedule-test-drive/thank-you" ||
            currentPath === "/contract/next-steps"
        ) {
            const googleFormSuccessData = getGoogleFormSuccessData();
            window.dataLayer.push(googleFormSuccessData);
        }
    }
    // add Adobe script to pages if enabled
    if (isAdobeEnabled) {
        addAdobeScripts();
        _satellite.track("trackPageLoad");
    }
};

const executeTagSteps = (isAdobeEnabled, isGoogleEnabled) => {
    // deconstruct the object for ease access
    const googleTags = globalTags.default.googleOnPageLoad;
    const adobeTags = globalTags.default.adobeOnPageLoad;

    // get the click to track properties of a given page and element
    const clickToTrack = globalTags.default;
    getClickToTrackProps(clickToTrack);

    // find current location for data layers
    const currentPath = getGenericPathFromCurrentPath();

    // set tags based on current location
    const pageNameModel = getPageNameModelFromCurrentPath(currentPath, googleTags, adobeTags);

    // set the data for digitalData (adobe) & dataLayer (gtm)
    setUpTagsData(currentPath, googleTags, adobeTags, pageNameModel, isAdobeEnabled, isGoogleEnabled);
};
// create and update the digitalData and dataLayer
export const dataLayerManager = (isAdobeEnabled = false, isGoogleEnabled = false) => {
    // During internal route changes ready state is already complete so onreadystatechange never triggers
    // if so execute tag steps
    if (document.readyState === "complete") {
        executeTagSteps(isAdobeEnabled, isGoogleEnabled);
    } else {
        // on initial load on entrypoint waits for ready state to be complete
        // if so execute tag steps
        document.onreadystatechange = () => {
            if (document.readyState === "complete") {
                executeTagSteps(isAdobeEnabled, isGoogleEnabled);
            }
        };
    }
};
