<template>
    <div>
        <b-container v-if="showHeader" class="p-0 px-lg-3">
            <b-row no-gutters>
                <b-col cols="12">
                    <div class="d-flex content-header">
                        <slot name="page-title">
                            <div v-if="hasTitleOrSlot" class="title flex-grow-1 text-center title align-self-center">
                                <slot name="page-title-content">{{ title }}</slot>
                            </div>
                        </slot>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        <b-container class="content-container" :class="contentClasses">
            <slot />
        </b-container>
    </div>
</template>

<script>
import lodashIsNil from "lodash/isNil";
export default {
    name: "ContentContainer",

    props: {
        title: {
            required: false,
            type: String,
            default: null,
        },
        contentClasses: {
            type: String,
            required: false,
            default: "",
        },
        // nleone 6/24 - provides the ability to hide the default header, which is not full width.
        // Some layouts call for full width header - JIRA issue: UP-1282
        // TODO: revisit revising this component in phase 2
        showHeader: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    computed: {
        hasTitleOrSlot() {
            return !lodashIsNil(this.title) || this.$slots["page-title-content"];
        },
    },

    methods: {
        onBack() {
            this.$emit("back");
            this.$router.go(-1);
        },
    },
};
</script>

<style lang="scss" scoped>
.spacer {
    width: 60px;
}

.title {
    vertical-align: middle;
    color: $gray-700;
}

.content-header {
    height: 40px;
    margin-bottom: 24px;
    border-bottom: solid 1px $gray-300;
}

.menu-button-wrapper {
    background: white;
    position: absolute;
    bottom: -2px;
    right: 0;
    /*width: 24px;*/
    height: 100%;
}
</style>
