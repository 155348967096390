<template>
    <b-row v-if="isNoSharePreset">
        <b-col class="text-center">
            <div class="font-italic py-2 px-3 d-inline-block no-share">
                <!-- eslint-disable vue/no-v-html -->
                <span v-html="$t('profile.create.infoSharing')"></span>
                <!-- eslint-enable vue/no-v-html -->
            </div>
        </b-col>
    </b-row>
</template>
<script>
import lodashIsEmpty from "lodash/isEmpty";
export default {
    name: "NoShare",
    computed: {
        isNoSharePreset() {
            const noShareInfo = this.$t("profile.create.infoSharing");
            return !lodashIsEmpty(noShareInfo) || noShareInfo !== "";
        },
    },
};
</script>
<style lang="scss">
.no-share {
    font-size: px2rem(12);
    background-color: $gray-200;
}
</style>
