import lodashGet from "lodash/get";

const initialState = {
    paymentType: lodashGet(window, "_INVENTORY_CONFIG.paymentType", null),
    defaultDistance: lodashGet(window, "_INVENTORY_CONFIG.defaultDistance", null),
    stockTypeConditions: lodashGet(window, "_INVENTORY_CONFIG.stockTypeConditions", null),
    externalOffers: lodashGet(window, "_INVENTORY_CONFIG.externalOffersProvider", null),
};

export default {
    namespaced: true,
    state: initialState,
};
