export default {
    campaign: {
        name: "CarSaver",
        title: "CarSaver Upgrade Program",
    },
    home: {
        hero: {
            title: "<h2>Vehicle</h2><h1>UPGRADE</h1>",
        },
        upgrade: {
            title: "UPGRADE @HOME",
            message: "UPGRADE@HOME gives you the power to shop for a new or used car online, right from your home.",
            buttonText: "Start Shopping",
            pinText: "Welcome to CarSaver Upgrade Program!"
        },
        valueProps: {
            title: "The Easiest Way to Upgrade to a Newer Car",
            items: ["Get into a newer car more often", "Enjoy the newest technology", "Upgrade with as little as $0 down"],
            buttonText: "See your upgrade offers"
        },
        payments: {
            name: '"All In"',
            msg: 'See real "All In" payments without impacting your credit score! Your offers include taxes & registration and the payoff on your current vehicle.',
            buttonText: "See your upgrade offers"
        },
        promotionBanner: {
            title: "Enjoy Exclusive Member Benefits",
            subtitle: "Enjoy exclusive {0} member benefits when you redeem an upgrade offer using the Vehicle Upgrade Program site.",
            promotionalDisclaimer: "*No payment for {0} days is only applicable to the {1} Vehicle Upgrade Program.",
            calendarIconText: "<b>*No Payments for {0} Days</b>",
            costIcon: "<b>“All In” Payments Including Taxes & Fees</b>",
        },
        newCar: {
            title: "Get Into a Newer Car, More Often",
            subtitle: "You are eligible to upgrade into a newer vehicle and lower your payment.",
            msg: "Just trade-in your current vehicle and start driving a newer one. It's that easy!",
            buttonText: "See your upgrade offers",
        },
        lifeTimeWarranty: {
            home: {
                title: "Lifetime Powertrain Warranty",
                subtitle: "Hassle-free buying & worry-free driving.",
                buttonText: "See your upgrade offers",
            },
            modal: {
                title: "CarSaver's Lifetime Warranty*",
                subtitle: "Experience the joy of worry-free driving with CarSaver's exclusive lifetime "
                + "warranty that covers your car for unlimited miles and unlimited time for as long as"
                + " you own the car, and at no cost to you.",
            },
            items: [
                "Lifetime Warranty* standard - at no cost to you",
                "Coverage on engine, transmission, and drivetrain",
                "Unlimited miles, unlimited time - for as long as you own the vehicle"
            ],
        },
        pinForm: {
            pinMessage: "Welcome to your personal vehicle upgrade website",
            pinButtonText: "View Your Offers",
        }
    },
    deal: {
        salePrice: "Sale Price",
        startUpgrade: "Contact Dealer",
        contactDealer: "Contact Dealer",
        primaryCta: "Finalize Purchase",
        primaryCtaPageMessage: "Click below to get financing options or to purchase the vehicle online.",
        vehicleDetails: "Vehicle Details",
        getFinancing: "Get Financing",
        getTradeOffer: "Get Trade Offer",
        changeTrade: "Change Trade",
        addAccessories: "Add Accessories",
        editAccessories: "Edit Accessories",
        testDrive: "Schedule Test Drive",
    },
    noProfile: {
        getPersonalizedPayments: "Get Personalized Payments",
        getTradeOffer: "Get Trade Offer",
        getPreApproved: "Get Pre-approved",
        contactDealer: "Contact Dealer",
        testDrive: "Test Drive",
        mobilePrimaryCta: "Unlock",
    },
    noProfileDealDetails: {
        getTradeOffer: "Get Trade Offer",
    },
    noProfileCardTrade: {
        tradeBtn: "Get Trade Offer"
    },
    offerTable: {
        description: "Offer"
    },
    benefits: [
        "Apply for offers, promotions and other savings",
        "Receive real-time offer for your trade-in",
        "Get personalized payments and fast approvals",
        "Choose express pickup OR touchless delivery",
        "Save up to 2 hours by starting your buying process online",
    ],
    trade: {
        start: {
            tradeOptionSelect: {
                pageTitle: "Do you have a Trade-in?",
                pageSubtitle:
                    "Tell us about your current vehicle so that we can include your trade and payoff in your offers",
            },
            tradeEntryForm: {
                pageTitle: "Enter Your Vin",
                pageSubtitle: "Enter your Vehicle Identification Number (VIN)",
                actionButtonFooterText: "I don\'t have a Trade In",
                actionButtonText: "Next"
            },
            tradeEntryFormNonPinnedFlow : {
                pageTitle: "Tell Us About Your Trade-In",
                pageSubtitle: "Enter your VIN and we will automatically include this vehicle in your payment calculations.",
                actionButtonFooterText: "Continue Without Trade",
                actionButtonText: "Search This VIN"
            }
        },
        startWithPlate: {
            tradeOptionSelect: {
                pageTitle: "Choose Trade Option",
                pageSubtitle:
                    "Get a real-time offer for your trade and " +
                    "<strong>save up to 30 minutes</strong> " +
                    "at the dealership.",
            },
            tradeEntryForm: {
                pageTitle: "Tell Us About Your Vehicle",
                pageSubtitle: "Please enter your License Plate Number or VIN so we can look up your vehicle",
            },
        },
        appraisal: {
            disclaimer: "Please note that the Offer amount is based on the information provided. Any " +
                "discrepancy found in the condition of the vehicle will cancel the Offer and require a " +
                "revaluation of the vehicle by the dealer."
        },
    },
    register: {
        create: {
            pageTitle: "Get Started",
        },
    },
    profile: {
        create: {
            pageTitle: "Edit Profile",
            pageSubtitle: "To create your offers, we need to know a little bit about you. ",
            infoSharing: "We will <strong>NOT</strong> share your information without your permission.",
        },
    },
    leaseReturn: {
        estimatedLeaseBalanceDisclaimer: "Subject to final inspection. Actual lease balance may vary.",
    },
    vehicleBanner: {
        mainCtaButton: "Estimate Payments",
        secondaryCtaButton: "Create an Account",
        valueStatement: "Get Personalized Offers on this",
    },
    vehicleOfInterestModal: {
        mainCtaButton: "Estimate Payments",
        mainCtaDescription: "Explore finance and lease payments",
        secondaryCtaButton: "Unlock All Benefits",
        secondaryCtaDescription:
            "Create your account to get personalized pricing, payments, and trade in offer and much more...",
        signInCtaButton: "Sign In",
    },
    landingPageFloatingFooter: {
        mainCtaButton: "Estimate Payments",
        secondaryCtaButton: "Create an Account",
    },
    creditRangeModal: {
        mainCtaButton: "Next",
        secondaryCtaButton: "Unlock All Benefits",
    },
    createAccountModal: {
        title: "",
        subTitle: "Unlock benefits by creating an account!",
        primaryCta: "Create an Account",
        secondaryCta: "Sign in",
        lineItemOne: "Get detailed pricing including taxes and fees",
        lineItemTwo: "Get an offer for your trade-in",
        lineItemThree: "Much more…",
    },
    noProfileSaveModal: {
        title: "Save Your Work",
        description: "Would you like to save this vehicle to your garage and generate personalized payments",
        mainCtaButton: "Save and get personalized payments.",
        secondaryCtaButton: "No thanks. Not What I'm looking for.",
    },
    preApprovalModal: {
        disclaimer: "*Stipulations apply. Please speak with the Certified dealer for complete details.",
        scrollDisclaimerOne: "",
        scrollDisclaimerTwo:
            "If trade-in vehicle’s value is less than its financed balance, accepting this offer " +
            "may result in the financing of negative equity on the trade-in vehicle, which will increase " +
            "your finance obligation.",
    },
    vehicleInTransitMessage: {
        boldMessage: "This vehicle has not yet arrived at the dealership.",
        message:
            "Pricing and/or rebates are subject to change. You can save this vehicle to your garage and be notified once it has arrived in dealer inventory. Please be aware that saving a vehicle in your garage does not place a hold on the vehicle or reserve the vehicle.",
    },
    inTransitFlag: {
        inTransit: "In Transit",
        estimatedDelivery: "Estimated Delivery Date",
        message:
            "This vehicle is currently in transit to the dealership. The date displayed is the estimated date the vehicle will arrive.",
    },
    tooltip: {
        msrpDisclaimer:
            'MSRP is the abbreviation for "Manufacturer\'s Suggested Retail Price" (sometimes called "sticker price"). This is the price recommended by the manufacturer for this vehicle. Many vehicles are purchased for less than MSRP.',
    },
    footer: {
        prop64: "Operating, servicing, and maintaining a passenger vehicle or off-highway motor vehicle\n" +
            "                                can expose you to chemicals including engine exhaust, carbon monoxide, phthalates, and\n" +
            "                                lead, which are known to the State of California to cause cancer and birth defects or\n" +
            "                                other reproductive harm. To minimize exposure, avoid breathing exhaust, do not idle the\n" +
            "                                engine except as necessary, service your vehicle in a well-ventilated area and wear\n" +
            "                                gloves or wash your hands frequently when servicing your vehicle. For more information\n" +
            "                                go to\n"
    },
    financeApp: {
        form: {
            pageTitle: "Redeem Offer",
            pageTitleNissan: "Get Approved",
            pageSubtitle:
                "Please complete the application below to lock in your financing. " +
                "Have questions? Give us a call at ",
        },
    },
    leavingSiteModal: {
        disclaimer: "You will be redirected to another site. If you are not interested, click no, thanks.",
    },
    libertyMutualModal: {
        disclaimer: "By clicking “Get Your Quote” you agree to grant Liberty Mutual Insurance access\n" +
            " to certain pieces of personal data to generate an auto insurance quote.\n" +
            " To make it easier, some of the data will be prepopulated with information you have provided Nissan.\n" +
            " <strong>Click <a href=\"https://www.libertymutualgroup.com/general/about-lm/corporate-information/liberty-mutual-personal-lines\" target=\"_blank\">here</a> \n" +
            " for more details about Liberty Mutual’s privacy policy.</strong> You are not obligated to purchase\n" +
            " insurance for your vehicle from.\n" +
            " If you are not interested, click “no, thanks.\n",
    },
    remoteDelivery: {
        disclaimerLineOne: "Delivery Fees are wholly established and managed by ",
        disclaimerLineTwo: " To the extent any consumer has questions, concerns or complaints relating " +
            "to a dealer’s delivery of a vehicle, the consumer should contact the dealer directly.",
    },
    libertyMutualBlock: {
        title: "Save with insurance customized for your vehicle.",
        subTitle: "Only pay for what you need",
        btnText: "Get Your Quote",
        disclaimer: "This is a non-exclusive offer for auto insurance that is solely recommended, offered, sold, and underwritten by Liberty Mutual Insurance Company and its affiliates.",
    },
    vsc: {

        coveragePlans: [

            {

                title: "Basic Coverage",

                description: "Covers Powertrain Coverage plus Comprehensive Coverage for <strong>65,000 miles</strong> from the time you purchase the car.",

                heroImage: "BasicCoverage",

                amountPerMonth: "28",

                milesLimit: "65000",

                timeLimit: "Unlimited",

                coverageDetails: {

                    "Powertrain Coverage": ["Engine", "Transmission", "Drive Axle", "Unlimited"],

                    "Comprehensive Coverage": ["Heating/Cooling", "Air Condition (Factory Installed Units Only)", "Braking", "Suspension", "Steering", "Fuel Delivery", "Vehicle Hardware", "Electrical", "Exclude Components", "65,000 Miles"],

                },

            },

            {

                title: "Peace of Mind",

                description: "Covers Powertrain Coverage plus Comprehensive Coverage for <strong>150,000 miles</strong> from the time you purchase the car.",

                heroImage: "PeaceOfMind",

                amountPerMonth: "32",

                milesLimit: "150000",

                timeLimit: "Unlimited",

                coverageDetails: {

                    "Powertrain Coverage": ["Engine", "Transmission", "Drive Axle", "Unlimited"],

                    "Comprehensive Coverage": ["Heating/Cooling", "Air Condition (Factory Installed Units Only)", "Braking", "Suspension", "Steering", "Fuel Delivery", "Vehicle Hardware", "Electrical", "Exclude Components", "150,000 Miles"],

                },

            },

            {

                title: "Lifetime Coverage",

                description: "Covers Powertrain Coverage plus Comprehensive Coverage for <strong>Unlimited miles</strong> from the time you purchase the car.",

                heroImage: "LifetimeCoverage",

                amountPerMonth: "38",

                milesLimit: "Unlimited",

                timeLimit: "Unlimited",

                coverageDetails: {

                    "Powertrain Coverage": ["Engine", "Transmission", "Drive Axle", "Unlimited"],

                    "Comprehensive Coverage": ["Heating/Cooling", "Air Condition (Factory Installed Units Only)", "Braking", "Suspension", "Steering", "Fuel Delivery", "Vehicle Hardware", "Electrical", "Exclude Components", "Unlimited Miles"],

                },

            },

        ],
    },
    vscLearnMore: {
        powertrainCoverage: [
            {
                title: "Engine",
                description: "Engine block and cylinder head(s), and all internally-lubricated parts, seals and gaskets,"
                    + "pistons, connecting rods, crankshaft, cam shaft, push rods, valves, rocker arms, timing gear," +
                    "chain and belt, harmonic balancer, oil pump, oil cooler, oil pan, water pump, fuel pump, " +
                    "intake manifold, exhaust manifold, engine mounts, vacuum pump, all engine pulleys, rotors, " +
                    "all internally-lubricated parts of the turbocharger/supercharger. The turbocharger/supercharger "
                + "housing is covered only if damaged by the failure of an internally-lubricated part. For hybrid " +
                    "electric vehicles, electric motor and power controller. Plus more!"
            },
            {
                title: "Transmission",
                description: "Transmission case, transfer case, torque converter, and all internally-lubricated parts" +
                    " contained within the cases, seals and gaskets, throttle valve, valve pack, governor, gear" +
                    " and cover, parking gear and pawl, roll pins, chain, springs, pressure regulator valve," +
                    "automatic transmission clutch, speedometer drive gears, accumulators and rings. " + "" +
                    "For hybrid electric vehicles, electronic transmission. Plus more!"
            },
            {
                title: "Drive Axle",
                description: "Front and rear differential housing, transaxle housing, final drive housing," +
                    " and all of the internally-lubricated parts of the foregoing, seals and gaskets, " +"" +
                    "axle shafts, constant velocity joints, universal joints, drive shafts, axle bearings, axles," +
                    " pinion ring, pinion gear, U-joint, prop shaft, and center support bearings. For hybrid electric" +
                    " vehicles, hybrid transaxle. Plus more!"
            }
        ],
        comprehensiveCoverage: [
            {
                title: "Heating/Cooling",
                description: "Water pump, thermostat, radiator, radiator mounting brackets, seals and gaskets," +
                    " fan shroud, fan control module, coolant recovery unit, heater ducts and cabins, blower motor," +
                    " heater core, fan, fan clutch, and fan motor. Plus more!"
            },
            {
                title: "Air Conditioning",
                subtitle: "(Factory Installed Units Only)",
                description: "Air conditioner compressor, clutch, condenser, evaporator, expansion valve, " + "" +
                    "seals and gaskets, accumulator, idler pulley, bearings, blower motor, " +
                    "temperature control programmer, power module, high/low cut off switch, AC " +
                    "serpentine belt tensioner, AC serpentine belt bearing and pulley. Plus more!"
            },
            {
                title: "Braking",
                description: "Master cylinder, power assist booster, wheel cylinders, combination valves, " + "" +
                    "proportioning valves, seals and gaskets, metal hydraulic lines and fittings, " +
                    "disc calipers, backing plates, springs, electronic anti-lock brake system (ABS), " + "" +
                    "parking brake linkage, parking brake cables, rear actuators, ABS wheel speed sensors, " +
                    "ABS hydraulic pump/motor assembly, and metering valve. Plus more!"
            },
            {
                title: "Suspension",
                description: "Struts, strut bar, bushings, mounting plates, and retainers, seals and gaskets, " +
                    "upper control arm, lower control arm, control arm shafts, control arm bushings, upper " +
                    "ball joints, lower ball joints, steering knuckle, wheel bearings, stabilizer " +
                    "shaft, linkage and bushings, torsion bars, mounts and bushings, bar bushings, spindle, " +
                    "spindle supports, radius arm and bushings, coil springs and bushings, " +
                    "leaf springs and bushings, strut cartridge, McPherson struts, track bar, " +
                    "track bar links and bushings. Plus more!"
            },
            {
                title: "Steering",
                description: "Gear housing and all internally-lubricated parts, including " +
                    "the rack and pinion, seals and gaskets, power steering pump, main shaft, " +
                    "intermediate shafts, couplings, steering cooler, steering cooler lines, " +
                    "power cylinder, pitman arm, idler arm, tie rod, tie rod ends, control valve, " +
                    "electric power steering motor,  sensors and computer module, fluid reservoir, " +
                    "and rack and pinion control valve. Plus more!"
            },
            {
                title: "Fuel Delivery",
                description: "Fuel pump, fuel tank, lift pump, sending unit, metal fuel lines, fuel injectors, " +
                    "fuel injector pump, fuel injection valve, throttle body, cable, position sensor, " +
                    "and linkage, fuel mixture control processor/module and sensors , oxygen sensor, " +
                    "diesel injectors, diesel injector pump, vacuum booster pump, fuel distributor, " +
                    "pressure regulator, and fuel rail. Seals and gaskets are covered for listed components. Plus more!"
            },
            {
                title: "Vehicle Hardware",
                description: "Bumper impact absorbers, headlamp mounting brackets, parking and side lamp bodies " +
                    "and sockets, hood latch, cables, hinges and springs, side door handles and hinges, " +
                    "glove box lock/assembly, ash tray assembly, manual seat track assembly, trunk lid hinges, "+
                    "torsion bars and striker plate."
            },
            {
                title: "Electrical",
                description: "Starter, starter solenoid, seals and gaskets, alternator, voltage regulator, " +
                    "distributor, ignition coil, wiring harness, starting solenoids, ignition switch, lock " +
                    "and tumbler, brake light switch, horns, manually operated switches, " +
                    "power door locks, power trunk solenoid, window motors and regulators, mirror motors, " +
                    "power antenna motor, power seat motor, cruise control, turn signal switch, " +
                    "headlamp switch, dimmer switch, wiper motors, wiper/washer pump, power sunroof motor, " +
                    "power sunroof switches, convertible top motor and switches, " +
                    "steering wheel multifunction switches, memory seat switches, electronic level control module, " +
                    "instrument cluster, keyless entry system, electronic anti-theft device, " +
                    "main engine wiring harness, engine control module, backup light switch, wiring harnesses, " +
                    "manifold absolute and differential pressure sensors, cam shaft position sensor, " +
                    "barometric pressure sensor, cooling fan relay, air control solenoid, knock sensor, " +
                    "oxygen sensor, mass air flow sensor, and coolant temperature sensor. " +
                    "For hybrid electric vehicles, inverter, generators, electronic display monitor, " +
                    "integrated starter generator, generator assembly, inverter assembly and converter, " +
                    "engine power control module, and motor generator. Plus more!!"
            },
            {
                title: "Exclude Components",
                description: "Manual clutch assembly, including pilot bearing assembly, throw out bearing, " +
                    "clutch engagement arm and pivot, clutch disc, and pressure plate, glow plugs, " +
                    "battery cables, battery case and mounting hardware, plug-in outlets, plug-in cable, " +
                    "trickle charger cable, hydrogen cell fuel stack, all filters, " +
                    "coolants and fluids (unless in conjunction with a covered repair), PVC valves, " +
                    "spark plugs, plug wires, EGR valve, exhaust pipes, emission components, " +
                    "catalytic converters, mufflers, resonators, shock absorbers, weather strips, " +
                    "metal trim, plastic trim, carpet, cup holders, vinyl  and convertible tops, " +
                    "convertible frame and mechanism, sunroof track and mechanism, safety restraint systems or " +
                    "any part thereof, brake drums, brake rotors, solar powered devices, glass,  lenses, " +
                    "headlamp assemblies, LED assemblies, ballasts, H.I.L.E.D. cooling systems, " +
                    "body parts and/or body panels, broken glass, trim, moldings, tires, wheels, upholstery, " +
                    "paint, bright metal work, worn or carbon fouled piston rings, burnt valves, " +
                    "electronic device software, or non-factory installed, non-dealer installed, " +
                    "and non-OEM systems, including, without limitation, audio, navigation, remote start, " +
                    "security systems, speakers, antennas, cellular phones, satellite components, " +
                    "video components, guidance systems, personal computers, and phone systems. Brake pads, " +
                    "shoes, wiper blades, wiper arms, batteries, drive belts, rubber hoses, sealed beams, " +
                    "fuses, xenon lighting systems, and light bulbs. Tire balance or wheel alignment " +
                    "(unless in conjunction with a covered repair).!"

            },

        ]
    },
    preQualify: {
        form: {
            pageTitle: "Get Pre-Qualified",
            pageSubtitle:
                "See what offers are available to you without impacting your credit score and " +
                "<strong>save up to 60 minutes</strong> at the dealership.",
            formTitle: "Please complete the required fields. This allows us to calculate accurate payments for you.",
            formWarning: "This will <span class='text-primary'>NOT</span> impact your credit score."
        },
        creditAppText: ["Get prequalified for finance and/or lease programs (not all lenders offer lease options)",
            "Check eligibility for additional rebates and incentives",
            "Verify payoff information on your trade-in (if available)",
            "Compare cash and finance offers (including all taxes and fees)"],
    },
    preQualifySuccess: {
        pageTitle: "Congratulations!",
        pageSubtitle: "You are pre-qualified for finance and lease programs",
        explanation: "Your pre-qualification will all you to see personalized payment options on New and Used " +
            "vehicles listed for the next 14 days",
        howItWorks: "How it works...",
        marketBlockOne: "Find the vehicle you like",
        marketBlockTwo: "Build your deal",
        marketBlockThree: "Submit your finance application for final approval",
        btn: "Close",
        disclaimer: "",
    }
};
