<template>
    <!-- Parent Chat Index, from here we will delegate which chat is used-->
    <div v-if="!chatConfigLoader.isLoading && isChatEnabled">
        <!-- eslint-disable vue/no-v-html -->
        <script
            v-if="isDealerChat && hasCodeBetweenScriptTags"
            id="code-between-script"
            type="application/javascript"
            v-html="codeBetweenScriptTags"
        ></script>
        <script
            v-else-if="isDealerChat && !hasCodeBetweenScriptTags && hasNoScriptTags"
            id="has-no-script"
            type="application/javascript"
            v-html="script"
        ></script>
        <!-- eslint-enable vue/no-v-html -->
        <reputation-chat-v2 v-else :dealer-chat-config="chatConfig" />
    </div>
</template>
<script>
import { call, get } from "vuex-pathify";
import ReputationChatV2 from "Components/ChatV2/ReputationChat.vue";
import lodashGet from "lodash/get";

export default {
    name: "ChatV2",
    components: { ReputationChatV2 },
    data() {
        return {
            buyAtHomeCampaigns: [
                "Nissan CS IdP",
                "Nissan Digital Retail Local",
                "Nissan Digital Retail Dev",
                "Nissan Digital Retail Beta",
                "Nissan SHOP@HOME",
            ],
        };
    },
    computed: {
        dealerId: get("context/dealerId"),
        chatConfig: get("context/chatConfig@data"),
        chatConfigLoader: get("context/chatConfig@loader"),
        campaignName: get("context/campaign"),
        isBuyAtHomeCampaign() {
            const result = this.buyAtHomeCampaigns.includes(this.campaignName);
            return result;
        },
        isChatEnabled() {
            return lodashGet(this.chatConfig, "chatEnabled", false) || false;
        },
        isDealerChat() {
            const isDealerChat = lodashGet(this.chatConfig, "isDealerChat", false) || false;
            return isDealerChat;
        },
        script() {
            const isScriptSetup = lodashGet(this.chatConfig, "script", null);
            const script = isScriptSetup ? atob(isScriptSetup).replaceAll('"', "'") : null;
            return script;
        },
        hasNoScriptTags() {
            const scriptTagRegex = /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi;
            const result = !scriptTagRegex.test(this.script);
            return result;
        },
        hasCodeBetweenScriptTags() {
            const codeRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/;
            const match = this.script.match(codeRegex);
            const result = match && match[1].trim().length > 0;

            return result;
        },
        codeBetweenScriptTags() {
            const codeRegex = /<script\b[^>]*>([\s\S]*?)<\/script>/;
            const match = this.script.match(codeRegex);
            let result = "";

            if (match && match[1].trim().length > 0) {
                // Code found between <script> tags
                result = match[1].trim();
            }

            return result;
        },
    },
    watch: {
        script(value) {
            if (value && !this.hasCodeBetweenScriptTags && this.isDealerChat) {
                this.addDealerScript();
            }
        },
    },
    created() {
        if (this.dealerId) {
            this.fetchChatConfig(this.dealerId);
        }
    },
    methods: {
        fetchChatConfig: call("context/fetchChatConfig"),
        addDealerScript() {
            const script = document.createElement("script");
            script.id = "added-dealer-script";

            // Regular expression to extract all attributes
            const attrRegex = /(\w+)='([^']*)'/g;
            const attributeMatches = this.script.matchAll(attrRegex);

            for (const [, attrName, attrValue] of attributeMatches) {
                script.setAttribute(attrName, attrValue);
            }

            document.body.appendChild(script);
        },
    },
};
</script>
