<template>
    <modal id="EV-promotions-modal" divider hide-footer hide-header>
        <div class="lender-hero-image">
            <primary-link class="position-relative link">
                <i aria-hidden="true" class="close-icon material-icons" @click="closeModal"> close </i>
            </primary-link>
        </div>
        <div class="header">
            <p class="text-center title">Upgrade to 100% Electric!</p>
            <hr class="divider mt-3 mb-4" />
        </div>
        <div class="body-content d-flex justify-content-center">
            <div class="row mt-3 max-width--440">
                <div class="col col-12">
                    <div class="text text-center">
                        Embodying the ideal blend of luxury, technology, and power, BMW delivers returning owners a
                        thrilling, sustainably designed all-electric line-up.
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex flex-column">
            <div class="w-100">
                <primary-btn class="get-started-btn w-100" @click.native="showAllEVs">
                    View All BMW EV Vehicles
                </primary-btn>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal";
import PrimaryLink from "Components/Links/primaryLink";
import { get } from "vuex-pathify";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";
import MfaRedirectionMixin from "Modules/Home/components/Home/MfaRedirectionMixin.vue";

export default {
    name: "EVPromotionsModal",
    components: {
        PrimaryBtn,
        Modal,
        PrimaryLink,
    },
    mixins: [MfaRedirectionMixin],
    methods: {
        closeModal() {
            this.$bvModal.hide("EV-promotions-modal");
        },
        showAllEVs() {
            const filters = { fuelTypes: ["Electric"] };
            this._mixin_handleRedirectionsWithFilters(filters);
            this.closeModal();
        },
    },
};
</script>

<style lang="scss" scoped>
.lender-hero-image {
    position: absolute;
    width: 100%;
    height: 220px;
    left: 0px;
    top: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    @include themify($themes) {
        background-image: themed("EV-promotional-banner-lg");

        @include media-breakpoint-down(sm) {
            background-image: themed("EV-promotional-banner-md");
        }
    }
}

.col {
    padding-right: 0px;
    padding-left: 0px;
}

.lender-hero-image:after {
    top: 0;
    left: 0;
    z-index: 2;
}

.header {
    padding-top: 215px;
}

.body-content {
    line-height: 1.4;
    margin-bottom: px2rem(30);
    max-width: 440px;
}

.title {
    font-size: px2rem(28);
    @include media-breakpoint-down(sm) {
        font-size: px2rem(26);
    }
}

.text {
    font-size: px2rem(16);
}

.get-started-btn {
    margin-bottom: px2rem(5) !important;
}

.divider {
    width: 178px;
    height: 2px;
    @include themify($themes) {
        background-color: themed("primary-color");
    }
}

.link {
    z-index: 3;
    top: 10px;
    right: 10px;
    position: absolute !important;
}

.button {
    margin: 0 10px;
}

.close-icon {
    color: $black;
}
</style>
