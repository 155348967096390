import Vue from "vue";
import VueI18n from "vue-i18n";
import globalMessages from "Locales/en";
import lodashGet from "lodash/get";
import lodashFindLast from "lodash/findLast";
import lodashDefaultsDeep from "lodash/defaultsDeep";

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: "en", // set locale
    fallbackLocale: "en",
    messages: {
        en: {},
    }, // set locale messages

    //usage: set default ex. $t("someKey", {default: "my default text"});
    missing: (locale, key, vm, values) => {
        let defaultObj = lodashFindLast(values, "default");

        // handle translation missing
        return lodashGet(defaultObj, "default") || key;
    },
});

const theme = lodashGet(window, "_CAMPAIGN.theme");

import(`Locales/${theme}/en.js`)
    .then((messages) => {
        const mergedMessages = lodashDefaultsDeep(messages.default, globalMessages);
        i18n.setLocaleMessage("en", mergedMessages);
    })
    .catch(() => {
        // Do nothing, no theme locale file exists
    });
