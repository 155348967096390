<template>
    <b-button v-bind="$attrs" :variant="variant" class="theme-styles" :disabled="isDisabled">
        <div v-if="loading">
            <b-spinner v-if="loading" small label="Spinning" />
            <span class="ml-2">Loading...</span>
        </div>
        <span v-else key="ready">
            <slot> Message </slot>
        </span>
    </b-button>
</template>

<script>
export default {
    name: "BaseBtn",
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        variant: {
            type: String,
            required: false,
            default: "primary",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        isDisabled() {
            const disabled = this.loading || this.disabled;

            return disabled;
        },
    },
};
</script>

<style lang="scss" scoped>
.theme-styles {
    @include themify($themes) {
        border-radius: themed("button-radius");
    }
}
</style>
