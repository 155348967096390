import get from "lodash/get";
import isNil from "lodash/isNil";

const initialState = {
    phoneNumber: get(window, "_CAMPAIGN.phoneNumber", null),
    domain: get(window, "_CAMPAIGN.domain", null),
    theme: get(window, "_CAMPAIGN.theme", null),
    channel: get(window, "_CAMPAIGN.channel", null),
    pinEnabled: get(window, "_CAMPAIGN.pinEnabled", false),
    warrantyEnabled: get(window, "_CAMPAIGN.warrantyEnabled", false),
    tenantId: get(window, "_CAMPAIGN.tenantId", null),
    hasVolieConfig: get(window, "_CAMPAIGN.hasVolieConfig", false),
    paymentType: get(window, "_CAMPAIGN.paymentType", null),
    ssos: get(window, "_CAMPAIGN.ssos", []),
    externalOffers: get(window, "_CAMPAIGN.externalOffers", null),
    productId: get(window, "_CAMPAIGN.productId", null),
    programId: get(window, "_CAMPAIGN.programId", null),
    noPaymentDays: get(window, "_CAMPAIGN.noPaymentDays", null),
    nonLTWVehicleEnabled: get(window, "_INVENTORY_CONFIG.nonLTWVehicleEnabled", null),
    stockTypeConditions: get(window, "_INVENTORY_CONFIG.stockTypeConditions"),
    cobrowseLicenseKey: process.env.COBROWSE_LICENSE_KEY,
    dealerizedMicrosite: get(window, "_CONTEXT.dealerizedMicrosite", false),
    dealerPhoneNumber: get(window, "_CONTEXT.dealerPhoneNumber", null),
};

const getters = {
    phoneNumber: (state) => {
        let dealerPhoneNumber = state.dealerPhoneNumber ? state.dealerPhoneNumber.split(/[-() ]+/).join("") : null;
        return state.dealerizedMicrosite ? dealerPhoneNumber : state.phoneNumber;
    },
    phoneNumberFormatted: (state) => {
        if (state.dealerizedMicrosite === true) {
            return state.dealerPhoneNumber;
        } else if (!isNil(state.phoneNumber)) {
            let phoneNumber = state.phoneNumber;
            return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6, 10)}`;
        }
        return "";
    },
    callCenterEnabled: (state) => {
        return state.hasVolieConfig;
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
};
