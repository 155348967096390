<template>
    <modal
        id="ghost-account-modal"
        aria-label="Register Account Modal"
        hide-footer
        :no-close-on-backdrop="false"
        v-bind="$attrs"
        divider
        v-on="$listeners"
        @hidden="resetData"
    >
        <template slot="title">
            <div class="d-flex justify-content-center w-100 mb-2">
                <div class="col col-9 col-md-12">
                    <div class="d-flex justify-content-center align-items-center">
                        <b-img :src="themeImg" :alt="alt + '-logo'" class="logo-img img-fluid mr-3" />
                        <div class="title nissan-font text-gray">BUY<span class="text-primary">@HOME</span></div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center w-100">
                <h5 class="modal-title pb-1">
                    <span>{{ buildModalTitle() }}</span>
                </h5>
            </div>
            <div class="d-flex justify-content-center w-100">
                <div class="modal-subtitle pb-1">
                    <span>{{ buildModalSubTitle() }}</span>
                </div>
            </div>
        </template>

        <no-share />

        <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.stop.prevent="passes(onSubmit)">
                <required-indicator />

                <b-row v-if="!isGhostAccountCookie">
                    <b-col cols="12">
                        <b-form-group class="first_name_group" label-for="firstName" label="First Name*">
                            <validation-provider
                                v-slot="validationContext"
                                vid="firstName"
                                name="First Name"
                                rules="required"
                            >
                                <!-- prettier-ignore-attribute :state -->
                                <b-input
                                    id="firstName"
                                    v-model="form.firstName"
                                    type="text"
                                    placeholder="Enter First Name"
                                    autocomplete="given-name"
                                    :required="true"
                                    :state="getValidationState(validationContext)"
                                />
                                <!-- prettier-ignore -->
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="!isGhostAccountCookie">
                    <b-col cols="12">
                        <b-form-group class="last_name_group" label-for="lastName" label="Last Name*">
                            <validation-provider
                                v-slot="validationContext"
                                vid="lastName"
                                name="Last Name"
                                rules="required"
                            >
                                <!-- prettier-ignore-attribute :state -->
                                <b-input
                                    id="lastName"
                                    v-model="form.lastName"
                                    type="text"
                                    placeholder="Enter Last Name"
                                    autocomplete="family-name"
                                    :required="true"
                                    :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-form-group class="email_group" label-for="email" label="Email*">
                            <validation-provider
                                v-slot="validationContext"
                                vid="email"
                                name="Email"
                                rules="required|email"
                                mode="passive"
                            >
                                <!-- prettier-ignore-attribute :state -->
                                <b-input
                                    id="email"
                                    v-model="form.email"
                                    type="email"
                                    autocomplete="email"
                                    placeholder="Enter Email"
                                    :state="getValidationState(validationContext)"
                                    :required="true"
                                    :readonly="isGhostAccountCookie"
                                    @blur="validationContext.validate()"
                                />
                                <invalid-email-message :validation-context="validationContext" />
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12">
                        <b-form-group class="phone_group" label-for="phoneNumber" label="Phone">
                            <validation-provider
                                v-slot="validationContext"
                                vid="phoneNumber"
                                name="Phone Number"
                                rules="phone"
                                mode="passive"
                            >
                                <!-- prettier-ignore-attribute :state -->
                                <input-mask
                                    id="phoneNumber"
                                    ref="phoneNumber"
                                    v-model="form.phoneNumber"
                                    :mask="'(###) ###-####'"
                                    maxlength="14"
                                    class="form-control d-inline-block"
                                    :class="
                                        validationContext.validated
                                            ? validationContext.invalid
                                                ? ' is-invalid'
                                                : ' is-valid'
                                            : ''
                                    "
                                    :state="validationContext.invalid && validationContext.validated ? false  : null"
                                    placeholder="Enter Phone Number"
                                    autocomplete="tel-area-code"
                                    aria-required="true"
                                    @blur.native="validationContext.validate()"
                                />
                                <div v-if="validationContext.invalid" class="invalid-feedback d-block">
                                    {{ validationContext.errors[0] }}
                                </div>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="!isGhostAccountCookie">
                    <b-col cols="12">
                        <b-form-group label="Zip Code*" label-for="zipCode">
                            <validation-provider
                                v-slot="validationContext"
                                vid="zipCode"
                                name="Zip Code"
                                rules="required|digits:5"
                            >
                                <b-input
                                    id="zipCode"
                                    v-model="form.zipCode"
                                    type="text"
                                    placeholder="Enter Zip Code"
                                    autocomplete="postal-code"
                                    :state="getValidationState(validationContext)"
                                    :required="true"
                                />
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <div class="mb-3">
                    <validation-provider v-slot="validationContext" vid="Terms" name="Terms" rules="required:true">
                        <b-form-checkbox
                            id="Terms"
                            v-model="form.terms"
                            type="checkbox"
                            aria-required="true"
                            required
                            :state="getValidationState(validationContext)"
                            @change="determineIfUnchecked"
                        >
                            <span class="consent-font">
                                By clicking the Continue button below I acknowledge I have read and agree to
                            </span>
                            <primary-link
                                href="https://www.nissanusa.com/privacy.html#legal"
                                class="font-style-consent"
                                target="_blank"
                                aria-label="Terms of Use (New Window)"
                                @click.native="trackEvent('terms')"
                            >
                                Terms of Use
                            </primary-link>
                            <span class="consent-font"> and </span>
                            <primary-link
                                href="https://www.nissanusa.com/privacy.html"
                                target="_blank"
                                class="font-style-consent"
                                aria-label="Privacy Notice (New Window)"
                                @click.native="trackEvent('privacy')"
                            >
                                Privacy Notice.
                            </primary-link>
                        </b-form-checkbox>
                        <div class="text-danger mt-1">
                            {{ validationContext.errors[0] }}
                        </div>
                    </validation-provider>
                </div>

                <div class="disclaimer-content mb-3">
                    <div class="p-1">
                        {{ formDisclaimer }}
                    </div>
                </div>

                <primary-btn type="submit" class="d-block sign-in-btn" :loading="loading"> Continue </primary-btn>
            </b-form>
        </validation-observer>

        <hr />

        <div class="mt-4 mb-2 block text-center">
            <div v-if="isGhostAccountCookie">
                Not {{ form.firstName }}?
                <primary-link
                    class="font-style-consent"
                    target="_blank"
                    aria-label="Not you? Click Here"
                    @click.native="notYouClickHere"
                >
                    Click Here
                </primary-link>
            </div>
        </div>
        <div class="d-flex justify-content-center mb-2">Sign in with</div>
        <div class="d-flex flex-column justify-content-center align-items-center">
            <google-one-tap :redirect-path="'/' + linkPath" />
        </div>
    </modal>
</template>
<script>
import Modal from "Components/Modal";
import api from "@/api";
import { call, get } from "vuex-pathify";
import { TheMask as InputMask } from "vue-the-mask";
import lodashGet from "lodash/get";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import CampaignProductEnum from "@/libs/CampaignProductEnum";
import NoShare from "Modules/Account/components/NoShare.vue";
import formValidation from "@/mixins/formValidation";
import InvalidEmailMessage from "Modules/Profile/views/InvalidEmailMessage.vue";
import RequiredIndicator from "Components/RequiredIndicator.vue";
import PrimaryLink from "Components/Links/primaryLink.vue";
import NissanLogo from "Modules/NoProfile/images/nissan-logo.png";
import GoogleOneTap from "Modules/Account/GoogleOneTap.vue";
import { clientSessionStorage } from "@/libs/clientStorage";
import lodashIsNil from "lodash/isNil";
import lodashUniqBy from "lodash/uniqBy";
import lodashHead from "lodash/head";
import lodashSize from "lodash/size";
import lodashIsEmpty from "lodash/isEmpty";

export default {
    name: "GhostAccountModal",
    components: {
        GoogleOneTap,
        PrimaryLink,
        RequiredIndicator,
        InvalidEmailMessage,
        NoShare,
        PrimaryBtn,
        Modal,
        InputMask,
    },
    mixins: [formValidation],
    data() {
        return {
            loading: false,
            linkPath: null,
            ghostRegistrationSuccessful: null,
            // TODO: remove hardcoded values that helped here
            form: {
                firstName: null,
                lastName: null,
                phoneNumber: null,
                email: null,
                password: null,
                // Address must be "Unknown" for this Modal to do its job.
                address: "Unknown",
                address2: null,
                city: null,
                stateCode: null,
                zipCode: null,
                lat: null,
                lng: null,
                county: null,
                smsAllowed: false,
                terms: null,
            },
            formDisclaimer:
                "By providing my phone number and clicking Continue below I verify that the phone number " +
                "provided is my number, and I authorize Nissan its affiliates and dealers to make telemarketing " +
                "calls and send advertising text messages to the phone number provided. I understand that " +
                "telemarketing calls and text messages may be placed using pre-recorded or automated dialing " +
                "systems, and that my consent is not a condition to purchase any product or service. " +
                "I understand that I may revoke my consent at any time. Message and data rates may apply. Please" +
                " visit Nissan's Privacy Policy for information about how we collect, use, disclose, and protect " +
                "your data.",
            themeLogos: {
                nissan: NissanLogo,
            },
            isGhostAccountCookie: false,
        };
    },
    computed: {
        productId: get("campaign/productId"),
        featureFlags: get("context/featureFlags"),
        theme: get("campaign/theme"),
        alt() {
            const result = this.theme;
            return result;
        },
        themeImg() {
            const result = this.themeLogos[this.theme];
            return result;
        },
        isECommerceProduct() {
            const result = this.productId === CampaignProductEnum.ECOMMERCE;
            return result;
        },
        isGhostAccountRegisterFeature() {
            const result = lodashGet(this.featureFlags, "GHOST_ACCOUNT_REGISTER_FEATURE", false) || false;
            return result;
        },
    },
    mounted() {
        this.initEventListeners();
        this.readGhostUserCookie();
    },
    methods: {
        registerGhostProfile: call("account/registerGhostProfile"),
        async onSubmit() {
            this.loading = true;
            await this.verifyAddress();
        },
        showModal() {
            if (this.isECommerceProduct && this.isGhostAccountRegisterFeature === true) {
                // There are times when the save modal may be invisible under this modal. Close it as not needed.
                this.$bvModal.hide("no-profile-save-modal");
                this.$bvModal.show("ghost-account-modal");
            }
        },
        initEventListeners() {
            // listening for ghost-account-modal event that can contain a redirect link path
            this.$root.$on("ghost-account-modal", this.showModalAndSetLinkPath);
        },
        showModalAndSetLinkPath(linkPath) {
            // set redirect link path from emitted event if it's not undefined or null
            this.linkPath = lodashIsNil(linkPath) ? null : linkPath;
            this.showModal();
        },
        async verifyAddress(withGeoCoding) {
            if (!this.isFormValid()) {
                const toastMessage = "Please fill all the required fields properly.";
                this.$bvToast.toast(toastMessage, {
                    title: "Required Fields",
                    autoHideDelay: 3000,
                    variant: "danger",
                    solid: true,
                });
                this.handleAddressSuggestionRejected();
                return;
            }

            const attemptGeoCode = lodashIsNil(withGeoCoding) ? true : withGeoCoding;

            const geoCodeSuccess = async () => {
                this.$emit("verification-success", {
                    form: this.form,
                    observer: this.$refs.observer,
                });

                this.submitGhostRegisterForm();
            };

            if (attemptGeoCode) {
                this.geoCodeAddress(geoCodeSuccess);
            } else {
                await geoCodeSuccess();
            }
        },

        handleAddressSuggestionRejected() {
            this.$emit("verification-failure");
        },
        submitGhostRegisterForm() {
            this.registerGhostProfile(this.form)
                .then(() => {
                    this.handleGhostRegisterSuccess(this.form);
                })
                .catch((error) => {
                    this.handleGhostRegisterError(error);
                })
                .finally(() => {
                    if (this.ghostRegistrationSuccessful) {
                        this.loginSuccess();
                    } else {
                        this.loginError();
                    }
                });
        },
        loginSuccess() {
            if (this.linkPath) {
                document.location = `/login-success/ghost?redirectPath=${this.linkPath}`;
            } else {
                document.location = `/login-success/ghost`;
            }
        },
        loginError() {
            this.loading = false;
            this.ghostRegistrationSuccessful = null;
            this.$bvToast.toast("Something went wrong, please try again!", {
                autoHideDelay: 3000,
                variant: "danger",
                solid: true,
            });
        },
        handleGhostRegisterSuccess(form) {
            this.ghostRegistrationSuccessful = true;
            clientSessionStorage.setItem("cscookiebackup", JSON.stringify(form));
        },
        handleGhostRegisterError() {
            this.ghostRegistrationSuccessful = false;
        },
        geoCodeAddress(successCallback) {
            this.callGeocode(this.form)
                .then((response) => {
                    const geocodeResults = lodashGet(response, "data", []);

                    /* Prevent multiple results with the same
                     * address differing only by properties we don't
                     * display such as lat/lon
                     */
                    const uniqueGeocodeResults = lodashUniqBy(geocodeResults, "address.fullAddress");

                    this.handleGeocodeResults(uniqueGeocodeResults, successCallback);
                })
                .catch((err) => {
                    this.handleGeocodeResults([]);
                });
        },
        callGeocode(profileForm) {
            return api.post("/profile/geocode", profileForm).then((response) => {
                return response;
            });
        },
        /**
         * Geocode results are handled differently depending on how
         * many there are (0, 1, n > 1)
         * @param results
         */
        handleGeocodeResults(results, successCallback) {
            this.matchedAddresses = results;
            const numberOfResults = lodashSize(results);

            // TODO: I've not yet found a Zip code that produces multiple addresses. Can a ZC do so?
            switch (numberOfResults) {
                case 0:
                    // TODO: is this only happening when the session is no good or such, then works on a refresh?
                    this.handleNoGeocodeResults();
                    break;
                case 1:
                    this.handleExactMatchGeocodeResult(lodashHead(results), successCallback);
                    break;
                default:
                    this.handleMultipleGeocodeResults(results);
            }
        },

        /**
         * Single geocode result. Set the address fields
         * unless the result is wildly different from user input.
         * In that case, allow the user to select the correct address.
         *
         * @param result
         */
        handleExactMatchGeocodeResult(result, successCallback) {
            const addressResult = result.address;
            this.form.city = addressResult.city;
            this.form.stateCode = addressResult.state;
            this.form.zipCode = addressResult.zipCode;
            this.form.county = addressResult.county;
            this.form.lat = result.lat;
            this.form.lng = result.lng;
            successCallback();
        },
        handleMultipleGeocodeResults(results) {
            // pick the 1st address and go with it. No need to ask user to choose.
            const result = lodashHead(results);
            this.handleExactMatchGeocodeResult(result);
        },
        handleNoGeocodeResults() {
            this.$refs.observer.setErrors("{zipCode: ['No good deeds...']}");
        },
        isFormValid() {
            this.$refs.observer.validate();
            return !Object.keys(this.$refs.observer.fields).some((key) => {
                if (key === "address") {
                    return lodashIsEmpty(this.form.address);
                }
                return this.$refs.observer.fields[key].failed;
            });
        },
        resetData() {
            this.loading = false;
            this.linkPath = null;
            this.ghostRegistrationSuccessful = null;
        },
        trackEvent(name) {
            if (name === "terms") {
                this.$carsaverEventTracker("signUpTerms");
            }
            if (name === "privacy") {
                this.$carsaverEventTracker("signUpPrivacy");
            }
        },
        determineIfUnchecked() {
            if (this.form.terms === false) {
                this.form.terms = null;
            }
        },
        readGhostUserCookie() {
            api.get("/profile/ghost-lead-cookie")
                .then((response) => {
                    const emailFromResponse = response.data?.email;
                    const cSCookieBackup = clientSessionStorage.getItem("cscookiebackup");
                    const isCSCookieBackUpNotNil = !lodashIsNil(cSCookieBackup);

                    if (lodashIsNil(emailFromResponse) && isCSCookieBackUpNotNil) {
                        const parsedCSCookieBackup = JSON.parse(cSCookieBackup);
                        this.useGhostLeadInfoFromSessionStorage(parsedCSCookieBackup);
                        this.isGhostAccountCookie = true;
                    } else {
                        this.useGhostLeadInfoFromCookie(response);
                        this.isGhostAccountCookie = !lodashIsNil(emailFromResponse);
                    }
                })
                .catch((error) => {
                    console.log("Ghost User cookie read  ", error);
                });
        },
        useGhostLeadInfoFromCookie(lead) {
            this.form.firstName = lead.data.firstName;
            this.form.lastName = lead.data.lastName;
            this.form.phoneNumber = lead.data.phoneNumber;
            this.form.email = lead.data.email;
            this.form.zipCode = lead.data.zipCode;
        },
        useGhostLeadInfoFromSessionStorage(cSCookieBackup) {
            this.form.firstName = cSCookieBackup.firstName;
            this.form.lastName = cSCookieBackup.lastName;
            this.form.phoneNumber = cSCookieBackup.phoneNumber;
            this.form.email = cSCookieBackup.email;
            this.form.zipCode = cSCookieBackup.zipCode;
        },
        buildModalTitle() {
            const result = !this.isGhostAccountCookie ? "BUY@HOME Benefits" : "Welcome Back, " + this.form.firstName;
            return result;
        },
        buildModalSubTitle() {
            const fullForm =
                "Please provide your information to get detailed pricing including taxes and fees, get " +
                "a guaranteed offer for your trade-in and more";
            const youAreBack = "Verify your email or phone number below to continue shopping";
            const result = !this.isGhostAccountCookie ? fullForm : youAreBack;

            return result;
        },
        notYouClickHere() {
            console.log("notYouClickHere...");
            // Allows user to go back to the complete form and enter new data for another account.
            // Clear the cookie through a BE call.
            this.deleteGhostUserCookie();
            this.isGhostAccountCookie = false;
        },
        deleteGhostUserCookie() {
            api.delete("/profile/ghost-lead-cookie")
                .then((response) => {})
                .catch((error) => {
                    console.log("Ghost User cookie delete  ", error);
                });
        },
    },
};
</script>
<style lang="scss">
#ghost-account-modal {
    .sign-in-btn {
        width: 100%;
    }
    .disclaimer-content {
        position: relative;
        width: 100%;
        max-height: 57px; /* Set a maximum height for scrollable content */
        overflow-y: auto; /* Enable vertical scrolling when content overflows */
        border: 1px solid #ccc;
        font-weight: 400;
        padding: 6px;
        background-color: #f6f6f6;
        color: #2e2e2e;
        font-size: 12px;
    }
    .consent-font {
        color: #2e2e2e;
        font-size: 12px;
        font-style: normal;
    }
    .font-style-consent {
        font-style: normal;
        font-size: 12px;
    }
    .modal-titles {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .modal-title {
            margin-bottom: 8px;
        }
        .modal-subtitle {
            width: 245px;
        }
    }

    .divider {
        height: 1px;
        width: 128px;
        @include themify($themes) {
            background-color: themed("primary-color");
        }
    }

    .disclaimer,
    .disclaimer-shaded {
        font-size: 10px;
        color: $gray-800;
    }

    .disclaimer-shaded {
        background: $gray-300;
    }
    .already-account {
        font-size: 14px;
        line-height: 16px;
    }
    .sso-btn {
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
        width: 182px;
    }
    .logo-img {
        background-size: contain;
        background-repeat: no-repeat;
        height: 32px;
    }
    .nissan-font {
        font-family: "Nissan Brand", serif;
    }
}
</style>
