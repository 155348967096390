<script>
import lodashGet from "lodash/get";
export default {
    data() {
        return {
            saveReminderModalConfig: {
                eventPostSave: null,
                eventPostCancel: null,
                eventOnHidden: null,
            },
        };
    },

    computed: {
        isDealSaved() {
            const savedFromDealStore = lodashGet(this.$store.state, "deal.deal.data.saved");
            if (savedFromDealStore !== undefined) {
                return savedFromDealStore;
            }

            //TODO verify that this works
            const savedFromCertificateStore = lodashGet(this.$store.state, "certificate.data.saved");
            if (savedFromCertificateStore !== undefined) {
                return savedFromCertificateStore;
            }
            return false;
        },
        isDealFormDirty() {
            return lodashGet(this.$store.state, "deal.dirty", false) || false;
        },
        currentRouteName() {
            return this.$route.name;
        },
        isSaveReminderModalEnabled() {
            const enabledPages = ["deal"];

            if (enabledPages.indexOf(this.currentRouteName) !== -1) {
                if (!this.isDealSaved || this.isDealFormDirty) {
                    return true;
                }
            }

            return false;
        },
    },
    methods: {
        openSaveReminderModal() {
            this.$root.$emit("show-save-reminder-modal", this.saveReminderModalConfig);
        },
    },
};
</script>
