<template>
    <NavBannerContainer v-if="refererUrl && !isOverlayEnabled" name="dealer-link-back">
        <primary-link class="back-link" @click.native="showSaveWorkModal">
            <span> back to dealer website </span>
        </primary-link>
    </NavBannerContainer>
</template>

<script>
import NavBannerContainer from "Modules/NavBanners/NavBannerContainer";
import { get } from "vuex-pathify";
import PrimaryLink from "Components/Links/primaryLink";
export default {
    name: "DealerLinkBackBanner",
    components: { NavBannerContainer, PrimaryLink },
    computed: {
        refererUrl: get("context/refererUrl"),
        userIsLoggedIn: get("context/isLoggedIn"),
        isOverlayEnabled: get("context/overlayEnabled"),
        currentRouteName() {
            return this.$route.name;
        },
        isModalEnabled() {
            const enabledPages = ["no-profile-deal"];

            return enabledPages.indexOf(this.currentRouteName) !== -1;
        },
    },
    methods: {
        showSaveWorkModal() {
            this.$carsaverEventTracker("backToDealerLink");
            if (!this.userIsLoggedIn && this.isModalEnabled) {
                this.$emit("saveWork", this.refererUrl);
            } else {
                document.location = this.refererUrl;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.back-link {
    color: inherit;
    padding-right: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;

    &:before {
        content: "<";
    }
}
</style>
