<template>
    <div class="menu-btn d-flex">
        <b-button
            variant="link"
            aria-label="Open the menu"
            aria-controls="menu-popover-wrapper"
            :aria-expanded="menuExpanded"
            class="p-0"
            @click.stop="togglePopover"
        >
            <i id="main-menu-btn" ref="mainMenuBtn" class="material-icons menu-btn-icon" aria-hidden="true"> menu </i>
            <div class="menu-badge">
                <menu-badge />
            </div>
        </b-button>
        <transition v-enter-to="'fade-enter-to'" name="fade">
            <div v-if="show" id="menu-popover-wrapper" class="transition-wrapper">
                <div ref="popover" class="menu-popover">
                    <div class="header-section text-right">
                        <b-button class="close-btn d-flex" @click="hidePopover">
                            <div class="x-icon">
                                <div class="criss"></div>
                                <div class="cross"></div>
                            </div>
                            Close
                        </b-button>
                    </div>
                    <ul class="menu-links text-center">
                        <li v-for="link in enabledLinks" :key="link.name" class="menu-link-wrapper">
                            <a
                                v-if="link.href === '/logout'"
                                class="menu-link"
                                href="javascript:void(0)"
                                @click="logOut(link)"
                            >
                                <div class="d-flex justify-content-center">
                                    <span>{{ link.name }}</span>
                                </div>
                            </a>
                            <!-- Personal Shopper
                                enabled by passing a link obj {name: <any>, enablePersonalShopperLink: true}
                            -->
                            <a
                                v-else-if="link.enablePersonalShopperLink === true"
                                v-b-modal.chat-modal
                                class="menu-link"
                                href="javascript:void(0)"
                                @click="clickPersonalShopperHandler"
                            >
                                <div class="d-flex justify-content-center">
                                    <span>{{ link.name }}</span>
                                </div>
                            </a>
                            <!-- End of Personal Shopper section  -->

                            <a
                                v-else-if="link.enablePersonalShopperLink !== true"
                                class="menu-link"
                                :href="link.href"
                                @click="trackEvent(link)"
                            >
                                <div class="d-flex justify-content-center">
                                    <span>{{ link.name }}</span>
                                    <menu-badge v-if="link.enableCountBadge" />
                                </div>
                            </a>

                            <div class="separator"></div>
                        </li>
                    </ul>
                </div>
            </div>
        </transition>
        <chat-modal id="chat-modal"></chat-modal>
        <logout ref="logout" />
    </div>
</template>

<script>
import MenuBadge from "./MenuBadge";
import saveReminderModalMixin from "Modules/VehicleAndDeal/components/SaveReminderModal/saveReminderModalMixin";
import noProfileSaveModalMixin from "Modules/NoProfile/components/SaveModal/saveModalMixin";
import ChatModal from "Modules/Vehicle/components/ChatModal";
import Logout from "Components/Logout";
import { get } from "vuex-pathify";
export default {
    name: "MenuBtn",
    components: { ChatModal, MenuBadge, Logout },
    mixins: [saveReminderModalMixin, noProfileSaveModalMixin],
    props: {
        links: {
            // Example of currently available options
            // {
            //   name: "My Garage" (required),
            //   href: "/garage" (optional),
            //   enableCountBadge: true (optional),
            //   disable: false (optional)
            //  }
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            show: false,
            selector: ".menu-popover",
            timerEvents: ["mousedown", "mousemove", "keypress", "scroll", "touchstart", "click"],
            time: null,
            saveDealReminderOnPages: [
                // list route names to enable the modal on
                "deal",
            ],
            saveReminderModalConfig: {
                eventPostSave: "menubtn-showpopover",
                eventPostCancel: "menubtn-showpopover",
                eventOnHidden: null,
            },
        };
    },
    computed: {
        isOverlayEnabled: get("context/overlayEnabled"),
        isUserLoggedIn: get("context/isLoggedIn"),
        enabledLinks() {
            const isNotNewUsedOrDisabled = this.links.filter(
                (item) => item.name !== "Shop New" && item.name !== "Shop Used" && item.disabled !== true
            );
            const result =
                this.isOverlayEnabled === true ? isNotNewUsedOrDisabled : this.links.filter((link) => !link.disabled);

            return result;
        },
        menuExpanded() {
            return this.show ? "true" : "false";
        },
    },
    mounted() {
        // deprecated remove later
        this.initEventListeners();
    },
    methods: {
        logOut(link) {
            this.trackEvent(link);
            this.$refs.logout.logout();
            sessionStorage.removeItem("updatedUserAboutNewTradeValue");
        },
        initEventListeners() {
            this.$root.$on("menubtn-showpopover", this.showPopover);
        },
        hidePopover() {
            this.show = false;

            // deprecated remove later
            // this.clearTimerEvents();

            this.removeOutsideClickListener();
        },

        showPopover() {
            this.show = true;
            // To add an inactivity time use this.initInactivityTimer();
            this.hideOnClickOutside(".menu-btn");
        },

        trackEvent(link) {
            switch (link.name) {
                case "Home":
                    this.$carsaverEventTracker("menuHome");
                    break;
                case "My Garage":
                    this.$carsaverEventTracker("menuMyGarage");
                    break;
                case "Value Trade":
                    this.$carsaverEventTracker("menuValueTrade");
                    break;
                case "Sign Out":
                    this.$carsaverEventTracker("menuSignOut");
                    break;
                default:
                    this.$carsaverEventTracker("menuHome");
            }
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: `Navbar - ${link.name} - Click`,
            });

            return true;
        },

        clickPersonalShopperHandler() {
            const link = {
                name: "Personal Shopper",
            };

            this.trackEvent(link);
        },

        togglePopover() {
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "Navbar - Hamburger - Click",
            });
            if (this.isUserLoggedIn) {
                this.authenticatedMenuLogic();
            } else {
                this.nonAuthenticatedMenuLogic();
            }
        },
        authenticatedMenuLogic() {
            if (this.isSaveReminderModalEnabled && !this.show) {
                this.openSaveReminderModal();
            } else {
                this.displayPopOverLogic();
            }
        },
        nonAuthenticatedMenuLogic() {
            if (this.isModalEnabled && !this.show) {
                this.openNoProfileSaveWorkReminderModal();
            } else {
                this.displayPopOverLogic();
            }
        },
        displayPopOverLogic() {
            if (this.show) {
                this.hidePopover();
            } else {
                this.showPopover();
            }
        },
        // deprecated remove later
        initInactivityTimer() {
            this.resetTimer();
            const that = this;

            // DOM Events
            this.timerEvents.forEach(function (name) {
                document.addEventListener(name, () => that.resetTimer(), true);
            });
        },

        // deprecated remove later
        clearTimerEvents() {
            const that = this;

            this.timerEvents.forEach(function (name) {
                document.removeEventListener(name, () => that.resetTimer(), true);
            });
        },

        resetTimer() {
            const delay = 5000;
            clearTimeout(this.time);
            this.time = setTimeout(this.hidePopover, delay);
        },

        hideOnClickOutside() {
            this.removeOutsideClickListener();
            this.addOutsideClickListener();
        },

        outsideClickListenerHandler(event) {
            const selector = this.selector;
            const target = event.target;
            const btn = this.$refs["mainMenuBtn"].$el || null;

            if (!target.closest(selector) && target != btn) {
                this.hidePopover();
            }
        },

        addOutsideClickListener() {
            document.addEventListener("click", this.outsideClickListenerHandler);
        },

        removeOutsideClickListener() {
            document.removeEventListener("click", this.outsideClickListenerHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
.menu-btn {
    position: relative;

    .fade-enter-to {
        opacity: 0.95;
    }

    & > button {
        color: inherit;
    }

    .menu-badge {
        position: absolute;
        left: 12px;
        top: -4px;

        .badge {
            background-color: #c3002f;
        }
    }

    .menu-popover {
        display: block;
        position: absolute;
        z-index: 1060;
        width: 220px;
        opacity: 0.95;
        background-color: #ffffff;
        top: 0;
        right: 0;
        color: #191919;
        font-size: px2rem(18);
        line-height: 23px;
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);

        .header-section {
            button.close-btn {
                @include themify($themes) {
                    color: themed("primary-color");
                }

                font-size: px2rem(14);
                line-height: 19px;
                font-weight: normal;
                padding: 0;
                margin: 12px 14px 12px auto;
                background: none;
                box-shadow: none;

                .x-icon {
                    width: 17px;
                    height: 16px;
                    position: relative;
                    margin-right: 4px;

                    .criss,
                    .cross {
                        @include themify($themes) {
                            background-color: themed("primary-color");
                        }
                        width: 1px;
                        height: 16px;
                        position: absolute;
                        left: 9px;

                        &:active,
                        &:hover,
                        &:focus {
                            @include themify($themes) {
                                background-color: darken(themed("primary-color"), 10%);
                            }
                        }
                    }
                    .criss {
                        transform: rotate(45deg);
                    }
                    .cross {
                        transform: rotate(-45deg);
                    }
                }

                &:active,
                &:hover,
                &:focus {
                    @include themify($themes) {
                        color: darken(themed("primary-color"), 10%);
                    }
                }
            }
        }

        .menu-links {
            list-style: none;
            padding: 0;
            margin-bottom: 32px;

            .menu-link-wrapper {
                a.menu-link {
                    $menu-link-color: #191919;

                    color: $menu-link-color;

                    &:active,
                    &:hover,
                    &:focus {
                        color: lighten($menu-link-color, 35%);
                        text-decoration: none;
                    }

                    .badge {
                        background-color: #c3002f;
                    }
                }

                .separator {
                    height: 1px;
                    width: 116px;
                    background-color: #d3d3d3;
                    margin: 12px auto;
                }

                &:last-child {
                    .separator {
                        display: none;
                    }
                }
            }
        }
    }
}
</style>
