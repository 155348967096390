<template>
    <div id="upgrade" class="d-flex flex-column min-vh-100 position-relative">
        <a href="#maincontent" class="skiplink tooltip-icon">Skip to main content</a>
        <dealer-link-back-banner @saveWork="showSaveModal" />
        <campaign-promotions-banner></campaign-promotions-banner>
        <navigation />

        <vehicle-banner v-if="showVehicleBanner" :vehicle-id="vehicleOfInterestId" />

        <div id="maincontent" class="wrapper flex-grow-1" tabindex="-1">
            <slot />

            <contact-carsaver v-if="enableContactCarsaverBtn" />
        </div>

        <upgrade-footer />

        <blank-overlay v-if="showBackgroundOverlay" />

        <save-modal v-if="isModalEnabled" />

        <update-trade-modal></update-trade-modal>

        <ghost-account-modal />

        <!-- TODO: CS-5596 - Remove feature flag once feature is "Live"-->
        <!-- If new dealer chat feature is enabled -->
        <chat-v2 />
        <!-- Else original reputation chat feature -->
    </div>
</template>

<script>
import Navigation from "Modules/Navigation";
import Footer from "Components/Footer/index";
import { get } from "vuex-pathify";
import BlankOverlay from "./BlankOverlay";
import ContactCarsaver from "./ContactCarsaver";
import DealerLinkBackBanner from "Modules/NavBanners/DealerLinkBackBanner";
import VehicleBanner from "Components/VehicleBanner";
import SaveModal from "Modules/NoProfile/components/SaveModal";
import noProfileSaveModalMixin from "Modules/NoProfile/components/SaveModal/saveModalMixin";
import ChatV2 from "Components/ChatV2";
import lodashGet from "lodash/get";
import CampaignPromotionsBanner from "Modules/Home/components/CampaignPromotionsBanner";
import GhostAccountModal from "Modules/Account/components/GhostAccountModal.vue";
import UpdateTradeModal from "Components/UpdateTradeModal.vue";

export default {
    name: "BaseLayout",
    components: {
        UpdateTradeModal,
        SaveModal,
        VehicleBanner,
        DealerLinkBackBanner,
        BlankOverlay,
        UpgradeFooter: Footer,
        Navigation,
        ContactCarsaver,
        ChatV2,
        CampaignPromotionsBanner,
        GhostAccountModal,
    },
    mixins: [noProfileSaveModalMixin],
    computed: {
        callCenterEnabled: get("campaign/callCenterEnabled"),
        enableContactCarsaverBtn() {
            return this.callCenterEnabled;
        },
        showBackgroundOverlay: get("context/showBackgroundOverlay"),
        userIsLoggedIn: get("context/isLoggedIn"),
        vehicleOfInterestId: get("context/vehicleOfInterestId"),
        featureFlags: get("context/featureFlags"),
        payoffUpdatedInfo: get("context/payoffUpdatedInfo"),
        homeRoute() {
            let routeName = lodashGet(this.$route, "name");

            return routeName === "plugin-landing-page" || routeName === "home";
        },
        showVehicleBanner() {
            return this.vehicleOfInterestId && this.homeRoute && this.noProfileEnabled;
        },
        noProfileEnabled() {
            return this.$page.isEnabled("no-profile");
        },
        showUpdatedTradeModal() {
            const isEnabled = lodashGet(this.featureFlags, "UPDATED_TRADE_MODAL_DISPLAY", false) || false;
            return isEnabled;
        },
    },
    mounted() {
        const informedUser =
            sessionStorage.getItem("updatedUserAboutNewTradeValue") !== null
                ? JSON.parse(sessionStorage.getItem("updatedUserAboutNewTradeValue"))
                : false;
        if (this.payoffUpdatedInfo !== null && this.showUpdatedTradeModal && !informedUser && this.userIsLoggedIn) {
            this.$bvModal.show("updated-trade-modal");
        }
    },
    beforeDestroy() {
        sessionStorage.removeItem("FuelType");
    },
    methods: {
        showSaveModal(redirectPath) {
            if (redirectPath) {
                this.saveReminderModalConfig.redirectPath = redirectPath;
            }
            this.$root.$emit("show-no-profile-save-modal", this.saveReminderModalConfig);
        },
    },
};
</script>

<style lang="scss">
.wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.skiplink {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.skiplink:focus {
    position: static;
    width: auto;
    height: auto;
    left: 50%;
}
</style>
