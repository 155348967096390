import lodashGet from "lodash/get";
import lodashIsNumber from "lodash/isNumber";
import lodashIsEmpty from "lodash/isEmpty";
import lodashIsNil from "lodash/isNil";
import Vue from "vue";

Vue.filter("emptyDash", function (val, path) {
    if (path !== undefined) {
        val = lodashGet(val, path);
    }
    const placeHolder = "- -";

    /***
     * isEmpty returns true for any number.
     * isNil returns false for empty strings.
     *
     * So for this to work as intended, we must distinguish
     * between strings and numbers before evaluating their
     * emptiness.
     ****/
    if (!lodashIsNumber(val) && lodashIsEmpty(val)) {
        val = placeHolder;
    } else if (lodashIsNil(val)) {
        val = placeHolder;
    }

    return val;
});
