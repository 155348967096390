<template>
    <div
        class="tour-point-wrapper position-absolute"
        :class="{ 'overlay-show-through': isTourPointActive }"
        @click="nextTourPoint"
    >
        <div class="position-relative">
            <tour-point
                :name="tourPoint"
                :disable-overlay="true"
                direction="right-bottom"
                class="contact-carsaver-tour-point"
            >
                <div class="d-flex flex-column">
                    <div class="font-weight-bold">Questions?</div>
                    <p class="m-0 font-italic">Our personal shoppers are here to help.</p>
                </div>
            </tour-point>
            <button class="contact-carsaver-button" aria-label="Open" @click="$bvModal.show('chat-modal')">
                <question-indicator />
            </button>
        </div>
    </div>
</template>

<script>
import QuestionIndicator from "Components/QuestionIndicator";
import TourPointMixin from "Components/TourPoint/TourPointMixin";
import TourPoint from "Components/TourPoint";

export default {
    name: "ContactCarsaver",
    components: { QuestionIndicator, TourPoint },
    mixins: [TourPointMixin],
    data() {
        return {
            tourPoint: "_session-contact-carsaver",
            timerEvents: ["keypress", "scroll", "click"],
            timer: null,
        };
    },
    computed: {
        isTourPointActive() {
            return this.tourPoint === this.activeTourPoint;
        },
    },

    mounted() {
        this.initInactivityTimer();
    },

    methods: {
        activateContactTourPoint() {
            this.activateTourPoints([this.tourPoint]);
        },
        initInactivityTimer() {
            const that = this;

            this.resetTimer();

            // DOM Events
            this.timerEvents.forEach(function (name) {
                document.addEventListener(name, () => that.resetTimer(), true);
            });
        },

        clearTimerEvents() {
            const that = this;

            this.timerEvents.forEach(function (name) {
                document.removeEventListener(name, () => that.resetTimer(), true);
            });
        },

        resetTimer() {
            const delay = 10000;

            clearTimeout(this.timer);
            this.timer = setTimeout(this.activateContactTourPoint, delay);
        },
    },
};
</script>

<style lang="scss" scoped>
.contact-carsaver-tour-point {
    position: fixed;
    right: px2rem(22);
    bottom: px2rem(78);

    @media (max-width: 767px) {
        bottom: px2rem(116);
    }
}

.contact-carsaver-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: px2rem(50);
    height: px2rem(50);
    box-sizing: border-box;
    border: 1px solid $gray-300;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 22px 0 rgba(0, 0, 0, 0.27);
    border-radius: 50%;
    position: fixed;
    right: px2rem(20);
    bottom: px2rem(20);
    z-index: 20;

    @media (max-width: 767px) {
        bottom: px2rem(60);
    }

    &:hover,
    &:focus {
        outline-style: none;
        filter: brightness(80%);
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.5);
    }
}
</style>
