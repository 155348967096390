<template>
    <div>
        <validation-observer ref="observer" v-slot="{ passes }">
            <b-form @submit.stop.prevent="passes(verifyAddress)">
                <required-indicator v-if="showRequiredIndicator" />
                <b-row>
                    <b-col cols="12">
                        <b-form-group label="Address Line 1*" label-for="address">
                            <validation-provider
                                v-slot="validationContext"
                                vid="address"
                                name="Address"
                                rules="required"
                            >
                                <address-lookup
                                    id="address"
                                    v-model="form.address"
                                    placeholder="Enter Address Line 1"
                                    autocomplete="address-line1"
                                    css-class="mb-1"
                                    :validation-state="getValidationState(validationContext)"
                                    :required="true"
                                    @place-change="handleAddressChange"
                                />

                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-form-group label="Address Line 2" label-for="address2">
                            <validation-provider v-slot="validationContext" vid="address2" name="Address2">
                                <b-input
                                    id="address2"
                                    v-model="form.address2"
                                    type="text"
                                    placeholder="Enter Address Line 2"
                                    autocomplete="address-line2"
                                    :state="getValidationState(validationContext)"
                                />
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" md="5">
                        <b-form-group label="City*" label-for="city">
                            <validation-provider v-slot="validationContext" vid="city" name="City" rules="required">
                                <!-- prettier-ignore-attribute :state -->
                                <b-input
                                    id="city"
                                    v-model="form.city"
                                    type="text"
                                    placeholder="Enter City"
                                    autocomplete="address-level2"
                                    :state="getValidationState(validationContext)"
                                    :required="true"
                                />
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="7" md="4">
                        <b-form-group label="State*" label-for="state">
                            <validation-provider v-slot="validationContext" vid="state" name="State" rules="required">
                                <!-- prettier-ignore-attribute :state -->
                                <b-form-select
                                    id="state"
                                    v-model="form.stateCode"
                                    name="state"
                                    autocomplete="address-level1"
                                    :options="stateOptions"
                                    :state="getValidationState(validationContext)"
                                    :required="true"
                                />
                                <!-- prettier-ignore -->
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="5" md="3">
                        <b-form-group label="Zip Code*" label-for="zipCode">
                            <validation-provider
                                v-slot="validationContext"
                                vid="zipCode"
                                name="Zip Code"
                                rules="required|digits:5"
                            >
                                <!-- prettier-ignore-attribute :state -->
                                <b-input
                                    id="zipCode"
                                    v-model="form.zipCode"
                                    type="text"
                                    placeholder="Enter Zip Code"
                                    autocomplete="postal-code"
                                    :state="getValidationState(validationContext)"
                                    :required="true"
                                />
                                <b-form-invalid-feedback>
                                    {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <address-selection-modal
            id="address-selection-modal"
            :entered-address="form"
            :matched-addresses="matchedAddresses"
            @address-suggestion-selected="handleAddressSuggestionSelected"
            @address-suggestion-rejected="handleAddressSuggestionRejected"
            @address-suggestion-rejected-use-original="handleRejectedSuggestionAndUseOriginal"
        />
    </div>
</template>

<script>
import formValidation from "@/mixins/formValidation";
import AddressLookup from "Modules/Profile/components/Address/AddressValidation/AddressLookup";
import AddressSelectionModal from "Modules/Profile/components/Address/AddressValidation/AddressSelectionModal";
import api from "@/api";
import RequiredIndicator from "Components/RequiredIndicator";
import lodashGet from "lodash/get";
import lodashIsEqual from "lodash/isEqual";
import lodashIsEmpty from "lodash/isEmpty";
import lodashIsNil from "lodash/isNil";
import lodashMap from "lodash/map";
import lodashUniqBy from "lodash/uniqBy";
import lodashSize from "lodash/size";
import lodashHead from "lodash/head";
export default {
    name: "AddressForm",
    components: {
        RequiredIndicator,
        AddressSelectionModal,
        AddressLookup,
    },
    mixins: [formValidation],
    props: {
        initializedForm: {
            type: Object,
            required: false,
            default: () => {},
        },
        formServerErrors: {
            type: Object,
            required: false,
            default: () => {},
        },
        showRequiredIndicator: {
            type: Boolean,
            require: false,
            default: true,
        },
    },
    data() {
        return {
            form: {
                address: null,
                address2: null,
                city: null,
                stateCode: null,
                zipCode: null,
                lat: null,
                lng: null,
                county: null,
            },
            stateOptions: [],
            matchedAddresses: [],
        };
    },
    watch: {
        initializedForm: {
            immediate: true,
            deep: true,
            handler(val) {
                if (!lodashIsEmpty(val)) {
                    this.form.address = val.address;
                    this.form.address2 = val.address2;
                    this.form.city = val.city;
                    this.form.stateCode = val.stateCode;
                    this.form.zipCode = val.zipCode;
                }
            },
        },
    },
    mounted() {
        if (!lodashIsEmpty(this.formServerErrors)) {
            this.$refs.observer.setErrors(this.formServerErrors);
        }
    },
    created() {
        this.initStates();
    },
    methods: {
        callGeocode(profileForm) {
            return api.post("/profile/geocode", profileForm).then((response) => {
                return response;
            });
        },
        verifyAddress(withGeoCoding) {
            if (!this.isFormValid()) {
                const toastMessage = "Please fill all the required fields properly.";
                this.$bvToast.toast(toastMessage, {
                    title: "Required Fields",
                    autoHideDelay: 3000,
                    variant: "danger",
                    solid: true,
                });
                this.$emit("verification-failure");
                return;
            }

            const attemptGeoCode = lodashIsNil(withGeoCoding) ? true : withGeoCoding;

            const geoCodeSuccess = () => {
                this.$emit("verification-success", {
                    form: this.form,
                    observer: this.$refs.observer,
                });
            };

            if (attemptGeoCode) {
                this.geocodeAddress(geoCodeSuccess);
            } else {
                geoCodeSuccess();
            }
        },
        isFormValid() {
            this.$refs.observer.validate();
            return !Object.keys(this.$refs.observer.fields).some((key) => {
                if (key === "address") {
                    return lodashIsEmpty(this.form.address);
                }
                return this.$refs.observer.fields[key].failed;
            });
        },
        bindFormFromUserProspect(userProspect) {
            if (!lodashIsEmpty(userProspect.address)) {
                this.form.address = userProspect.address.street1;
                this.form.address2 = userProspect.address.street2;
                this.form.city = userProspect.address.city;
                this.form.stateCode = userProspect.address.state;
                this.form.zipCode = userProspect.address.zipCode;
            }
        },
        initStates() {
            return api.get("/states").then((response) => {
                this.stateOptions = lodashMap(response.data, (state) => {
                    return {
                        value: state.abbreviation,
                        text: state.abbreviation,
                    };
                });
            });
        },
        geocodeAddress(successCallback) {
            this.callGeocode(this.form)
                .then((response) => {
                    const geocodeResults = lodashGet(response, "data", []);

                    /* Prevent multiple results with the same
                     * address differing only by properties we dont
                     * display such as lat/lon
                     */
                    const uniqueGeocodeResults = lodashUniqBy(geocodeResults, "address.fullAddress");

                    this.handleGeocodeResults(uniqueGeocodeResults, successCallback);
                })
                .catch((err) => {
                    this.handleGeocodeResults([]);
                });
        },
        /**
         * Geocode results are handled differently depending on how
         * many there are (0, 1, n > 1)
         * @param results
         */
        handleGeocodeResults(results, successCallback) {
            this.matchedAddresses = results;
            const numberOfResults = lodashSize(results);

            switch (numberOfResults) {
                case 1:
                    this.handleExactMatchGeocodeResult(lodashHead(results), successCallback);
                    break;
                default:
                    this.handleMultipleGeocodeResults(results);
            }
        },

        /**
         * Single geocode result. Set the address fields
         * unless the result is wildly different from user input.
         * In that case, allow the user to select the correct address.
         *
         * @param result
         */
        handleExactMatchGeocodeResult(result, successCallback) {
            const addressResult = result.address;
            const isBusinessAddress = lodashGet(result, "businessAddress");

            // if city, state or zip are different the user should intervene
            if (
                !lodashIsEqual(this.form.city, addressResult.city) ||
                !lodashIsEqual(this.form.stateCode, addressResult.state) ||
                !lodashIsEqual(this.form.zipCode, addressResult.zipCode)
            ) {
                this.showAddressSelector();
                // if it's a business address, we need to notify the user
            } else if (isBusinessAddress) {
                this.showAddressSelector();
            } else {
                this.form.address = addressResult.street1;
                this.form.address2 = addressResult.street2;
                this.form.city = addressResult.city;
                this.form.stateCode = addressResult.state;
                this.form.zipCode = addressResult.zipCode;
                this.form.county = addressResult.county;
                this.form.lat = result.lat;
                this.form.lng = result.lng;
                successCallback();
            }
        },

        /**
         * Multiple geocode results should prompt the user to
         * select an address.
         *
         * @param result
         */
        handleMultipleGeocodeResults(results) {
            this.showAddressSelector();
        },

        showAddressSelector() {
            this.$bvModal.show("address-selection-modal");
        },

        handleAddressChange(addressData) {
            if (!lodashIsEmpty(addressData.city)) {
                this.form.city = addressData.city;
            }
            if (!lodashIsEmpty(addressData.stateAbbreviation)) {
                this.form.stateCode = addressData.stateAbbreviation;
            }
            if (!lodashIsEmpty(addressData.zipCode)) {
                this.form.zipCode = addressData.zipCode;
            }
            this.form.county = addressData.county;
            this.form.lat = addressData.latitude;
            this.form.lng = addressData.longitude;
        },

        handleAddressSuggestionSelected(result) {
            const selectedAddress = result.address;
            this.form.address = selectedAddress.street1;
            this.form.address2 = selectedAddress.street2;
            this.form.city = selectedAddress.city;
            this.form.stateCode = selectedAddress.state;
            this.form.zipCode = selectedAddress.zipCode;
            this.form.county = selectedAddress.county;
            this.form.lat = result.lat;
            this.form.lng = result.lng;
            this.verifyAddress(true);
        },

        handleAddressSuggestionRejected() {
            this.$emit("verification-canceled");
        },

        handleRejectedSuggestionAndUseOriginal() {
            this.verifyAddress(false);
        },
    },
};
</script>

<style lang="scss" scoped>
.step-indicator-bar {
    height: 40px;
    background-color: rgba(255, 255, 255, 0.85);
}
</style>
