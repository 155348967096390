<template>
    <b-modal
        id="no-profile-save-modal"
        ref="noProfileSaveModal"
        hide-footer
        hide-header-close
        body-class="body-class"
        @hidden="onHiddenHandler"
    >
        <div class="d-flex flex-column align-items-center position-relative">
            <!-- eslint-disable vue/no-v-html -->
            <div class="title mb-1" v-html="$t('noProfileSaveModal.title')" />
            <!-- eslint-enable vue/no-v-html -->
            <div class="divider mt-2 mb-4"></div>

            <div class="button-block w-100">
                <div class="text-center mb-4">
                    <span class="description-text font-weight-bold">{{ $t("noProfileSaveModal.description") }}</span>
                </div>

                <primary-btn :loading="loadingPrimaryCta" block class="w-100 mb-2" @click.native="onSave">
                    {{ $t("noProfileSaveModal.mainCtaButton") }}
                </primary-btn>
                <secondary-btn :loading="loadingSecondaryCta" block class="w-100" @click.native="onCancel">
                    {{ $t("noProfileSaveModal.secondaryCtaButton") }}
                </secondary-btn>
            </div>

            <div class="logo-block position-absolute">
                <div class="logo-img"></div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import SecondaryBtn from "Components/Buttons/SecondaryBtn";
import CampaignProductEnum from "@/libs/CampaignProductEnum";
import { get } from "vuex-pathify";
import lodashGet from "lodash/get";

export default {
    name: "SaveModal",
    components: { SecondaryBtn, PrimaryBtn },
    data() {
        return {
            eventPostCancel: null,
            eventOnHidden: null,
            redirectPath: null,
            loadingPrimaryCta: false,
            loadingSecondaryCta: false,
        };
    },

    computed: {
        featureFlags: get("context/featureFlags"),
        productId: get("campaign/productId"),
        isECommerceProduct() {
            const result = this.productId === CampaignProductEnum.ECOMMERCE;
            return result;
        },
        isGhostAccountRegisterFeature() {
            const result = lodashGet(this.featureFlags, "GHOST_ACCOUNT_REGISTER_FEATURE", false) || false;
            return result;
        },
    },

    mounted() {
        this.initEventListeners();
    },

    methods: {
        stringNormalizer(value) {
            //returns '' if value is not of type string
            return typeof value === "string" ? value : "";
        },
        onHiddenHandler() {
            this.resetData();
            if (this.onHiddenHandler) {
                this.$root.$emit(this.eventOnHidden);
            }
        },
        closeModal() {
            this.loadingPrimaryCta = false;
            this.loadingSecondaryCta = false;
            this.$refs.noProfileSaveModal.hide();
        },
        showModal() {
            this.$refs.noProfileSaveModal.show();
        },
        onCancel() {
            this.loadingSecondaryCta = true;
            this.closeModal();
            this.trackCloseEvent();

            if (this.eventPostCancel) {
                setTimeout(
                    function setTimeOutHandler() {
                        this.$root.$emit(this.eventPostCancel);
                    }.bind(this)
                );
            }
            if (this.redirectPath) {
                document.location = this.redirectPath;
            }
        },
        trackCloseEvent() {
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "NoProfileSaveModal - No thanks. Not What I'm looking for - Click",
            });
            this.$gtm.trackEvent({
                category: "NoProfileSaveModal",
                action: "click",
                label: "No thanks. Not What I'm looking for",
                noninteraction: false,
            });
        },
        trackSaveEvent() {
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "NoProfileSaveModal - Save and get personalized payments - Click",
            });
            this.$gtm.trackEvent({
                category: "NoProfileSaveModal",
                action: "click",
                label: "NoProfileSaveModal",
                noninteraction: false,
            });
        },
        onSave() {
            this.loadingPrimaryCta = true;
            this.trackSaveEvent();
            this.handleGhostAccount();
        },
        handleGhostAccount() {
            if (this.isECommerceProduct && this.isGhostAccountRegisterFeature === true) {
                this.$root.$emit("ghost-account-modal");
            } else {
                document.location = "/account/register";
            }
        },
        showEventHandler(config = {}) {
            this.eventPostCancel = this.stringNormalizer(config.eventPostCancel);
            this.eventOnHidden = this.stringNormalizer(config.eventOnHidden);
            this.redirectPath = this.stringNormalizer(config.redirectPath);
            this.showModal();
        },
        initEventListeners() {
            this.$root.$on("show-no-profile-save-modal", this.showEventHandler);
        },
        resetData() {
            this.loadingPrimaryCta = false;
            this.loadingSecondaryCta = false;
        },
    },
};
</script>
<style lang="scss">
#no-profile-save-modal {
    .title {
        color: $gray-800;
        font-size: px2rem(28);
        letter-spacing: 0;
        line-height: px2rem(34);
        text-align: center;
    }
    .divider {
        height: 1px;
        width: 128px;
        @include themify($themes) {
            background-color: themed("primary-color");
        }
    }
    .button-block {
        margin-bottom: 55px;
    }
    .logo-block {
        bottom: 8px;
        right: 10px;
        height: 20px;
        width: 85px;

        .logo-img {
            @include themify($themes) {
                background-image: themed("vehicle-of-interest-modal-logo");
            }
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 100%;
        }

        @include media-breakpoint-up(lg) {
            bottom: 10px;
            right: 20px;
            height: 25px;
            width: 110px;
        }
    }
}
</style>
