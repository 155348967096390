<template>
    <b-row>
        <b-col cols="12">
            <div class="d-flex justify-content-end">
                <span class="font-weight-bold font-italic required-text">required*</span>
            </div>
        </b-col>
    </b-row>
</template>
<script>
export default {
    name: "RequiredIndicator",
};
</script>
<style lang="scss">
.required-text {
    color: $gray-800;
    font-size: px2rem(12);
}
</style>
