<template>
    <div>
        <modal
            ref="modal"
            v-bind="$attrs"
            size="md"
            :title="title"
            :subtitle="subtitle"
            centered
            hide-footer
            no-stacking
            :no-close-on-backdrop="true"
            @show="onShow"
        >
            <div v-if="showConfirmation" class="d-flex flex-column justify-content-center">
                <primary-btn block @click.native="$refs.modal.hide()"> Close Window</primary-btn>
            </div>
            <div v-else>
                <div class="form-body d-flex justify-content-center">
                    <personal-shopper
                        name="Claire R."
                        :employment-start-year="2016"
                        :photo="personalShopperPhoto"
                        spanish-speaking
                    />
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form id="contact-shopper-form" novalidate @submit.prevent="handleSubmit(submit)">
                        <div class="d-flex justify-content-center">
                            <div class="form-body d-flex flex-column align-items-center">
                                <div class="question-comments d-flex flex-column">
                                    <div class="comment">
                                        <div class="label">Question/Comments</div>
                                        <validation-provider
                                            v-slot="validationContext"
                                            rules="required"
                                            vid="question"
                                            name="Question"
                                        >
                                            <!-- prettier-ignore-attribute :state -->
                                            <b-form-textarea
                                                v-model="form.question"
                                                title="Contact Shopper Question"
                                                class="comment-box p-2 pr-4"
                                                required
                                                :state="getValidationState(validationContext)"
                                            >
                                            </b-form-textarea>
                                            <b-form-invalid-feedback class="mt-2">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                    <div class="pt-2">
                                        <div class="label">Reply to address</div>
                                        <validation-provider
                                            v-slot="validationContext"
                                            rules="required"
                                            vid="replyToEmail"
                                            name="Reply to address"
                                        >
                                            <!-- prettier-ignore-attribute :state -->
                                            <b-form-input
                                                v-model="form.replyToEmail"
                                                class="p-2"
                                                type="email"
                                                :state="getValidationState(validationContext)"
                                            />
                                            <b-form-invalid-feedback class="mt-2">
                                                {{ validationContext.errors[0] }}
                                            </b-form-invalid-feedback>
                                        </validation-provider>
                                    </div>
                                    <div class="pt-3 d-flex">
                                        <b-form-checkbox v-model="updateProfile" :disabled="updateProfileDisabled" />
                                        <div class="">
                                            <em> Update my profile with this email address </em>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <primary-btn block type="submit" class="mb-2" :loading="submittingForm">
                            Send Email
                        </primary-btn>
                    </b-form>
                </validation-observer>
            </div>
            <div v-if="isError" class="d-block invalid-feedback text-center">
                Sorry, there was an unexpected error, please try again shortly.
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from "Components/Modal";
import api from "@/api";
import veeValidateUtils from "@/libs/veeValidateUtils";
import formValidation from "@/mixins/formValidation";
import PersonalShopper from "./PersonalShopper";
import PersonalShopperImage from "../images/Claire.png";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";

export default {
    name: "ContactShopperModal",
    components: {
        PrimaryBtn,
        Modal,
        PersonalShopper,
    },
    mixins: [formValidation],
    props: {
        certificateId: {
            type: Number,
            default: null,
            required: false,
        },
    },
    data() {
        return {
            form: {
                question: null,
                replyToEmail: null,
                updateProfile: false,
            },
            submittingForm: false,
            personalShopperPhoto: PersonalShopperImage,
            showConfirmation: false,
            isError: false,
        };
    },
    computed: {
        updateProfile: {
            get() {
                return this.updateProfileDisabled ? false : this.form.updateProfile;
            },
            set(val) {
                this.form.updateProfile = val;
            },
        },
        updateProfileDisabled() {
            return !this.form.replyToEmail;
        },
        title() {
            return this.showConfirmation ? "Your email has been sent" : "How can we help you?";
        },
        subtitle() {
            return this.showConfirmation
                ? "Someone will be with you shortly"
                : "We are looking forward to speaking with you!";
        },
    },
    methods: {
        onShow() {
            this.reset();
        },
        reset() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        submit() {
            this.submittingForm = true;
            const certificateId = this.certificateId;
            const endPoint = certificateId
                ? `/lead/contact-shopper/email?certificateId=${certificateId}`
                : `/lead/contact-shopper/email`;
            api.post(endPoint, this.form)
                .then(() => {
                    this.submittingForm = false;
                    this.showConfirmation = true;
                })
                .catch((err) => {
                    this.submittingForm = false;
                    console.error("error submitting contact-shopper form", err);
                    if (err && err.response && err.response.status === 400) {
                        //validation error
                        this.$refs.observer.setErrors(veeValidateUtils.convertErrorToObserverErrors(err));
                    } else {
                        this.isError = true;
                        this.$refs.observer.reset();
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
#contact-shopper-form {
    .form-body {
        width: px2rem(330);

        .question-comments {
            width: 100%;
            margin-bottom: px2rem(24);

            .question {
                margin-bottom: px2rem(16);

                .label {
                    color: $gray-700;
                    font-size: px2rem(12);
                    line-height: px2rem(15);
                    white-space: nowrap;
                }
            }

            .comment {
                .label {
                    color: $gray-700;
                    font-size: px2rem(12);
                    line-height: px2rem(15);
                    margin-bottom: px2rem(4);
                }

                .comment-box {
                    box-sizing: border-box;
                    height: px2rem(95);
                    width: 100%;
                    border: 1px solid $gray-200;
                    color: $black;
                    font-size: px2rem(12);
                    line-height: px2rem(15);
                }
            }
        }
    }
}
</style>
