<template>
    <base-layout>
        <router-view />
    </base-layout>
</template>

<script>
import BaseLayout from "Components/BaseLayout";
export default {
    name: "Layout",
    components: {
        BaseLayout,
    },
};
</script>
