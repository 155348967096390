<template>
    <primary-btn class="get-started-btn mb-3" @click.native="getStartedAction">
        {{ text }}
    </primary-btn>
</template>

<script>
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
export default {
    name: "GetStartedBtn",
    components: { PrimaryBtn },
    props: {
        text: {
            type: String,
            required: false,
            default: "Get Started",
        },
    },
    data() {
        return {
            getStartedState: {
                loading: false,
            },
        };
    },
    methods: {
        getStartedAction() {
            this.$root.$emit("get-started-action");
        },
    },
};
</script>

<style lang="scss" scoped>
.get-started-btn {
    button {
        min-width: 145px;
    }
}
</style>
