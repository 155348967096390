<template>
    <PromotionsBannerContainer
        name="campaign-promotions-banner"
        class="campaign-promotions-banner"
        :show-banner="showCampaignPromotionsBanner"
    >
        <template v-if="isBMWProduct">
            <p v-if="isKuniNameChangeBanner">Come Visit Us at Our New Location!</p>
            <p v-else-if="isEVPromotionalBanner">Discover the Power of Electric!</p>
            <p v-else>Exclusive loyalty offers for returning BMW owners*</p>
        </template>
        <p v-else-if="isToyotaPromotionalBanner" class="px-1">NEW Toyota Inventory is Available!</p>
        <p v-else>Sign Up and Enjoy No Payments for {{ homepagePromotionsNoPaymentsDays }} Days*</p>
        <base-link class="modal-link d-inline-flex" href="#" @click.native="openModal">
            <span>{{ bannerCtaText }}</span>
        </base-link>
        <PromotionsModal />
        <EVPromotionsModal />
        <NewRidePromotionsModal />
        <NewTigardModal />
        <NewPromoModalBmw />
    </PromotionsBannerContainer>
</template>

<script>
import { get } from "vuex-pathify";
import BaseLink from "Components/Links/baseLink";
import PromotionsBannerContainer from "Components/PromotionsBannerContainer";
import PromotionsModal from "./PromotionsModal";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";
import EVPromotionsModal from "./EVPromotionsModal";
import NewRidePromotionsModal from "./NewRidePromotionsModal";
import lodashGet from "lodash/get";
import NewTigardModal from "Modules/Home/components/CampaignPromotionsBanner/NewTigardModal/index.vue";
import NewPromoModalBmw from "./NewPromoModalBmw";

export default {
    name: "CampaignPromotionsBanner",
    components: {
        NewPromoModalBmw,
        NewTigardModal,
        EVPromotionsModal,
        BaseLink,
        PromotionsBannerContainer,
        PromotionsModal,
        NewRidePromotionsModal,
    },
    computed: {
        homepagePromotionsNoPaymentsDays: get("campaign/noPaymentDays"),
        programId: get("campaign/programId"),

        currentRouteName() {
            return this.$route.name;
        },
        showCampaignPromotionsBanner() {
            if (this.isBMWProduct) {
                return (
                    (this.currentRouteName === "home" || this.currentRouteName === "listings") &&
                    !!this.homepagePromotionsNoPaymentsDays
                );
            }
            if (this.isToyotaPromotionalBanner) {
                return this.currentRouteName === "home" || this.currentRouteName === "listings";
            }
            return this.currentRouteName === "home" && !!this.homepagePromotionsNoPaymentsDays;
        },
        isBMWProduct() {
            return CampaignProgramEnum.BMW.indexOf(this.programId) !== -1;
        },
        featureFlags: get("context/featureFlags"),
        isEVPromotionalBanner() {
            const result = lodashGet(this.featureFlags, "EV_PROMOTIONAL_BANNER", false) || false;
            return result;
        },
        isToyotaPromotionalBanner() {
            const result = lodashGet(this.featureFlags, "TOYOTA_PROMOTIONAL_BANNER", false) || false;
            return result;
        },
        isKuniNameChangeBanner() {
            return lodashGet(this.featureFlags, "KUNI_NAME_CHANGE_BANNER", false) || false;
        },
        isNewBmwPromotionalBanner() {
            return lodashGet(this.featureFlags, "NEW_BMW_PROMOTIONAL_BANNER", false) || false;
        },
        bannerCtaText() {
            return this.isBMWProduct && this.isKuniNameChangeBanner ? "Click Here" : "Learn More";
        },
    },
    methods: {
        openModal() {
            if (this.isBMWProduct) {
                if (this.isKuniNameChangeBanner) {
                    this.$bvModal.show("new-tigard-modal");
                } else if (this.isEVPromotionalBanner) {
                    this.$bvModal.show("EV-promotions-modal");
                } else if (this.isNewBmwPromotionalBanner) {
                    this.$bvModal.show("new-promo-modal-bmw");
                } else {
                    this.$bvModal.show("campaign-promotions-modal");
                }
            } else {
                if (this.isToyotaPromotionalBanner) {
                    this.$bvModal.show("new-ride-promotions-modal");
                } else {
                    this.$bvModal.show("campaign-promotions-modal");
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.campaign-promotions-banner {
    text-align: center;
    @include themify($themes) {
        background-color: themed("promotions-banner-bg-color");
        color: themed("promotions-banner-text-color");
    }
}
.campaign-promotions-banner p {
    font-weight: 600;
    margin-bottom: 0;
    display: initial;
    margin-right: 4px;
    margin-left: 4px;
}
.modal-link {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    @include themify($themes) {
        color: themed("promotions-banner-text-color");
    }
    @include media-breakpoint-down(md) {
        display: block;
    }
}
</style>
