<template>
    <div class="hero-container d-flex flex-column" v-bind="$attrs">
        <b-container
            fluid
            class="hero position-relative overflow-hidden text-center p-0"
            :class="enableNewHero ? newHeroClass : ''"
        >
            <slot name="step-indicator-bar"></slot>
        </b-container>
        <b-container
            class="content-wrapper max-width-lg--800"
            :class="{ login: enableLoginOffset }"
            :style="overlayStyleOverrides"
        >
            <b-row id="hero-overlay" :class="overlayClass">
                <b-col class="px-sm-4">
                    <layout-title
                        :title-class="titleClass"
                        :display-separator="displaySeparator"
                        :subtitle-class="subtitleClass"
                    >
                        <template #subtitle>
                            <slot name="subtitle"></slot>
                        </template>

                        <template #title>
                            <slot name="title"></slot>
                        </template>

                        <template #bottom-subtitle>
                            <slot name="bottom-subtitle"></slot>
                        </template>
                    </layout-title>
                    <div>
                        <slot name="overlay-body"></slot>
                    </div>
                    <div class="mt-5 footer-text">
                        <slot name="footer"></slot>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <slot name="body"></slot>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import layoutTitle from "Components/LayoutTitle";

export default {
    name: "HeroLayout",

    components: {
        layoutTitle,
    },

    inheritAttrs: false,

    props: {
        enableLoginOffset: {
            type: Boolean,
            required: false,
        },
        displaySeparator: {
            required: false,
            type: Boolean,
            default: false,
        },
        newHeroClass: {
            type: String,
            required: false,
            default: "",
        },
        enableNewHero: {
            type: Boolean,
            required: false,
        },
        overlayClass: {
            type: String,
            required: false,
            default: "",
        },
        subtitleClass: {
            type: String,
            required: false,
            default: "",
        },
        titleClass: {
            type: String,
            required: false,
            default: "",
        },
        overlayStyleOverrides: {
            type: Object,
            required: false,
            default: function () {
                return {};
            },
        },
    },
};
</script>

<style lang="scss" scoped>
$offset-height: -77px;
$login-lg-offset-height: -170px;

.hero-container {
    width: 100%;

    .hero {
        min-height: 243px;
        width: 100%;
        background-size: cover;
        background-position: center;

        @include themify($themes) {
            background-image: themed("hero-sm-img");
        }

        @include media-breakpoint-up("md") {
            @include themify($themes) {
                background-image: themed("hero-md-img");
            }
            height: 421px;
        }

        @include media-breakpoint-up("lg") {
            @include themify($themes) {
                background-image: themed("hero-lg-img");
            }
        }
    }

    .hero.next-steps-hero {
        @include themify($themes) {
            background-image: themed("next-steps-hero-img");
        }
    }

    .hero.insure-hero {
        @include themify($themes) {
            background-image: themed("insure-hero-sm");
        }

        @include media-breakpoint-up("md") {
            @include themify($themes) {
                background-image: themed("insure-hero-lg");
            }
            height: 421px;
        }

        @include media-breakpoint-up("lg") {
            @include themify($themes) {
                background-image: themed("insure-hero-lg");
            }
        }
    }

    .hero.warranty-hero {
        @include themify($themes) {
            background-image: themed("warranty-hero-img");
        }

        @include media-breakpoint-up("md") {
            @include themify($themes) {
                background-image: themed("warranty-hero-img");
            }
            height: 421px;
        }

        @include media-breakpoint-up("lg") {
            @include themify($themes) {
                background-image: themed("warranty-hero-img");
            }
        }
    }

    .hero {
        &.howcarsaverworks-hero {
            @include themify($themes) {
                background-image: themed("how-carsaver-works-hero-sm");
            }

            @include media-breakpoint-up("md") {
                @include themify($themes) {
                    background-image: themed("how-carsaver-works-hero-lg");
                }
                height: 421px;
            }

            @include media-breakpoint-up("lg") {
                @include themify($themes) {
                    background-image: themed("how-carsaver-works-hero-lg");
                }
            }
        }
    }

    .content-wrapper {
        position: relative;

        /* Height adjust for offsetting */
        top: $offset-height;
        margin-bottom: $offset-height;

        @include media-breakpoint-up("lg") {
            &.login {
                /* Height adjust for offsetting */
                top: $login-lg-offset-height;
                margin-bottom: $login-lg-offset-height;
            }

            padding-left: 0;
            padding-right: 0;
        }
    }

    #hero-overlay {
        background-color: white;
        margin: 0;
        padding: px2rem(17) 0;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);

        @include media-breakpoint-up("md") {
            padding: px2rem(42) 0 px2rem(20) 0;
            margin-left: 0;
            margin-right: 0;
        }
    }
}

.footer-text {
    font-size: px2rem(8);
}
</style>
