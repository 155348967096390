<template>
    <div>
        <modal
            ref="modal"
            v-bind="$attrs"
            size="md"
            :title="title"
            :subtitle="subtitle"
            centered
            hide-footer
            no-stacking
            @show="onShow"
        >
            <div v-if="showConfirmation">
                <div class="d-flex flex-column justify-content-center">
                    <div class="confirmation-box d-flex justify-content-center align-items-center">
                        <div class="conf d-flex justify-content-center">
                            <confirmation-message class="pr-2" :scheduled-call-back="form" />
                            <primary-link aria-label="Edit">
                                <i class="material-icons edit-icon" aria-hidden="true" @click="edit"> edit </i>
                            </primary-link>
                        </div>
                    </div>
                    <primary-btn block @click.native="$refs.modal.hide()"> Close </primary-btn>
                </div>
            </div>
            <div v-else>
                <div class="form-body d-flex justify-content-center">
                    <personal-shopper
                        name="Claire R."
                        :employment-start-year="2016"
                        :photo="personalShopperPhoto"
                        spanish-speaking
                    />
                </div>
                <validation-observer ref="observer" v-slot="{ handleSubmit }">
                    <b-form id="call-me-now-form" novalidate @submit.prevent="handleSubmit(submit)">
                        <div class="d-flex justify-content-center">
                            <div class="form-body d-flex">
                                <div class="question-comments">
                                    <b-row class="mb-3">
                                        <b-col cols="6" class="pr-1">
                                            <b-form-group label="Day" label-for="call-back-date">
                                                <validation-provider
                                                    v-slot="validationContext"
                                                    rules="required"
                                                    vid="callBackDate"
                                                    name="Call Back Date"
                                                >
                                                    <!-- prettier-ignore-attribute :state -->
                                                    <b-form-datepicker
                                                        id="call-back-date"
                                                        v-model="form.callBackDate"
                                                        required
                                                        :state="getValidationState(validationContext)"
                                                        size="sm"
                                                        placeholder="Select Day"
                                                        :date-format-options="{
                                                            year: 'numeric',
                                                            month: 'numeric',
                                                            day: 'numeric',
                                                        }"
                                                        :disabled="!loaded"
                                                        :min="minDate"
                                                        @input="setAvailableTimeSlots"
                                                    >
                                                    </b-form-datepicker>
                                                    <b-form-invalid-feedback class="mt-2">
                                                        {{ validationContext.errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                        <b-col cols="6" class="pl-1">
                                            <b-form-group label-for="call-back-time-select">
                                                <template #label>
                                                    Time
                                                    <small class="font-weight-normal font-italic">
                                                        (Select day first)*
                                                    </small>
                                                </template>
                                                <validation-provider
                                                    v-slot="validationContext"
                                                    rules="required"
                                                    vid="callBackTime"
                                                    name="Call Back Time"
                                                >
                                                    <!-- prettier-ignore-attribute :state -->
                                                    <b-select
                                                        id="call-back-time-select"
                                                        v-model="form.callBackTime"
                                                        :disabled="dateIsNotSelected"
                                                        size="md"
                                                        required
                                                        :state="getValidationState(validationContext)"
                                                    >
                                                        <option :value="null" selected disabled hidden>Select</option>
                                                        <option
                                                            v-for="(timeSlot, index) in timeSlots"
                                                            :key="index"
                                                            :value="timeSlot.value"
                                                        >
                                                            {{ timeSlot.text }}
                                                        </option>
                                                    </b-select>
                                                    <b-form-invalid-feedback class="mt-2">
                                                        {{ validationContext.errors[0] }}
                                                    </b-form-invalid-feedback>
                                                </validation-provider>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <div class="comment">
                                        <b-form-group label="Question/Comments" label-for="call-me-later-question">
                                            <validation-provider
                                                v-slot="validationContext"
                                                vid="question"
                                                name="Question"
                                            >
                                                <b-form-textarea
                                                    id="call-me-later-question"
                                                    v-model="form.question"
                                                    title="Call Me Later Question"
                                                    :disabled="callBackNotSelected"
                                                    class="comment-box p-2"
                                                />
                                                <b-form-invalid-feedback :state="validationContext.valid" class="mt-2">
                                                    {{ validationContext.errors[0] }}
                                                </b-form-invalid-feedback>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>

                                    <div class="phone mt-2">
                                        <b-form-group label="You can call me at" label-for="phone-number">
                                            <validation-provider
                                                v-slot="validationContext"
                                                name="Phone Number"
                                                :rules="{
                                                    required: true,
                                                    regex: /^([0-9]{10})$/,
                                                }"
                                                mode="passive"
                                            >
                                                <!-- prettier-ignore-attribute :state -->
                                                <input-mask
                                                    id="phoneNumber"
                                                    ref="phoneNumber"
                                                    v-model="form.phoneNumber"
                                                    :mask="'(###) ###-####'"
                                                    maxlength="14"
                                                    class="form-control d-inline-block"
                                                    :state="validationContext.invalid && validationContext.validated ? false  : null"
                                                    placeholder="(XXX) XXX-XXXX"
                                                    autocomplete="tel-area-code"
                                                    @blur="validationContext.validate()"
                                                />
                                                <div v-if="validationContext.invalid" class="invalid-feedback d-block">
                                                    {{ validationContext.errors[0] }}
                                                </div>
                                            </validation-provider>
                                        </b-form-group>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <primary-btn
                            ref="loadingBtn"
                            :disabled="!loaded"
                            block
                            type="submit"
                            class="mb-2"
                            :loading="submitting"
                        >
                            Go
                        </primary-btn>
                    </b-form>
                </validation-observer>
            </div>
            <div v-if="isError" class="d-block invalid-feedback text-center">
                Sorry, there was an unexpected error, please close this window and try again shortly.
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from "Components/Modal";
import api from "@/api";
import veeValidateUtils from "@/libs/veeValidateUtils";
import formValidation from "@/mixins/formValidation";
import PersonalShopper from "./PersonalShopper";
import PersonalShopperImage from "../images/Claire.png";
import PrimaryLink from "Components/Links/primaryLink";
//import PhoneNumberInput from "Components/PhoneNumberInput";
import ConfirmationMessage from "./ConfirmationMessage";
import { TheMask as InputMask } from "vue-the-mask";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import lodashIsEmpty from "lodash/isEmpty";
import lodashIsNil from "lodash/isNil";
import lodashGet from "lodash/get";
import lodashFind from "lodash/find";
export default {
    name: "CallMeLaterModal",
    components: {
        PrimaryBtn,
        ConfirmationMessage,
        Modal,
        PersonalShopper,
        //PhoneNumberInput,
        InputMask,
        PrimaryLink,
    },
    mixins: [formValidation],
    props: {
        certificateId: {
            type: Number,
            default: null,
            required: false,
        },
        queueId: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            form: {
                callBackTime: null,
                callBackDate: null,
                userTimeZone: null,
                queueId: null,
                phoneNumber: null,
            },
            submitting: false,
            personalShopperPhoto: PersonalShopperImage,
            callCenterModel: {
                hoursOfOperations: [],
            },
            timeSlots: [],
            showConfirmation: false,
            isError: false,
        };
    },
    computed: {
        title() {
            return this.showConfirmation ? "Thank You" : "Please call me";
        },
        subtitle() {
            return this.showConfirmation
                ? "We look forward to speaking with you"
                : "We are looking forward to speaking with you! When would you like us to call?";
        },
        loaded() {
            return !lodashIsEmpty(this.callCenterModel.hoursOfOperations);
        },
        dateIsNotSelected() {
            return this.form.callBackDate === null;
        },
        callBackNotSelected() {
            return this.dateIsNotSelected || this.form.callBackTime === null;
        },
        minDate() {
            const todaysHoursOfOperations = this.getHoursOfOperationForDay(this.$moment().format("dddd"));

            if (!todaysHoursOfOperations) {
                return new Date();
            }

            const now = this.$moment();
            const end = this.$moment(todaysHoursOfOperations.close, ["hh:mm a", "hh:mma"]);
            //show the next day as first available if past call center hours
            return now.isAfter(end) ? now.add(1, "day").toDate() : new Date();
        },
    },
    methods: {
        onShow() {
            this.reset();
            this.form.userTimeZone = this.$moment.tz.guess(true);
            this.form.queueId = this.queueId;
            this.loadCallCenterModel().then(() => {
                if (!lodashIsNil(this.queueId)) {
                    this.loadExistingCallBack();
                }
            });
        },
        loadCallCenterModel() {
            const userTimeZone = this.form.userTimeZone;
            const certificateId = this.certificateId;
            return api
                .get("/lead/call-center", { certificateId, userTimeZone })
                .then((resp) => {
                    this.callCenterModel = lodashGet(resp, "data", this.callCenterModel);
                })
                .catch((err) => {
                    this.isError = true;
                    console.error("error loading contact me later modal", err);
                });
        },
        loadExistingCallBack() {
            const queueId = this.queueId;
            const userTimeZone = this.form.userTimeZone;
            return api
                .get("/lead/contact-shopper/call-me-back", {
                    queueId,
                    userTimeZone,
                })
                .then((resp) => {
                    const callQueue = lodashGet(resp, "data");

                    this.form.callBackDate = callQueue.callBackDate;
                    const callBackDate = this.$moment(this.form.callBackDate).toDate();
                    this.setAvailableTimeSlots(callBackDate);
                    this.form.callBackTime = callQueue.callBackTime;
                    this.form.phoneNumber = callQueue.phoneNumber;
                    this.form.question = callQueue.question;
                })
                .catch((err) => {
                    this.isError = true;
                    console.error("error loading contact me later modal", err);
                });
        },
        reset() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },
        submit() {
            this.submitting = true;
            const endPoint = this.certificateId
                ? `/lead/contact-shopper/call-me-back/later?certificateId=${this.certificateId}`
                : `/lead/contact-shopper/call-me-back/later`;
            api.post(endPoint, this.form)
                .then((resp) => {
                    this.submitting = false;
                    this.form.queueId = resp.data.queueId;
                    this.showConfirmation = true;
                })
                .catch((err) => {
                    this.submitting = false;
                    console.error("error submitting call-me-later form", err);

                    if (err && err.response && err.response.status === 400) {
                        this.$refs.observer.setErrors(veeValidateUtils.convertErrorToObserverErrors(err));
                    } else {
                        this.isError = true;
                    }
                });
        },
        setAvailableTimeSlots(date) {
            const hoursOfOperation = this.getHoursOfOperationForDay(this.$moment(date).format("dddd"));

            const timeSlots = [];
            const now = this.$moment();
            const start = this.$moment(hoursOfOperation.open, ["hh:mm a", "hh:mma"]);
            const end = this.$moment(hoursOfOperation.close, ["hh:mm a", "hh:mma"]);

            const isToday = this.$moment(now.toDate()).isSame(date, "day");
            while (start.isBefore(end) || start.isSame(end)) {
                if (!(isToday && start.isBefore(now))) {
                    const formattedTime = start.format("hh:mm a").toUpperCase();

                    timeSlots.push({
                        text: formattedTime,
                        value: formattedTime,
                    });
                }
                start.add(30, "minute");
            }

            this.timeSlots = timeSlots;
        },

        getHoursOfOperationForDay(day) {
            const dayOfWeek = day.toLowerCase();
            const hoursOfOperation = lodashFind(this.callCenterModel.hoursOfOperations, (hoursOfOperation) => {
                let dealersDay = hoursOfOperation.day.toLowerCase();
                return dealersDay === dayOfWeek;
            });

            return hoursOfOperation;
        },
        edit() {
            this.showConfirmation = false;
        },
        handlePhoneNumberInputComplete(evt, validationContext) {
            validationContext.validate();
            this.$refs.loadingBtn.$el.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
#call-me-now-form {
    .form-body {
        width: px2rem(330);

        .question-comments {
            width: 100%;
            margin-bottom: px2rem(24);

            .question {
                margin-bottom: px2rem(16);
                .label {
                    color: $gray-700;
                    font-size: px2rem(12);
                    line-height: px2rem(15);
                    white-space: nowrap;
                }
            }
            .comment {
                .label {
                    color: $gray-700;
                    font-size: px2rem(12);
                    line-height: px2rem(15);
                    margin-bottom: px2rem(4);
                }
                .comment-box {
                    box-sizing: border-box;
                    height: px2rem(95);
                    width: 100%;
                    border: 1px solid $gray-200;
                    color: $black;
                    font-size: px2rem(12);
                    line-height: px2rem(15);
                }
            }

            .b-form-datepicker {
                height: 38px !important;
                display: flex !important;
                align-items: center !important;
            }
        }

        .phone {
            width: 100%;
            margin-bottom: px2rem(24);

            .label {
                color: $gray-700;
                font-size: px2rem(12);
                line-height: px2rem(15);
                margin-bottom: px2rem(4);
            }
        }
    }
}
.confirmation-box {
    height: px2rem(93);
    width: 100%;
    background-color: $gray-200;
    margin-bottom: px2rem(16);
    font-size: px2rem(18);
    line-height: px2rem(23);
    color: #2e2e2e;

    @include media-breakpoint-up(sm) {
        font-size: px2rem(24);
        line-height: px2rem(32);
    }
    .conf {
        width: 100%;
    }
    .edit-icon {
        @include themify($themes) {
            color: themed("primary-color");
        }
    }
}
</style>
