import { make } from "vuex-pathify";
import api from "@/api";
import loader from "@/api/loader";
import lodashGet from "lodash/get";

const state = {
    userProspect: null,
    prospectContact: null,
    formServerErrors: null,
    prospectiveTrade: {
        data: null,
        loader: loader.defaultState(),
    },
    profileForm: null,
    mfaVerified: null,
    userProspectLoader: {
        loader: loader.defaultState(),
    },
    userProspectContactLoader: loader.defaultState(),
    userProspectEmailLoader: loader.defaultState(),
    prospectResendLoader: loader.defaultState(),
    duplicateEmail: false,
};

const actions = {
    ...make.actions(state),

    fetchUserProspect({ commit }, { prospectId, mfa }) {
        return api.get(`/user-prospect/${prospectId}?mfa=${mfa}`).then((response) => {
            const userProspect = lodashGet(response, "data");
            commit("SET_USER_PROSPECT", userProspect);
            return userProspect;
        });
    },
    fetchUserMember({ commit }, { email, mfa }) {
        return api.get(`/members/${email}?mfa=${mfa}`).then((response) => {
            const userMember = lodashGet(response, "data");
            commit("SET_USER_PROSPECT", userMember);
            return userMember;
        });
    },
    fetchProspectContact({ commit }, prospectId) {
        commit("SET_USER_PROSPECT_LOADER", loader.started());
        return api
            .get(`/prospects/${prospectId}/mfa/contactInfo`)
            .then((response) => {
                const prospectContact = lodashGet(response, "data");
                commit("SET_PROSPECT_CONTACT", prospectContact);
                commit("SET_USER_PROSPECT_LOADER", loader.successful());
                return prospectContact;
            })
            .catch((error) => {
                commit("SET_USER_PROSPECT_LOADER", loader.error(error));
            });
    },

    fetchMemberContact({ commit }, email) {
        commit("SET_USER_PROSPECT_LOADER", loader.started());
        return api
            .get(`/members/${email}/mfa/contactInfo`)
            .then((response) => {
                const memberContact = lodashGet(response, "data");
                commit("SET_PROSPECT_CONTACT", memberContact);
                commit("SET_USER_PROSPECT_LOADER", loader.successful());
                return memberContact;
            })
            .catch((error) => {
                commit("SET_USER_PROSPECT_LOADER", loader.error(error));
            });
    },

    verifyMemberContactMfa({ commit }, { email, mfa }) {
        return api.get(`/members/${email}/mfa/verify?mfa=${mfa}`).then(() => {
            const mfaVerified = mfa;
            commit("SET_MFA_VERIFIED", mfaVerified);
            return mfaVerified;
        });
    },

    verifyContactMfa({ commit }, { prospectId, mfa }) {
        return api.get(`/prospects/${prospectId}/mfa/verify?mfa=${mfa}`).then(() => {
            const mfaVerified = mfa;
            commit("SET_MFA_VERIFIED", mfaVerified);
            return mfaVerified;
        });
    },

    contactMethodText({ commit }, prospectId) {
        commit("SET_USER_PROSPECT_CONTACT_LOADER", loader.started());
        commit("SET_PROSPECT_RESEND_LOADER", loader.started());
        return api
            .post(`/prospects/${prospectId}/mfa?contact-method=SMS`)
            .then((response) => {
                commit("SET_USER_PROSPECT_CONTACT_LOADER", loader.successful());
                commit("SET_PROSPECT_RESEND_LOADER", loader.successful());
                return response;
            })
            .catch((error) => {
                commit("SET_USER_PROSPECT_CONTACT_LOADER", loader.error(error));
                commit("SET_PROSPECT_RESEND_LOADER", loader.error(error));
            });
    },

    contactMethodEmail({ commit }, prospectId) {
        commit("SET_USER_PROSPECT_EMAIL_LOADER", loader.started());
        commit("SET_PROSPECT_RESEND_LOADER", loader.started());
        return api
            .post(`/prospects/${prospectId}/mfa?contact-method=EMAIL`)
            .then((response) => {
                commit("SET_USER_PROSPECT_EMAIL_LOADER", loader.successful());
                commit("SET_PROSPECT_RESEND_LOADER", loader.successful());
                return response;
            })
            .catch((error) => {
                commit("SET_USER_PROSPECT_EMAIL_LOADER", loader.error(error));
                commit("SET_PROSPECT_RESEND_LOADER", loader.error(error));
            });
    },

    contactMethodEmailForMember({ commit }, email) {
        commit("SET_USER_PROSPECT_EMAIL_LOADER", loader.started());
        commit("SET_PROSPECT_RESEND_LOADER", loader.started());
        return api
            .post(`/members/${email}/mfa?contact-method=EMAIL`)
            .then((response) => {
                commit("SET_USER_PROSPECT_EMAIL_LOADER", loader.successful());
                commit("SET_PROSPECT_RESEND_LOADER", loader.successful());
                return response;
            })
            .catch((error) => {
                commit("SET_USER_PROSPECT_EMAIL_LOADER", loader.error(error));
                commit("SET_PROSPECT_RESEND_LOADER", loader.error(error));
            });
    },

    contactMethodTextForMember({ commit }, email) {
        commit("SET_USER_PROSPECT_CONTACT_LOADER", loader.started());
        commit("SET_PROSPECT_RESEND_LOADER", loader.started());
        return api
            .post(`/members/${email}/mfa?contact-method=SMS`)
            .then((response) => {
                commit("SET_USER_PROSPECT_CONTACT_LOADER", loader.successful());
                commit("SET_PROSPECT_RESEND_LOADER", loader.successful());
                return response;
            })
            .catch((error) => {
                commit("SET_USER_PROSPECT_CONTACT_LOADER", loader.error(error));
                commit("SET_PROSPECT_RESEND_LOADER", loader.error(error));
            });
    },

    fetchProspectTrade({ commit }, prospectId) {
        commit("SET_PROSPECTIVE_TRADE_LOADER", loader.started());

        return api
            .get("/trade", { prospectId })
            .then((response) => {
                const prospectiveTrade = lodashGet(response, "data");
                commit("SET_PROSPECTIVE_TRADE", prospectiveTrade);
                commit("SET_PROSPECTIVE_TRADE_LOADER", loader.successful());
                return prospectiveTrade;
            })
            .catch((error) => {
                commit("SET_PROSPECTIVE_TRADE_LOADER", loader.error(error));
            });
    },

    registerProfile({ commit }, profileForm) {
        return api.post("/profile/register", profileForm).then((response) => {
            return response;
        });
    },
    ssoCompleteProfile({ commit }, profileForm) {
        return api.post("/profile/register-sso", profileForm).then((response) => {
            return response;
        });
    },
    validateProfileStepOne({ commit }, profileForm) {
        return api.post("/profile/validate-step-one", profileForm).then((response) => {
            return response;
        });
    },
};

// const mutations = make.mutations(state);
const mutations = {
    ...make.mutations(state),
    SET_PROSPECTIVE_TRADE: (state, payload) => {
        state.prospectiveTrade.data = payload;
    },
    SET_PROSPECTIVE_TRADE_LOADER: (state, payload) => {
        state.prospectiveTrade.loader = payload;
    },
    SET_DUPLICATE_EMAIL: (state, payload) => {
        state.duplicateEmail = payload;
    },
};
const getters = {
    ...make.getters(state),
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
