import lodashIsNil from "lodash/isNil";
import Vue from "vue";
import numeral from "numeral";

Vue.filter("numeral", function (val, format) {
    if (lodashIsNil(val)) {
        return val;
    }

    return numeral(val).format(format);
});
