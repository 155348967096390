import isNil from "lodash/isNil";

export default {
    beforeCreate() {
        const self = this;

        this.$page = {
            isEnabled(pageId) {
                if (isNil(pageId)) {
                    return true;
                }

                return self.$store.getters["context/isPageEnabled"](pageId);
            },
        };
    },
};
