import lodashIsNil from "lodash/isNil";
import lodashGet from "lodash/get";
import axios from "axios";

const basePath = "/api";

function getMeta(metaName) {
    const metas = document.getElementsByTagName("meta");

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute("name") === metaName) {
            return metas[i].getAttribute("content");
        }
    }

    return null;
}

export default new (class ApiClient {
    constructor() {
        this.httpClient = axios.create();

        this.httpClient.interceptors.request.use((req) => {
            // `req` is the Axios request config, so you can modify
            // the `headers`.
            const csrfHeader = getMeta("_csrf_header");
            if (!lodashIsNil(csrfHeader)) {
                req.headers[csrfHeader] = getMeta("_csrf");
            }
            return req;
        });

        this.httpClient.interceptors.response.use(
            (response) => response,
            (error) => {
                const bugsnag = lodashGet(window, "bugsnagClient");
                if (bugsnag !== undefined && lodashGet(error, "response.status") !== 400) {
                    const response = error.response;
                    bugsnag.notify(error, {
                        metaData: {
                            responseConfig: response.config,
                            request: response.request,
                        },
                    });
                }

                return Promise.reject(error);
            }
        );
    }

    get(path, params) {
        return this.httpClient.get(basePath + path, { params });
    }

    put(path, data, config) {
        return this.httpClient.put(basePath + path, data, config);
    }

    post(path, data, config) {
        return this.httpClient.post(basePath + path, data, config);
    }

    patch(path, data) {
        return this.httpClient.patch(basePath + path, data);
    }

    delete(path, data) {
        return this.httpClient.delete(basePath + path, data);
    }
})();
