<template>
    <modal
        id="address-selection-modal"
        ref="addressSelectionModal"
        v-bind="$attrs"
        size="md"
        :title="title"
        :subtitle="subtitle"
        centered
        hide-footer
        body-class="pt-3"
        no-stacking
        @hide="onHide"
    >
        <div class="justify-content-md-between w-100 flex-md-row">
            <h3 id="entered-address-title">Entered Address:</h3>
            <div aria-labelledby="entered-address-title" class="full-address pl-2 mt-1 mb-4">
                <div class="street">
                    {{ enteredAddress.address }}
                    {{ enteredAddress.address2 }}
                </div>
                <div class="city-state-zip">
                    {{ enteredAddress.city }},
                    {{ enteredAddress.stateCode }}
                    {{ enteredAddress.zipCode }}
                </div>
            </div>
        </div>

        <h3 v-if="hasMatchedAddresses" id="suggested-address-title">Suggestions:</h3>
        <div
            v-if="hasMatchedAddresses"
            class="address-selector px-2 pb-2 mt-2 overflow-auto"
            style="max-height: 13rem !important"
        >
            <b-list-group
                v-for="(result, index) in matchedAddresses"
                :id="`address-${index}`"
                :key="index"
                class="address-content p-2 mt-2 d-flex"
            >
                <!-- TODO: this list might need to be switch for a proper HTML list element (li) -->
                <div class="full-address">
                    <div class="street">
                        {{ result.address.street1 }}
                        {{ result.address.street2 }}
                    </div>
                    <div class="city-state-zip">
                        {{ result.address.city }},
                        {{ result.address.state }}
                        {{ result.address.zipCode }}
                    </div>
                    <div v-if="result.businessAddress === true" class="business">
                        <span class="material-icons icon"> error_outline </span>
                        <small class="font-italic align-top c-add">commercial address</small>
                    </div>
                </div>
                <div :class="$mq === 'xs' ? 'w-100 pt-2' : ''" style="place-self: center">
                    <button
                        type="button"
                        class="btn btn-primary btn-sm block w-100"
                        @click="selectAddressSuggestion(result)"
                    >
                        Select
                    </button>
                </div>
            </b-list-group>
        </div>

        <div v-if="hasMatchedAddresses" class="d-flex justify-content-center my-4">— or —</div>
        <div class="d-flex justify-content-center">
            <primary-btn class="w-100 mb-1" @click.native="rejectAddressSuggestion"> Edit Address </primary-btn>
        </div>
        <div class="d-flex justify-content-center">
            <primary-btn class="w-100" @click.native="rejectAddressAndUseOriginal">
                Use the address as entered
            </primary-btn>
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import lodashIsNil from "lodash/isNil";
import lodashFind from "lodash/find";

export default {
    name: "AddressSelectionModal",
    components: { PrimaryBtn, Modal },
    props: {
        enteredAddress: {
            type: Object,
            required: true,
        },
        matchedAddresses: {
            type: Array,
            required: true,
        },
    },
    computed: {
        hasBusinessAddress() {
            // if one result is a business address, we show messaging for business address
            return !lodashIsNil(lodashFind(this.matchedAddresses, { businessAddress: true }));
        },
        title() {
            if (this.hasBusinessAddress) {
                return "Confirm Address";
            } else {
                return "We couldn't find the address you entered";
            }
        },
        subtitle() {
            if (!this.hasMatchedAddresses) {
                return "Please edit or continue with the address as entered";
            } else if (this.hasBusinessAddress) {
                return "The address you entered is registered as a commercial location.";
            } else {
                return "Did you mean?";
            }
        },
        hasMatchedAddresses() {
            return this.matchedAddresses && this.matchedAddresses.length > 0;
        },
    },
    methods: {
        hideModal() {
            this.$refs["addressSelectionModal"].hide();
        },
        rejectAddressSuggestion() {
            this.$emit("address-suggestion-rejected");
            this.hideModal();
        },
        selectAddressSuggestion(result) {
            this.$emit("address-suggestion-selected", result);
            this.hideModal();
        },
        rejectAddressAndUseOriginal() {
            this.$emit("address-suggestion-rejected-use-original");
            this.hideModal();
        },
        onHide(evt) {
            //if user initiated action hid the modal consider this a rejection
            if (evt.trigger === "backdrop" || evt.trigger === "closed") {
                this.$emit("address-suggestion-rejected");
            }
        },
    },
};
</script>

<style lang="scss">
#address-selection-modal {
    .address-content {
        box-sizing: border-box;
        background-color: $white;
        box-shadow: 0 0 px2rem(2) 0 $gray-300;
        border: px2rem(1) solid $gray-300;
        color: inherit;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .address-selector {
        box-sizing: border-box;
        background-color: $white;
        box-shadow: 0 0 px2rem(2) 0 $gray-300;
        border: px2rem(1) solid $gray-300;
        color: inherit;
        height: 100%;

        .business {
            width: 102px;
        }

        .icon {
            font-size: px2rem(12);
            @include themify($themes) {
                color: themed("primary-color");
            }
        }

        .c-add {
            @include themify($themes) {
                color: themed("primary-color");
            }
        }
    }
    .modal-title {
        font-size: px2rem(24);
        line-height: px2rem(29);
        font-weight: normal;
    }
    .modal-subtitle {
        font-size: px2rem(14);
        line-height: px2rem(17);
    }

    .full-address {
        line-height: 1.3;
        place-self: center;
    }
}
</style>
