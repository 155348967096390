<template>
    <div v-if="show" class="header-banner">
        <b-container class="position-relative">
            <slot></slot>
            <button
                v-if="refererUrl && !isOverlayEnabled"
                key="referrer-btn"
                type="button"
                title="Close Banner"
                class="close-btn"
                @click="showSaveWorkModal"
            >
                <i class="material-icons icon" aria-hidden="true">close</i>
            </button>
            <button v-else key="non-referrer-btn" type="button" title="Close Banner" class="close-btn" @click="close">
                <i class="material-icons icon" aria-hidden="true">close</i>
            </button>
        </b-container>
    </div>
</template>

<script>
import bannersViewedStorage from "@/libs/bannersViewedStorage";
import { get } from "vuex-pathify";

export default {
    name: "NavBannerContainer",
    props: {
        name: {
            //example dealer-link-back-banner, covid19-banner, ...
            type: String,
            required: true,
        },
        disable: {
            type: Boolean,
            default: false,
            required: false,
        },
        hideCloseButton: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            viewed: null,
            viewedBanners: bannersViewedStorage.get(),
        };
    },
    computed: {
        refererUrl: get("context/refererUrl"),
        isOverlayEnabled: get("context/overlayEnabled"),
        userIsLoggedIn: get("context/isLoggedIn"),
        show() {
            return !this.disable && !this.viewed;
        },
    },

    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.viewed = this.wasBannerViewed();
        },

        close() {
            this.setAsViewed();
        },
        setAsViewed() {
            this.viewed = bannersViewedStorage.add(this.name);
        },
        wasBannerViewed() {
            if (Array.isArray(this.viewedBanners)) {
                return this.viewedBanners.indexOf(this.name) !== -1;
            } else if (typeof this.viewedBanners === "string") {
                return this.viewedBanners === this.name;
            }

            return false;
        },
        showSaveWorkModal() {
            this.$carsaverEventTracker("backToDealerLink");
            if (!this.userIsLoggedIn && this.isModalEnabled) {
                this.$emit("saveWork", this.refererUrl);
            } else {
                document.location = this.refererUrl;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.header-banner {
    color: $gray-500;
    background-color: $gray-200;
    font-size: px2rem(12);
    line-height: 1.3;
    padding-top: 12px;
    padding-bottom: 12px;
    z-index: 1000;

    .close-btn {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        background: 0 0;
        border: none;
        margin-right: 16px;
        color: #444;

        .icon {
            font-size: px2rem(16);
        }
    }
}
</style>
