<template>
    <div v-if="isWidgetIdAndIsChatEnabled">
        <a
            class="reputation-widget"
            target="_blank"
            :href="widgetUrl"
            :data-tk="chatTenantKey"
            :data-widget-id="dealerWidgetId"
            env=""
            region="us"
            aria-label="Dealership Customer Service Chat"
        >
            Reputation Inbox2
        </a>
        <script type="application/javascript">
            !(function (d, s, c) {
                var js,
                    fjs = d.getElementsByTagName(s)[0];
                js = d.createElement(s);
                js.className = c;
                js.src = "https://widgets.reputation.com/src/client/widgets/widgets.js?v=1592944030435";
                fjs.parentNode.insertBefore(js, fjs);
            })(document, "script", "reputation-wjs");
        </script>
    </div>
</template>

<script>
import { get } from "vuex-pathify";
import lodashGet from "lodash/get";

export default {
    name: "ReputationChat",
    props: {
        dealerChatConfig: {
            type: Object,
            required: true,
            default: null,
        },
    },
    computed: {
        dealerId: get("context/dealerId"),
        widgetUrl() {
            return `https://widgets.reputation.com/widgets/${this.dealerWidgetId}/run?tk=d83761b1b6d`;
        },
        dealerWidgetId() {
            return lodashGet(this.dealerChatConfig, "widgetId", null) || null;
        },
        isChatEnabled() {
            return lodashGet(this.dealerChatConfig, "chatEnabled", false) || false;
        },
        chatTenantKey() {
            return lodashGet(this.dealerChatConfig, "tenantKey", null) || null;
        },
        isReputationChat() {
            return lodashGet(this.dealerChatConfig, "isReputationChat", false);
        },
        isWidgetIdAndIsChatEnabled() {
            let isEnabled = false;

            if (this.dealerWidgetId && this.isChatEnabled && this.isReputationChat) {
                isEnabled = true;
            }

            return isEnabled;
        },
    },
};
</script>
<style lang="scss">
#inbox-form-body {
    z-index: 1001;
    position: fixed !important;
    bottom: 195px !important;
}

#r4e-inbox-form-launcher {
    position: fixed !important;
    bottom: 135px !important;
    right: 25px !important;
}

.r4e-widget.inbox .inbox-icon {
    position: fixed !important;
    bottom: 135px !important;
    right: 25px !important;
}

@include media-breakpoint-up(sm) {
    #inbox-form-body {
        z-index: 21;
    }
    #r4e-inbox-form-launcher {
        bottom: 125px !important;
    }

    .r4e-widget.inbox .inbox-icon {
        bottom: 125px !important;
    }
}
</style>
