<template>
    <div class="mt-2 pt-2 pt-sm-0">
        <div v-if="hasBreadcrumbs" class="page-breadcrumb">
            <slot name="page-breadcrumb-content" />
        </div>

        <slot name="page-title">
            <h1
                v-if="hasTitleOrSlot"
                class="page-title text-center page-title-color mb-1"
                :class="'content mx-auto content-' + size"
            >
                <slot name="page-title-content">{{ title }}</slot>
            </h1>
        </slot>

        <slot name="page-subtitle">
            <div class="page-subtitle-container mx-auto">
                <div v-if="hasSubtitleOrSlot" class="page-subtitle text-center mx-4 mx-sm-0">
                    <slot name="page-subtitle-content">{{ subtitle }}</slot>
                </div>
            </div>
        </slot>

        <slot name="page-subtitle2">
            <div class="page-subtitle-container mx-auto">
                <div class="page-subtitle text-center mx-4 mx-sm-0">
                    {{ subtitle2 }}
                </div>
            </div>
        </slot>

        <hr v-if="!hideSeparator" class="separator mb-sm-5" />

        <div v-if="hasContentHeaderSlot" :class="'content-' + size" class="content mx-auto py-3 mb-sm-3 content-box">
            <slot name="content-header" />
        </div>

        <div v-if="!hideContent" :class="'content mx-auto content-box content-' + size">
            <slot />
        </div>
    </div>
</template>

<script>
import lodashIsNil from "lodash/isNil";
export default {
    props: {
        hideSeparator: {
            required: false,
            type: Boolean,
            default: false,
        },
        size: {
            required: false,
            type: String,
            default: "md",
        },
        subtitle: {
            required: false,
            type: String,
            default: null,
        },
        subtitle2: {
            required: false,
            type: String,
            default: null,
        },
        title: {
            required: false,
            type: String,
            default: null,
        },
        hideContent: {
            required: false,
            type: Boolean,
            default: false,
        },
    },
    computed: {
        hasContentHeaderSlot() {
            return this.$slots["content-header"];
        },
        hasSubtitleOrSlot() {
            return !lodashIsNil(this.subtitle) || this.$slots["page-subtitle-content"];
        },
        hasTitleOrSlot() {
            return !lodashIsNil(this.title) || this.$slots["page-title-content"];
        },
        hasBreadcrumbs() {
            return this.$slots["page-breadcrumb-content"];
        },
        hasSubtitle2Slot() {
            return this.$slots["content-subtitle2-content"];
        },
    },
};
</script>

<style lang="scss" scoped>
.content {
    padding-left: 12px;
    padding-right: 12px;
    @include media-breakpoint-up("sm") {
        padding-left: 40px;
        padding-right: 40px;
    }

    &.content-auto {
        max-width: 100%;
    }

    &.content-xl {
        max-width: 900px;
    }

    &.content-lg {
        max-width: 800px;
    }

    &.content-md {
        max-width: 500px;
    }
}

.content-box {
    box-shadow: 0px 0px 8px rgba(68, 68, 68, 0.2);
    padding-top: 24px;
    padding-bottom: 24px;
}

h1 {
    font-size: px2rem(28);
    line-height: px2rem(34);
}

.separator {
    width: 128px;
    @include themify($themes) {
        border: solid 1px themed("primary-color");
    }
}

.page-subtitle {
    color: $gray-600;
    font-size: px2rem(16);
    line-height: 1.3;
}

.page-subtitle-container {
    max-width: 500px;
}

.page-title-color {
    @include themify($themes) {
        color: themed("title-color");
    }
}

.page-breadcrumb {
    .breadcrumb {
        background-color: transparent;

        a {
            @include themify($themes) {
                color: themed("primary-color");
            }
        }
    }
}
</style>
