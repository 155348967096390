<template>
    <b-form-input
        id="addressInput"
        ref="addressInput"
        :placeholder="placeholder"
        :class="cssClass"
        :value="value"
        :state="validationState"
        autocomplete="address-line1"
        autofill="off"
        :required="required"
        @input="updateValue($event)"
    />
</template>

<script>
import lodashGet from "lodash/get";

export default {
    name: "AddressLookup",

    props: {
        placeholder: {
            type: String,
            required: false,
            default: "Address",
        },
        value: {
            type: String,
            required: false,
            default: "",
        },
        cssClass: {
            type: String,
            required: false,
            default: "",
        },
        validationState: {
            type: Boolean,
            required: false,
            default: true,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    data() {
        return {
            autocomplete: null,
        };
    },

    mounted() {
        this.loadGoogleMaps(this.initAutocomplete);
    },

    methods: {
        loadGoogleMaps(cb) {
            this.$loadScript(
                "https://maps.googleapis.com/maps/api/js?key=AIzaSyAmeMzc4Bx3ogH6OT4oNZ_hy2ufznNTcwY&libraries=places"
            )
                .then(() => {
                    cb();
                })
                .catch((err) => {
                    console.error("Unable to load Google Maps Api:", err);
                });
        },
        initAutocomplete() {
            if (this.$refs.addressInput) {
                this.autocomplete = new google.maps.places.Autocomplete(this.$refs.addressInput.$el, {
                    types: ["geocode"],
                });
                this.autocomplete.setFields(["address_component", "formatted_address", "geometry"]);
                this.autocomplete.addListener("place_changed", this.handlePlaceChange);
            }
        },
        handlePlaceChange() {
            const place = this.autocomplete.getPlace();
            const location = this.toLocation(place);
            this.updateValue(location.streetAddress);
            this.$emit("place-change", location);
        },

        toLocation(place) {
            const formattedPlace = this.reFormat(place);
            const location = lodashGet(place.geometry, "location", {});
            const streetAddress = `${lodashGet(formattedPlace, "street_number.longName", "")} ${lodashGet(
                formattedPlace,
                "route.longName",
                ""
            )}`;

            return {
                streetAddress: streetAddress,
                city: lodashGet(formattedPlace, "locality.longName", ""),
                state: lodashGet(formattedPlace, "administrative_area_level_1.longName", ""),
                stateAbbreviation: lodashGet(formattedPlace, "administrative_area_level_1.shortName", ""),
                zipCode: lodashGet(formattedPlace, "postal_code.shortName", ""),
                county: lodashGet(formattedPlace, "administrative_area_level_2.longName", ""),
                latitude: location.lat(),
                longitude: location.lng(),
            };
        },

        updateValue: function (value) {
            this.$emit("input", value);
        },

        /**
         * Creates key val pairs from Google Place's
         * address components.
         *
         * @param place
         * @returns {{}}
         */
        reFormat(place) {
            let formattedPlace = {};

            for (let i = 0; i < place.address_components.length; i++) {
                let addressComponent = place.address_components[i];

                formattedPlace[addressComponent.types[0]] = {
                    longName: addressComponent.long_name,
                    shortName: addressComponent.short_name,
                };
            }

            return formattedPlace;
        },
    },
};
</script>

<style lang="scss" scoped>
.address-display {
    width: 100%;
    border: 1px solid #28a745;
    //$gray-300;
}
.edit-address-button {
    font-size: px2rem(12) !important;
    @include themify($themes) {
        color: themed("primary-color");
    }
}
</style>
