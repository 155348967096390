<!-- Works with the Bootstrap View popover component -->
<template>
    <div class="tooltip-container align-bottom d-inline">
        <b-button ref="tool-tip" aria-label="Tooltip" class="tooltip-btn" @click="iconClicked($event)">
            <i
                ref="info-icon"
                :class="large ? 'tooltip-icon-large' : ''"
                class="material-icons tooltip-icon"
                :aria-label="ariaLabel"
            >
                info_outline
            </i>
        </b-button>
        <b-popover
            :target="() => $refs['tool-tip']"
            triggers="hover focus"
            v-bind="$attrs"
            container="tooltip-container"
            :show.sync="showing"
            @show="nowShowing(true)"
            @hide="nowShowing(false)"
        >
            <template v-if="title" #title>
                <b-button class="close" aria-label="Close" @click="nowShowing(false)">
                    <span class="d-inline-block" aria-hidden="true">&times;</span>
                </b-button>
                {{ title }}
            </template>
            <slot />
        </b-popover>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,
    props: {
        // eslint-disable-next-line
        title: {
            type: String,
            required: false,
        },
        large: {
            type: Boolean,
            required: false,
        },
        ariaLabel: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            monitoredEvents: ["touch", "click"],
            showing: false,
        };
    },
    mounted() {
        this.initClickOutsideListener();
    },
    methods: {
        nowShowing(state) {
            this.showing = state;
            if (state === true) {
                this.$carsaverEventTracker("garageViewDisclaimer");
            }
        },

        hideAllTooltips() {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$root.$emit("bv::hide::popover");
        },
        initClickOutsideListener() {
            const that = this;

            // DOM Events
            this.monitoredEvents.forEach(function (name) {
                document.addEventListener(name, (evt) => that.outsideClickListenerHandler(evt), true);
            });
        },
        outsideClickListenerHandler(event) {
            const selector = "popover-body";
            const target = event.target;

            if (!target.classList.contains(selector)) {
                if (this.showing) {
                    this.hideAllTooltips();
                    event.stopPropagation();
                }
            }
        },
        iconClicked(event) {
            this.nowShowing(!this.showing);
            event.stopPropagation();
        },
    },
};
</script>

<style lang="scss" scoped>
.tooltip-btn {
    background: transparent;
    border-color: transparent;
    padding: 0;
    box-shadow: none;
}

.tooltip-icon {
    color: $gray-600;

    &:hover {
        cursor: pointer;
    }

    font-size: px2rem(12);
}

.tooltip-icon-large {
    font-size: px2rem(16);
}
</style>
