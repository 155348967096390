<template>
    <div class="pr-2">{{ confirmationMessage }}</div>
</template>

<script>
import lodashStartWith from "lodash/startsWith";
import lodashTrimStart from "lodash/trim";
export default {
    name: "ConfirmationMessage",
    props: {
        scheduledCallBack: {
            type: Object,
            required: true,
        },
    },
    computed: {
        confirmationMessage() {
            let confirmationMessage = null;

            if (this.scheduledCallBack.callBackDate) {
                const dayOfWeek = this.$moment(this.scheduledCallBack.callBackDate).format("dddd");
                const formattedDate = this.$moment(this.scheduledCallBack.callBackDate).format("MM/DD/YY");

                //03:00 PM --> 3:00 PM
                const callBackTime = lodashStartWith(this.scheduledCallBack.callBackTime, "0")
                    ? lodashTrimStart(this.scheduledCallBack.callBackTime, "0")
                    : this.scheduledCallBack.callBackTime;

                const callBackIsToday =
                    this.$moment().format("MM/DD/YY") ===
                    this.$moment(this.scheduledCallBack.callBackDate).format("MM/DD/YY");

                confirmationMessage = callBackIsToday
                    ? "Today at " + callBackTime
                    : dayOfWeek + ", " + formattedDate + " at " + callBackTime;
            }

            return confirmationMessage;
        },
    },
};
</script>

<style lang="scss" scoped></style>
