<template>
    <div class="vehicle-banner d-flex justify-content-center align-items-center">
        <b-container>
            <b-row>
                <b-col cols="12">
                    <b-spinner v-if="loading" />

                    <div v-else class="d-flex justify-content-between align-items-center w-100">
                        <div class="d-flex align-items-center">
                            <div class="vehicle-image mr-2 p-1">
                                <vehicle-image
                                    :src="imageUrl"
                                    :stock-src="stockImageUrl"
                                    :body-style="bodyStyle"
                                    :alt="vehicleName"
                                />
                            </div>
                            <div class="d-flex flex-column">
                                <div class="message">{{ $t("vehicleBanner.valueStatement") }}</div>
                                <div class="ymm" role="heading" aria-level="2">{{ vehicleName }}</div>
                                <div v-if="vehicleTrim" class="trim">{{ vehicleTrim }}</div>
                                <div v-if="exteriorColor" class="ex-color">Exterior: {{ exteriorColor }}</div>
                                <div v-if="interiorColor" class="in-color">Interior: {{ interiorColor }}</div>
                            </div>
                        </div>

                        <div class="account-btn d-none d-sm-flex flex-column">
                            <primary-btn
                                class="mb-2"
                                :loading="loadingPrimaryCta"
                                @click.native="estimatePaymentsClick"
                            >
                                {{ $t("vehicleBanner.mainCtaButton") }}
                            </primary-btn>

                            <secondary-btn :loading="loadingSecondaryCta" @click.native="createAccountClick">
                                {{ $t("vehicleBanner.secondaryCtaButton") }}
                            </secondary-btn>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import api from "@/api";
import VehicleImage from "Components/VehicleImage";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import SecondaryBtn from "Components/Buttons/SecondaryBtn";
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";

export default {
    name: "VehicleBanner",
    components: { SecondaryBtn, PrimaryBtn, VehicleImage },
    props: {
        vehicleId: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            loading: true,
            vehicle: null,
            loadingPrimaryCta: false,
            loadingSecondaryCta: false,
        };
    },
    computed: {
        imageUrl() {
            return lodashGet(this.vehicle, "imageUrl", null);
        },
        stockImageUrl() {
            return lodashGet(this.vehicle, "stockImageUrl", null);
        },
        bodyStyle() {
            return lodashGet(this.vehicle, "bodyStyle", "sedan");
        },
        vehicleName() {
            let year = lodashGet(this.vehicle, "year", "");
            let make = lodashGet(this.vehicle, "make", "");
            let model = lodashGet(this.vehicle, "model", "");

            return year + " " + make + " " + model;
        },
        vehicleTrim() {
            return lodashGet(this.vehicle, "trim", null) || null;
        },
        exteriorColor() {
            return lodashGet(this.vehicle, "exteriorColor", null) || null;
        },
        interiorColor() {
            return lodashGet(this.vehicle, "interiorColor", null) || null;
        },
    },
    mounted() {
        this.fetchVehicle();
    },
    methods: {
        createAccountClick() {
            this.loadingSecondaryCta = true;
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "VehicleBanner - Create Account - Click",
            });
            this.$gtm.trackEvent({
                category: "VehicleBanner",
                action: "click",
                label: "Create Your Nissan Account",
                noninteraction: false,
            });
            this.$carsaverEventTracker("trackCreateAccountClickAction");

            document.location = "/account/register/ghost";
        },
        estimatePaymentsClick() {
            this.loadingPrimaryCta = true;
            this.$gtm.trackEvent({
                event: "mixpanel",
                name: "VehicleBanner - Estimate Payments - Click",
            });
            this.$gtm.trackEvent({
                category: "VehicleBanner",
                action: "click",
                label: "Estimate Payments",
                noninteraction: false,
            });
            this.$carsaverEventTracker("trackEstimatePaymentAction");

            document.location = `/no-profile/vehicles/${this.vehicleId}`;
        },
        fetchVehicle() {
            if (!lodashIsNil(this.vehicleId)) {
                api.get(`/vehicles/${this.vehicleId}/banner`)
                    .then((response) => {
                        this.vehicle = response.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.loading = false;
                    });
            }
        },
    },
};
</script>
<style lang="scss">
.vehicle-banner {
    height: 100px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    position: relative;

    .vehicle-image {
        width: 110px;

        .deal-image {
            i {
                font-size: px2rem(30);
            }
        }
    }

    .message {
        color: $gray-800;
        font-size: px2rem(12);
        font-style: oblique;
        letter-spacing: 0;
        line-height: 14px;
    }

    .ymm {
        color: $gray-800;
        font-size: px2rem(14);
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
    }

    .trim {
        color: $gray-800;
        font-size: px2rem(18);
        line-height: 22px;
    }

    .ex-color,
    .in-color {
        color: $gray-600;
        font-size: px2rem(12);
        line-height: 14px;
    }

    @include media-breakpoint-up(md) {
        height: 125px;

        .vehicle-image {
            width: 135px;

            .deal-image {
                i {
                    font-size: px2rem(40);
                }
            }
        }

        .message {
            font-size: px2rem(14);
            line-height: 17px;
        }
        .ymm {
            font-size: px2rem(18);
            line-height: 26px;
        }

        .account-btn {
            button {
                width: 260px;
            }
        }
    }
}
</style>
