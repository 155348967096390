import VueGtm from "@carsaver/vue-gtm";
import { clickToTrackData } from "./dataLayerManager";
import lodashGet from "lodash/get";

// default options
const defaultOptions = {
    adobeTagsEnabled: false,
    googleTagsProps: {},
};

export default {
    // called by Vue.use(carsaverTagManager)
    install(Vue, options) {
        // merge default options with arg options
        const userOptions = { ...defaultOptions, ...options };

        // which tag systems are enabled/disabled
        const isGoogleEnabled = userOptions.googleTagsProps.enabled || false;
        const isAdobeEnabled = userOptions.adobeTagsEnabled || false;

        // initialize Google GTM if enabled
        Vue.use(VueGtm, { ...userOptions.googleTagsProps });

        // eslint-disable-next-line
        const showProps = (props) => {
            // dev utility to display click to track data
            const out = JSON.stringify(props, null, 4);
            console.dir(out);
        };

        const updateUserClickTrackHandler = (userClicked, pageName) => {
            let phone;
            switch (pageName) {
                case "financeTab":
                    userClicked.adobe.otherProps.contentValue = localStorage.getItem("financeTabToggleValue");
                    break;
                case "financeTabNoProfile":
                    userClicked.adobe.otherProps.contentValue = localStorage.getItem("financeTabToggleValueNoProfile");
                    break;
                case "leaseTab":
                    userClicked.adobe.otherProps.contentValue = localStorage.getItem("leaseTabToggleValue");
                    break;
                case "leaseTabNoProfile":
                    userClicked.adobe.otherProps.contentValue = localStorage.getItem("leaseTabToggleValueNoProfile");
                    break;
                case "appointmentTab":
                    phone = localStorage.getItem("appointmentTabPhone");
                    userClicked.google.clickToCallPhone = phone;
                    userClicked.adobe.otherProps.clickToCallPhone = phone;
                    break;
                case "financeTabDropDown":
                    userClicked.adobe.otherProps.contentSelect = localStorage.getItem("financeTabDropDownValue");
                    break;
                case "leaseTabDropDown":
                    userClicked.adobe.otherProps.contentSelect = localStorage.getItem("leaseTabDropDownValue");
                    break;
                case "orderConfirmPage":
                    phone = localStorage.getItem("orderCertificateDealerPhone");
                    userClicked.google["nissan-partner-tagging"].clickToCallPhone = phone;
                    userClicked.adobe.otherProps.clickToCallPhone = phone;
                    break;
                case "myNissanRewardsPoints":
                    let nissanToggleValue = localStorage.getItem("myNissanRewardsPointsToggleActionValue");
                    nissanToggleValue = nissanToggleValue === "true" ? "selected" : "deselected";
                    userClicked.adobe.otherProps.contentAction = nissanToggleValue;
                    break;
                case "promoCode":
                    let promoCodeToggleValue = localStorage.getItem("promoCodeToggleActionValue");
                    promoCodeToggleValue = promoCodeToggleValue === "true" ? "selected" : "deselected";
                    userClicked.adobe.otherProps.contentAction = promoCodeToggleValue;
                    break;
                case "contactUsAction":
                    let contactUsPhoneNumberValue = localStorage.getItem("contactUsPhoneNumber");
                    userClicked.google["nissan-partner-tagging"].clickToCallPhone = contactUsPhoneNumberValue;
                    userClicked.adobe.otherProps.clickToCallPhone = contactUsPhoneNumberValue;
                    break;
                case "rebatesAndIncentiveTabChangedAction":
                    userClicked.adobe.otherProps.contentAction = localStorage.getItem("rebatesAndIncentiveTabChanged");
                    break;
                case "noProfileDealTypeChangedAction":
                    userClicked.adobe.otherProps.contentAction = localStorage.getItem("noProfileDealTypeChanged");
                    break;
                default:
                    break; // no action
            }
        };

        /**
         *
         * @param {*} elementClicked must match a prop in tagData.js
         */
        Vue.prototype.$carsaverEventTracker = function (elementClicked = "noData", pageName = "") {
            let isValidElement = false;

            // check if the props passed exist in the object data
            if (clickToTrackData && typeof clickToTrackData[elementClicked] !== "undefined") {
                isValidElement = true;
            }

            let userClicked = {};
            if (isValidElement) {
                userClicked = clickToTrackData[elementClicked];

                if (pageName != "") {
                    updateUserClickTrackHandler(userClicked, pageName);
                }

                const environment = lodashGet(window, "_APP_CONFIG.env[0]", "local") || "local";
                environment === "local" || environment === "beta" ? showProps(userClicked) : null;
            } else {
                console.log(`Logger: the received props: ${elementClicked}, were not found in tagsData.js`);
            }

            if (isAdobeEnabled && isValidElement) {
                _satellite.track(userClicked.adobe.kindOfTracking || {}, userClicked.adobe.otherProps || {});
            }

            if (isGoogleEnabled && isValidElement) {
                this.$gtm.trackEvent({ ...(userClicked.google || {}) });
            }
        };
    },
};
