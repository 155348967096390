<template>
    <div>
        <div v-if="isDuplicateEmail(validationContext)" class="invalid-feedback d-flex flex-column">
            <span>Email already in use.</span>
            <span v-if="!loggedIn">
                Please use a different email or
                <a href="/account/login">log in.</a>
            </span>
        </div>
        <div v-else-if="validationContext.invalid" class="invalid-feedback d-flex">
            {{ validationContext.errors[0] }}
        </div>
    </div>
</template>
<script>
import lodashGet from "lodash/get";
import lodashIsEmpty from "lodash/isEmpty";
import lodashSome from "lodash/some";

export default {
    components: {},
    props: {
        validationContext: {
            type: Object,
            required: true,
        },
        loggedIn: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {};
    },
    methods: {
        isDuplicateEmail(validationContext) {
            return (
                !lodashIsEmpty(lodashGet(validationContext, "errors", [])) &&
                lodashSome(validationContext.errors, (err) => err === "Duplicate email")
            );
        },
    },
};
</script>
<style lang="scss" scoped></style>
