<template>
    <footer
        class="main-footer d-flex align-items-center justify-content-center pt-4 pb-sm-4"
        :class="{ 'overlay-main-footer': isOverlayEnabled }"
    >
        <b-container>
            <b-row>
                <b-col>
                    <div class="content-wrapper flex-sm-row flex-md-nowrap justify-content-md-between">
                        <div class="info flex-sm-row justify-content-md-start">
                            <ul class="d-flex justify-content-center justify-content-md-start mr-3">
                                <li>&copy; Nissan {{ currentYear }}. All rights reserved.</li>
                            </ul>
                            <ul class="d-flex flex-column flex-sm-row">
                                <li>
                                    <b-link
                                        :target="determineTarget"
                                        href="https://www.nissanusa.com/privacy.html#legal"
                                        @click="footerLinksClick('trackTermsOfUseAction')"
                                    >
                                        Legal
                                    </b-link>
                                </li>
                                <li>
                                    <b-link
                                        :target="determineTarget"
                                        href="https://www.nissanusa.com/privacy.html#categories-information-collect-disclose"
                                        @click="footerLinksClick('trackPrivacyPolicyAction')"
                                    >
                                        Privacy Notice
                                    </b-link>
                                </li>
                                <li>
                                    <b-link
                                        :target="determineTarget"
                                        href="https://www.nissanusa.com/data-privacy.html"
                                        @click="footerLinksClick('trackDoNotSellMyInfoAction')"
                                    >
                                        Do Not Sell or Share My Personal Information
                                    </b-link>
                                </li>
                                <li>
                                    <b-link
                                        :target="determineTarget"
                                        href="https://www.nissanusa.com/contact-us.html"
                                        @click="footerLinksClick('trackContactUsAction')"
                                    >
                                        Contact Us
                                    </b-link>
                                </li>
                                <li>
                                    <button id="ot-sdk-btn" class="ot-sdk-show-settings">Cookie Settings</button>
                                </li>
                            </ul>
                        </div>
                        <div class="logo text-sm-left mt-2 mt-md-0">
                            <p class="text-context font-weight-bold logo-mobile">Powered by</p>
                            <div
                                class="logo-img mx-auto logo-mobile"
                                role="img"
                                aria-label="Powered by Carsaver"
                                style="margin-bottom: 10px"
                            />
                        </div>
                    </div>
                    <div>
                        <div class="logo text-center text-sm-left mt-2 mt-md-0">
                            <p class="text-context font-weight-bold text-justify">
                                {{ $t("footer.prop64") }}
                                <b-link href="https://www.p65warnings.ca.gov/products/passenger-vehicle">
                                    P65 Warnings
                                </b-link>
                            </p>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
import { get } from "vuex-pathify";

export default {
    name: "NissanFooter",
    computed: {
        campaignTheme: get("campaign/theme"),
        isOverlayEnabled: get("context/overlayEnabled"),
        currentYear() {
            return new Date().getFullYear();
        },
        determineTarget() {
            let result = "_self";
            if (this.isOverlayEnabled) {
                result = "_blank";
            }
            return result;
        },
    },
    methods: {
        footerLinksClick(clickIdentifier = "") {
            this.$carsaverEventTracker(clickIdentifier);
        },
    },
};
</script>

<style lang="scss">
.overlay-main-footer {
    margin-bottom: 55px;
}
.main-footer {
    background-color: #eaeaea;
    color: gray("600");
    font-size: 14px;
    margin-top: 64px;
    min-height: 100px;

    .content-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: inline-block;
        }

        a {
            color: $gray-700;
        }
    }

    .info {
        line-height: 25px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;

        ul {
            text-align: center;
        }

        li {
            line-height: 1.4;
            margin-bottom: 16px;
        }

        @include media-breakpoint-up("sm") {
            li:not(:last-child):after {
                opacity: 0.89;
                content: "|";
                margin-right: 5px;
                margin-left: 5px;
            }

            li {
                margin-bottom: 4px;
            }
        }
    }

    .logo {
        a {
            color: $gray-700;
        }

        .text-context {
            font-size: px2rem(10);
            line-height: 13px;
            font-style: italic;
            margin: 0;
        }

        .logo-img {
            width: 115px;
            height: 25px;
            background-repeat: no-repeat;
            @include themify($themes) {
                background-image: themed("footer-logo");
            }
        }
    }

    .logo-mobile {
        a {
            color: $gray-700;
        }

        margin-left: 35px !important;
    }
}

footer button.ot-sdk-show-settings {
    background: none !important;
    border: none !important;
    word-break: break-word;
    color: $gray-700 !important;
    text-align: left;
    text-decoration: underline;
    font-size: inherit !important;
    padding: 0 !important;
    line-height: inherit !important;
    height: inherit !important;
    white-space: inherit !important;
    word-wrap: inherit !important;
}

footer button.ot-sdk-show-settings:hover {
    text-decoration: underline;
}
</style>
