<template>
    <div class="in-transit-flag d-flex flex-wrap align-items-end" :class="addClass">
        <span v-if="shouldDisplayStockType" key="stock-type-key" class="stock-type-flag">{{ stockType }} -&nbsp;</span>
        {{ $t("inTransitFlag.inTransit") }}&nbsp;
        <span v-if="vinOnDealerLotDate" key="vin-on-dealer-lot-key">
            {{ $t("inTransitFlag.estimatedDelivery") }}&nbsp;{{ vinOnDealerLotDate | moment("MM/DD/YYYY") }}&nbsp;
        </span>
        <tooltip :aria-label="'in transit flag message'">
            {{ $t("inTransitFlag.message") }}
        </tooltip>
    </div>
</template>
<script>
import lodashGet from "lodash/get";
import Tooltip from "Components/Tooltip";

export default {
    name: "InTransitFlag",
    components: { Tooltip },
    props: {
        vehicle: {
            type: Object,
            required: true,
        },
        shouldDisplayStockType: {
            type: Boolean,
            required: false,
            default: true,
        },
        addClass: {
            type: String,
            required: false,
            default: "",
        },
    },
    computed: {
        vinOnDealerLotDate() {
            return lodashGet(this.vehicle, "vinOnDealerLotDate");
        },
        stockType() {
            const stockTypeName = lodashGet(this.vehicle, "stockType", "");

            return stockTypeName.toLowerCase();
        },
    },
};
</script>
<style lang="scss">
.in-transit-flag {
    font-size: px2rem(12);
    line-height: px2rem(14);
    text-transform: capitalize;
    white-space: nowrap;
    @include themify($themes) {
        color: themed("primary-color");
    }

    .stock-type-flag {
        color: #717171;
    }
}
</style>
