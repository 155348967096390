import lodashGet from "lodash/get";

const CampaignProductEnum = Object.freeze({
    ECOMMERCE: 102,
    UPGRADE: 103,
});

export const isUpgradeProduct = () => {
    return (lodashGet(window, "_CAMPAIGN.productId", null) || null) === CampaignProductEnum.UPGRADE;
};

export default CampaignProductEnum;
