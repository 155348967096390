<template>
    <b-navbar class="main-menu">
        <b-container class="px-sm-3">
            <b-navbar-brand class="p-0 d-flex align-items-center">
                <theme-img class="nav-logo-img img-fluid" />
                <span v-if="navBrand && isDealerHeaderEnabled" class="brand-name ml-2">
                    {{ navBrand }}
                </span>
                <span v-else-if="!showCampaignTitle" class="upgrade-brand-name ml-2">{{ $t("campaign.title") }}</span>
            </b-navbar-brand>
            <div class="d-flex align-items-center menu-bar-links">
                <!-- Displays on desktop-->
                <div v-if="!isLoggedIn" class="d-none d-sm-block login-link mr-2">
                    Already have an account?
                    <primary-link class="link-color" @click.prevent.native="handleClick()"> Sign in here </primary-link>
                </div>

                <!-- Display on mobile-->
                <primary-link
                    v-if="!isLoggedIn"
                    class="d-sm-none link-color login-link mr-2"
                    @click.prevent.native="handleClick()"
                    >Log In
                </primary-link>

                <div v-if="isEcommerceAndPageIsEnabled" class="contact-container d-none d-lg-flex">
                    <hr v-if="isLoggedOut" class="contact-hr" />

                    <b-button class="link-color p-0 mr-3" variant="link" @click="shopVehicle('NEW')">
                        Shop New
                    </b-button>
                </div>

                <div v-if="isEcommerceAndPageIsEnabled" class="contact-container d-none d-lg-flex">
                    <hr class="contact-hr" />

                    <b-button class="link-color p-0 mr-3" variant="link" @click="shopVehicle('USED')">
                        Shop Used
                    </b-button>
                </div>

                <div v-if="!isECommerceProduct" class="contact-container d-none d-lg-flex">
                    <hr v-if="isLoggedOut" class="contact-hr" />

                    <b-button
                        v-if="phoneNumberFormatted"
                        :href="contactUsConfigs.href"
                        class="link-color p-0 mr-3"
                        variant="link"
                        @click="contactUsBtnHandler"
                    >
                        {{ contactUsConfigs.text }}
                    </b-button>
                </div>

                <menu-btn :links="links"></menu-btn>
            </div>
        </b-container>
    </b-navbar>
</template>

<script>
import MenuBtn from "./components/MenuBtn";
import { get } from "vuex-pathify";
import saveReminderModalMixin from "Modules/VehicleAndDeal/components/SaveReminderModal/saveReminderModalMixin";
import ThemeImg from "Components/ThemeImg";
import PrimaryLink from "Components/Links/primaryLink";
import CampaignProductEnum from "@/libs/CampaignProductEnum";
import lodashGet from "lodash/get";
import lodashIncludes from "lodash/includes";

export default {
    name: "Navigation",
    components: {
        ThemeImg,
        MenuBtn,
        PrimaryLink,
    },
    mixins: [saveReminderModalMixin],
    data() {
        return {
            saveReminderModalConfig: {
                eventPostSave: null,
                eventPostCancel: "goToHomeEvent",
                eventOnHidden: null,
            },
            screenWidth: window.innerWidth,
            contactUsConfigs: {
                text: "Contact Us",
                href: "",
            },
        };
    },
    computed: {
        dealerId: get("context/dealerId"),
        productId: get("campaign/productId"),
        isECommerceProduct() {
            return this.productId === CampaignProductEnum.ECOMMERCE;
        },
        isUpgradeProduct() {
            return this.productId === CampaignProductEnum.UPGRADE;
        },
        cobrowseLicenseKey: get("campaign/cobrowseLicenseKey"),
        theme: get("campaign/theme"),
        isLoggedIn: get("context/isLoggedIn"),
        disabledPages: get("context/disabledPages"),
        channel: get("campaign/channel"),
        callCenterEnabled: get("campaign/callCenterEnabled"),
        warrantyEnabled: get("campaign/warrantyEnabled"),
        featureFlags: get("context/featureFlags"),
        isCoBrowseEnabled() {
            return lodashGet(this.featureFlags, "ENABLE_CO_BROWSE", false) || false;
        },
        isLoggedOut() {
            return !this.isLoggedIn;
        },
        isDealerHeaderEnabled() {
            return this.channel !== "upgrade";
        },
        navBrand: get("context/navBrand"),
        campaignTheme: get("campaign/theme"),
        phoneNumberFormatted: get("campaign/phoneNumberFormatted"),
        phoneNumber: get("campaign/phoneNumber"),
        isMedium() {
            const mediumDeviceWidth = 1023;
            return this.screenWidth > mediumDeviceWidth;
        },
        links() {
            return [
                {
                    name: "Home",
                    href: "/",
                    disabled: this.isECommerceProduct && this.isLoggedIn,
                },
                {
                    name: "My Garage",
                    href: "/garage",
                    enableCountBadge: true,
                    disabled: !this.isLoggedIn || this.isPageDisabled("garage"),
                },
                {
                    name: "Value Trade",
                    href: "/trade",
                    disabled: !this.isLoggedIn || this.isPageDisabled("trade"),
                },
                {
                    name: "Personal Shopper",
                    enablePersonalShopperLink: true,
                    disabled: !this.callCenterEnabled,
                },
                {
                    name: "Lifetime Warranty",
                    href: "/warranty",
                    disabled: !this.warrantyEnabled || this.isUpgradeProduct,
                },
                // Todo: Enable once configs are set
                // {
                //     name: "Insure",
                //     href: "/insure",
                //     disabled: this.isPageDisabled("insure"),
                // },
                {
                    name: "Contact Us",
                    href: `tel:${this.phoneNumber}`,
                    disabled: this.isMedium || !this.phoneNumberFormatted || this.isECommerceProduct,
                },
                // {
                //     name: "Shop New",
                //     href: "/listings",
                //     disabled: !this.isLoggedIn || this.isPageDisabled("listings") || this.isECommerceProduct,
                // },
                {
                    name: "Shop New",
                    href: this.getUrl("NEW"),
                    disabled: !this.isLoggedIn || !this.isNewInventoryEnabled,
                },
                {
                    name: "Shop Used",
                    href: this.getUrl("USED"),
                    disabled: !this.isLoggedIn || !this.isUsedInventoryEnabled,
                },
                {
                    name: "Sign Out",
                    href: "/logout",
                    disabled: this.isLoggedOut || this.isPageDisabled("sign-out"),
                },
            ];
        },
        stockTypeConditions: get("campaign/stockTypeConditions"),
        showCampaignTitle() {
            // Adding Usbank here as title is included in logo. So to avoid multiple times added here.
            return lodashIncludes(["usbank", "eecu"], this.campaignTheme);
        },
        isEcommerceAndPageIsEnabled() {
            let enabled = false;
            let listingsPageEnabled = this.$page.isEnabled("listings");
            let currentRoute = window.location.pathname;
            let listOfDisabledPathNames = ["/", "/profile/get-started", "/account/login", "/account/register"];
            // disable paths only if user is not logged in
            if (this.isLoggedIn) {
                listOfDisabledPathNames = [];
            }

            let isCurrentRouteDisabled = lodashIncludes(listOfDisabledPathNames, currentRoute);
            if (listingsPageEnabled && this.isECommerceProduct && this.dealerId && !isCurrentRouteDisabled) {
                enabled = true;
            }

            return enabled;
        },
        isUsedInventoryEnabled() {
            const USED = "USED";
            return this.inventoryChecker(USED);
        },
        isNewInventoryEnabled() {
            const NEW = "NEW";
            return this.inventoryChecker(NEW);
        },
        isGhostAccountRegisterFeature() {
            const result = lodashGet(this.featureFlags, "GHOST_ACCOUNT_REGISTER_FEATURE", false) || false;
            return result;
        },
    },

    mounted() {
        localStorage.setItem("COBROWSE_LICENSE_KEY", this.cobrowseLicenseKey);
        this.initEventListeners();

        if (this.isCoBrowseEnabled) {
            (function (w, t, c, p, s, e) {
                p = new Promise(function (r) {
                    w[c] = {
                        client: function () {
                            if (!s) {
                                s = document.createElement(t);
                                s.src = "https://js.cobrowse.io/CobrowseIO.js";
                                s.async = 1;
                                e = document.getElementsByTagName(t)[0];
                                e.parentNode.insertBefore(s, e);
                                s.onload = function () {
                                    r(w[c]);
                                };
                            }
                            return p;
                        },
                    };
                });
            })(window, "script", "CobrowseIO");
            CobrowseIO.license = "Gw5wwVUiACrsDg";
            CobrowseIO.client().then(function () {
                CobrowseIO.start();
            });
        }
    },

    methods: {
        getUrl(stockType) {
            if (this.isECommerceProduct) {
                return `/listings?distance=-1&stockTypes=${stockType}`;
            } else {
                return `/listings?distance=-1&stockTypeConditions=${stockType}&sort=price,asc&page=1`;
            }
        },
        inventoryChecker(stockType) {
            if (this.isECommerceProduct) {
                return this.isEcommerceAndPageIsEnabled;
            } else {
                return lodashIncludes(this.stockTypeConditions, stockType);
            }
        },
        onCompleteReload() {
            this.$router.go(0);
        },
        shopVehicle(vehicleType) {
            let path = "/listings?distance=-1&stockTypes=";
            if (vehicleType === "NEW") {
                this.$router.push({ path: `${path}NEW` }, this.onCompleteReload);
            } else {
                this.$router.push({ path: `${path}USED` }, this.onCompleteReload);
            }
        },
        contactUsBtnHandler() {
            if (this.contactUsConfigs.text === "Contact Us") {
                this.$carsaverEventTracker("trackContactUSAction");
            } else {
                localStorage.setItem("contactUsPhoneNumber", this.contactUsConfigs.text);
                this.$carsaverEventTracker("trackContactNumberAction", "contactUsAction");
            }
            this.contactUsConfigs.text = this.phoneNumberFormatted;
            this.contactUsConfigs.href = "tel:" + this.phoneNumber;
        },
        goHome() {
            const to = { path: "/" };
            const link = this.$router.resolve(to);
            const matches = link.resolved.matched;

            if (matches.length !== 0) {
                this.$router.push(to);
            } else {
                // If not a defined route
                document.location = to.path;
            }
        },
        isPageDisabled(page) {
            let isDisabled = false;

            if (Array.isArray(this.disabledPages)) {
                isDisabled = this.disabledPages.indexOf(page) !== -1;
            }

            return isDisabled;
        },
        onIconClickHandler() {
            if (this.isSaveReminderModalEnabled) {
                this.openSaveReminderModal();
            } else {
                this.goHome();
            }
        },
        initEventListeners() {
            this.$root.$on("goToHomeEvent", this.goHome);
        },
        handleClick() {
            this.trackClick();

            if (this.isECommerceProduct && this.isGhostAccountRegisterFeature === true) {
                this.$root.$emit("ghost-account-modal");
            } else {
                window.location = "/account/login";
            }
        },
        trackClick() {
            this.$carsaverEventTracker("homeSignInHere");
        },
    },
};
</script>

<style lang="scss" scoped>
.main-menu {
    height: 50px;
    z-index: 1000;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

    .nav-logo-img {
        @include themify($themes) {
            @if themed("logo-size") {
                max-height: themed("logo-size");
            } @else {
                max-height: 34px;
            }
        }
    }

    .brand-name {
        color: $gray-700;
        font-size: px2rem(18);
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 185px;
        line-height: 34px;

        @include media-breakpoint-up(sm) {
            max-width: 100%;
        }
    }

    .demo-brand-name {
        color: $white;
        font-size: px2rem(18);
        line-height: 22px;
    }

    .upgrade-brand-name {
        @include themify($themes) {
            color: themed("navbar-text-color");
            font-weight: themed("brand-name-weight");
        }
        font-size: px2rem(18);
        line-height: 22px;

        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .menu-bar-links {
        color: inherit;
        font-size: px2rem(12);
        letter-spacing: 0;
        line-height: px2rem(16);
        margin-bottom: 1px;

        button,
        a {
            font-size: inherit;
        }
    }

    .login-link {
        a {
            color: inherit;
        }
    }

    .link-color {
        @include themify($themes) {
            color: themed("signin-link-color");
        }
    }

    .contact-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .contact-hr {
        display: block;
        border: 0;
        height: 16px;
        margin: 0 16px;
        @include themify($themes) {
            border-left: 1px solid themed("navbar-text-color");
        }
    }
}
</style>
