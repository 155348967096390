<template>
    <b-link v-bind="$attrs" class="theme-styles">
        <slot> Message</slot>
    </b-link>
</template>

<script>
export default {
    name: "BaseLink",
};
</script>
