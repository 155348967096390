<template>
    <div class="pb-3">
        <div class="d-flex flex-row">
            <div>
                <b-img-lazy :width="75" :height="75" :src="photo" class="rounded-circle" alt="Personal Shopper Image" />
            </div>
            <div class="align-self-center ml-3 flex-grow-1">
                <div class="personal-shopper-name">{{ name }}</div>
                <div class="personal-shopper-sin">{{ $t("deal.shopperText") }} since {{ employmentStartYear }}</div>
                <div v-if="spanishSpeaking" class="se-habla-espanol pt-1">Se Habla Español</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PersonalShopper",
    components: {},
    props: {
        name: {
            type: String,
            default: "unknown",
        },
        photo: {
            type: String,
            required: true,
        },
        employmentStartYear: {
            type: Number,
            required: true,
        },
        spanishSpeaking: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
};
</script>

<style lang="scss" scoped>
.personal-shopper-name {
    font-size: px2rem(18);
    font-weight: bold;
    line-height: px2rem(21);
}
.personal-shopper-sin {
    font-size: px2rem(14);
    line-height: px2rem(16);
}
.se-habla-espanol {
    color: $gray-600;
    font-style: italic;
    line-height: px2rem(17);
}
</style>
