import lodashGet from "lodash/get";
import lodashIsEmpty from "lodash/isEmpty";

const convertErrorToObserverErrors = (error) => {
    const fieldErrors = lodashGet(error.response, "data.errors", []);
    return convertFieldErrorsToObserverErrors(fieldErrors);
};

const convertFieldErrorsToObserverErrors = (fieldErrors) => {
    if (!lodashIsEmpty(fieldErrors)) {
        return fieldErrors.reduce((obj, item) => ((obj[item.field] = [item.message]), obj), {});
    }

    return [];
};

export default {
    convertErrorToObserverErrors,
    convertFieldErrorsToObserverErrors,
};
