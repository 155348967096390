import { dispatch, make } from "vuex-pathify";
import api from "@/api";
import loader from "@/api/loader";
import lodashGet from "lodash/get";
import lodashSet from "lodash/set";
import lodashIsEmpty from "lodash/isEmpty";
import lodashIsNil from "lodash/isNil";

const initialState = {
    vehicle: {
        data: null,
        loader: loader.defaultState(),
    },
    certificate: {
        data: null,
        loader: loader.defaultState(),
    },
    salePrice: {
        data: null,
        loader: loader.defaultState(),
    },
    media: {
        evoxImages: {
            data: [],
            loader: loader.defaultState(),
        },
        evoxVideo: {
            data: [],
            loader: loader.defaultState(),
        },
        evoxThreeSixty: {
            data: null,
            loader: loader.defaultState(),
        },

        show360: false,
        showImageGallery: true,
        showVideos: false,
        resizeImageGallery: false,
    },
    creditProfileSelfSelected: false,
    creditProfileEvaluator: null,
    modalLoading: null,
};

const actions = {
    ...make.actions(initialState),
    setModalLoading({ commit, state }, value) {
        commit("SET_MODAL_LOADING", value);
    },
    fetchVehicleDetails({ commit, state }, id) {
        commit("SET_VEHICLE_LOADER", loader.started());
        return api
            .get(`/vehicles/${id}`)
            .then((response) => {
                commit("SET_VEHICLE", response.data);
                commit("SET_VEHICLE_LOADER", loader.successful());
            })
            .catch((error) => {
                console.error("error =", error);
                commit("SET_VEHICLE_LOADER", loader.error(error));
                throw new Error(error);
            });
    },

    fetchEvoxImages({ commit, state }) {
        commit("SET_EVOX_IMAGES_LOADER", loader.started());
        const styleId = lodashGet(state.vehicle, "data.styleId", null);
        if (styleId) {
            return api
                .get(`/vehicles/evox-images/${styleId}`)
                .then((response) => {
                    commit("SET_EVOX_IMAGES", response.data);
                    commit("SET_EVOX_IMAGES_LOADER", loader.successful());

                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    commit("SET_EVOX_IMAGES_LOADER", loader.error(error));
                });
        }

        commit("SET_EVOX_IMAGES_LOADER", loader.error());

        return Promise.resolve();
    },

    fetchEvoxVideo({ commit, state }) {
        commit("SET_EVOX_VIDEO_LOADER", loader.started());
        const styleId = lodashGet(state.vehicle, "data.styleId", null);
        if (styleId) {
            return api
                .get(`/vehicles/evox-video/${styleId}`)
                .then((response) => {
                    commit("SET_EVOX_VIDEO", response.data);
                    commit("SET_EVOX_VIDEO_LOADER", loader.successful());

                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    commit("SET_EVOX_VIDEO_LOADER", loader.error(error));
                });
        }

        commit("SET_EVOX_VIDEO_LOADER", loader.error());

        return Promise.resolve();
    },

    fetchThreeSixtyImages({ commit, state }) {
        commit("SET_THREE_SIXTY_IMAGES_LOADER", loader.started());
        const styleId = lodashGet(state.vehicle, "data.styleId", null);
        const modelId = lodashGet(state.vehicle, "data.modelId", null);
        if (styleId) {
            return api
                .get(`/vehicles/three-sixty-images/${styleId}`, {
                    modelId: modelId,
                })
                .then((response) => {
                    commit("SET_THREE_SIXTY_IMAGES", response.data);
                    commit("SET_THREE_SIXTY_IMAGES_LOADER", loader.successful());

                    return response.data;
                })
                .catch((error) => {
                    console.error(error);
                    commit("SET_THREE_SIXTY_IMAGES_LOADER", loader.error(error));
                });
        }

        commit("SET_THREE_SIXTY_IMAGES_LOADER", loader.error());

        return Promise.resolve();
    },

    // This method will attempt to fetch a certificate from either the certificate id
    // of let the server look one up or create one to return back.
    // This is designed to fetch the corresponding modal associated with the certificate
    // which is used by the deal page and VDP.
    fetchCertificate({ commit, state }, { vehicleId, certificateId, paymentType }) {
        commit("SET_CERTIFICATE_LOADER", loader.started());

        const loadCertUrl = !lodashIsEmpty(certificateId)
            ? `/vehicles/${vehicleId}/certificates/${certificateId}`
            : `/vehicles/${vehicleId}/certificates`;

        return api
            .get(loadCertUrl, { paymentType })
            .then((response) => {
                commit("SET_CERTIFICATE", response.data);
                commit("SET_CERTIFICATE_LOADER", loader.successful());
                return response.data;
            })
            .catch((error) => {
                commit("SET_CERTIFICATE_LOADER", loader.error(error));
            });
    },

    fetchSalePrice({ commit, state }, { certificateId }) {
        if (lodashGet(state, "salePrice.loader.isLoading")) {
            return;
        }

        commit("SET_SALE_PRICE_LOADER", loader.started());

        return api
            .get(`/vehicles/${certificateId}/saleprice`)
            .then((response) => {
                const salePriceData = lodashGet(response, "data");

                commit("SET_SALE_PRICE", salePriceData);

                commit("SET_SALE_PRICE_LOADER", loader.successful());
            })
            .catch((error) => {
                console.error("fetchSalePrice error: ", error);
                commit("SET_SALE_PRICE_LOADER", loader.error(error));
            });
    },

    saveDeal({ commit, state }, dealName) {
        const certificateId = lodashGet(state, "certificate.data.certificateId");

        if (lodashIsNil(certificateId)) {
            console.error("Unable to find a certificateId");
            return;
        }

        commit("SET_SAVED", true);

        const dealPreferences = lodashGet(state, "certificate.data.dealPreferences", null);
        const oldDealName = lodashGet(state, "certificate.data.dealName");

        commit("SET_DEAL_NAME", dealName);
        return api
            .post(`/deals/${certificateId}/update`, {
                updateQuote: true, // This is now always required (garage now uses the quote from the saved certificate)
                dealName,
                dealPreferences,
                sendLead: true,
            })
            .then(() => {
                // updates deal count in context
                dispatch("context/fetchSavedDealCount");
            })
            .catch((error) => {
                console.error("Vehicle saveDeal error: ", error);
                commit("SET_DEAL_NAME", oldDealName);
                commit("SET_SAVED", false);
                throw new Error(error);
            });
    },

    fetchUserCreditProfileEvaluator({ commit }) {
        return api
            .get("/soft-pull/user/credit-profile/evaluator")
            .then((response) => {
                const isSelfSelected = response.data === "CUSTOMER";
                commit("SET_CREDIT_PROFILE_EVALUATOR", response.data);
                commit("SET_CREDIT_PROFILE_SELF_SELECTED", isSelfSelected);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    commit("SET_CREDIT_PROFILE_EVALUATOR", null);
                    commit("SET_CREDIT_PROFILE_SELF_SELECTED", false);
                } else {
                    console.error(error);
                }
            });
    },
};

const mutations = {
    ...make.mutations(initialState),

    SET_SAVED: (state, payload) => {
        lodashSet(state, "certificate.data.saved", payload);
    },

    SET_DEAL_NAME: (state, payload) => {
        lodashSet(state, "certificate.data.dealName", payload);
    },

    SET_SALE_PRICE: (state, payload) => {
        state.salePrice.data = payload;
    },

    SET_SALE_PRICE_LOADER: (state, payload) => {
        state.salePrice.loader = payload;
    },

    SET_VEHICLE_LOADER: (state, payload) => {
        state.vehicle.loader = payload;
    },

    SET_VEHICLE: (state, payload) => {
        state.vehicle.data = payload;
    },

    SET_CERTIFICATE_LOADER: (state, payload) => {
        state.certificate.loader = payload;
    },

    SET_CERTIFICATE: (state, payload) => {
        state.certificate.data = payload;
    },

    SET_EVOX_IMAGES_LOADER: (state, payload) => {
        state.media.evoxImages.loader = payload;
    },

    SET_EVOX_IMAGES: (state, payload) => {
        state.media.evoxImages.data = payload;
    },

    SET_EVOX_VIDEO_LOADER: (state, payload) => {
        state.media.evoxVideo.loader = payload;
    },

    SET_EVOX_VIDEO: (state, payload) => {
        state.media.evoxVideo.data = payload;
    },

    SET_THREE_SIXTY_IMAGES_LOADER: (state, payload) => {
        state.media.evoxThreeSixty.loader = payload;
    },

    SET_THREE_SIXTY_IMAGES: (state, payload) => {
        state.media.evoxThreeSixty.data = payload;
    },
    SET_MODAL_LOADING: (state, payload) => {
        state.modalLoading = payload;
    },
};

const getters = {
    ...make.getters(initialState),
};

export default {
    namespaced: true,
    state: initialState,
    actions,
    mutations,
    getters,
};
