import { render, staticRenderFns } from "./MenuBadge.vue?vue&type=template&id=2fb8601a&scoped=true&"
import script from "./MenuBadge.vue?vue&type=script&lang=js&"
export * from "./MenuBadge.vue?vue&type=script&lang=js&"
import style0 from "./MenuBadge.vue?vue&type=style&index=0&id=2fb8601a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.10.2_babel-core@6.26.3_cache-loader@4.1.0_css-loader@3.6.0_lodash@4.17.21_prett_26klfa342gafietiguuukji5n4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fb8601a",
  null
  
)

export default component.exports