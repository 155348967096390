<template>
    <modal
        id="updated-trade-modal"
        aria-label="Upgrade trade Modal"
        ok-only
        ok-title="Close"
        divider
        no-close-on-backdrop
        @hidden="close"
    >
        <template slot="title">
            <div class="p-3 d-flex w-100 justify-content-center">
                <div class="title-container">
                    <h5 class="modal-title">
                        {{ title }}
                    </h5>
                </div>
            </div>
        </template>

        <div v-if="payoffUpdatedInfo" class="w-100 justify-content-center container">
            <div class="vehicle-info-container padding-bottom-28">
                <div class="vehicle-name text-center pb-1">{{ payoffUpdatedInfo.vehicleName }}</div>
                <div class="vin text-center">VIN: {{ payoffUpdatedInfo.vin }}</div>
            </div>

            <div class="px-md-4 trade-value-container padding-bottom-28">
                <div class="trade-value-info text-center">Previous Trade Value</div>
                <hr class="separator" />
                <div class="d-flex justify-content-between row">
                    <div class="col-6 col-md-4 text-center p-0">
                        <span class="label">Trade Offer: </span>
                        <span class="value">{{ payoffUpdatedInfo.oldTradeOffer | money("$0,0") }}</span>
                    </div>
                    <div class="col-6 col-md-4 text-center p-0">
                        <span class="label">Payoff: </span>
                        <span class="value">{{ payoffUpdatedInfo.oldPayoff | money("$0,0") }}</span>
                    </div>
                    <div class="col-12 col-md-4 text-center p-0 pt-1 pt-md-0">
                        <span class="label">Net Offer: </span>
                        <span class="value">{{ payoffUpdatedInfo.oldNetOffer | money("$0,0") }}</span>
                    </div>
                </div>
            </div>

            <div class="px-md-4 trade-value-container padding-bottom-28">
                <div class="trade-value-info text-center">Updated Trade Value</div>
                <hr class="separator" />
                <div class="d-flex justify-content-between row">
                    <div class="col-6 col-md-4 text-center p-0">
                        <span class="label">Trade Offer: </span>
                        <span class="value">{{ payoffUpdatedInfo.newTradeOffer | money("$0,0") }}</span>
                    </div>
                    <div class="col-6 col-md-4 text-center p-0">
                        <span class="label">Payoff: </span>
                        <span class="value">{{ payoffUpdatedInfo.newPayoff | money("$0,0") }}</span>
                    </div>
                    <div class="col-12 col-md-4 text-center pt-1 pt-md-0 p-0">
                        <span class="label">Net Offer: </span>
                        <span class="value">{{ payoffUpdatedInfo.newNetOffer | money("$0,0") }}</span>
                    </div>
                </div>
            </div>

            <div class="net-trade-value-container">
                <div class="w-100 justify-content-center py-4">
                    <div class="text-center label">Net Trade Offer</div>
                    <div class="text-center net-offer-value">{{ payoffUpdatedInfo.newNetOffer | money("$0,0") }}</div>
                    <div class="text-center label">{{ netOfferDifference | money("$0,0") }} {{ higherOrLower }}</div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal.vue";
import numeral from "numeral";
import { get } from "vuex-pathify";

export default {
    components: { Modal },
    filters: {
        money(value) {
            return numeral(value).format("$0,0");
        },
    },
    data() {
        return {
            title: "Your Trade Value has been Updated",
        };
    },
    computed: {
        payoffUpdatedInfo: get("context/payoffUpdatedInfo"),
        netOfferDifference() {
            return Math.abs(this.payoffUpdatedInfo.difference);
        },

        higherOrLower() {
            if (this.payoffUpdatedInfo.difference < 0) {
                return "lower";
            }
            return "higher";
        },
    },
    methods: {
        close() {
            sessionStorage.setItem("updatedUserAboutNewTradeValue", "true");
        },
    },
};
</script>

<style lang="scss" scoped>
.modal-title {
    font-size: 28px;
    line-height: 1.3;
    font-weight: normal;
    color: $gray-900;
}

.title-container {
    max-width: 374px;
}

.vehicle-name {
    font-size: 16px;
    font-weight: 700;
    line-height: 1.3;
    color: $gray-700;
}

.vin {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.3;
    color: $gray-700;
}

.trade-value-info {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    color: $gray-500;
}

.container {
    gap: 28px;
    padding-left: 2px;
    padding-right: 2px;
}

.padding-bottom-28 {
    padding-bottom: 28px;
}

.label {
    font-size: 14px;
    font-weight: 700;
    line-height: 1.3;
    color: $gray-700;
}

.value {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    color: $gray-700;
}

.separator {
    margin: 8px 0px;
}

.net-trade-value-container {
    background: #f6f6f6;
}

.net-offer-value {
    font-size: 36px;
    line-height: 1.3;
    font-weight: 700;
    color: $gray-700;
}

.modal-footer {
    padding: 16px;
}
</style>
