<template>
    <transition name="bounce">
        <badge v-if="showBadge">{{ dealCount }}</badge>
    </transition>
</template>

<script>
import Badge from "Components/Badge";
import { call, get } from "vuex-pathify";

export default {
    name: "MenuBadge",
    components: { Badge },
    computed: {
        dealCountLoader: get("context/dealCount@loader"),
        dealCount: get("context/dealCount@data"),
        showBadge() {
            return this.dealCountLoader.isComplete && this.dealCount > 0;
        },
    },
    mounted() {
        this.fetchSavedDealCount();
    },
    methods: {
        fetchSavedDealCount: call("context/fetchSavedDealCount"),
    },
};
</script>

<style lang="scss" scoped>
.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}
</style>
