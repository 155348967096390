<template>
    <b-overlay :show="showBackgroundOverlay" no-wrap opacity="0.4" z-index="1030" blur="4px" class="blank-overlay">
        <template #overlay>
            <span></span>
        </template>
    </b-overlay>
</template>

<script>
import { get } from "vuex-pathify";

export default {
    name: "BlankOverlay",

    computed: {
        showBackgroundOverlay: get("context/showBackgroundOverlay"),
    },
};
</script>

<style lang="scss">
$zindex: 1032;

.overlay-show-through {
    position: inherit;
    z-index: $zindex !important;

    &--active {
        position: inherit;
        z-index: $zindex !important;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.5) !important;
    }
}
</style>
