<template>
    <div
        v-if="!isFloatingFooterhidden"
        :class="{ 'overlay-show-through': activeTourPoint === 'my-deal', 'overlay-enabled': isOverlayEnabled }"
        class="mobile-sticky-footer d-flex d-md-none"
    >
        <div class="d-flex w-100 footer-block">
            <slot />
        </div>
    </div>
</template>
<script>
import { get } from "vuex-pathify";
import lodashGet from "lodash/get";

export default {
    name: "FloatingFooter",
    computed: {
        featureFlags: get("context/featureFlags"),
        activeTourPoint: get("context/activeTourPoint"),
        isOverlayEnabled: get("context/overlayEnabled"),
        isFloatingFooterhidden() {
            const isHidden = lodashGet(this.featureFlags, "HIDE_FLOATING_FOOTERS", false) || false;
            return isHidden;
        },
    },
};
</script>

<style lang="scss">
//to prevent covering up main footer
.main-footer {
    @include media-breakpoint-up(md) {
        margin-bottom: inherit;
    }
    @include media-breakpoint-down(sm) {
        @include themify($themes) {
            padding-bottom: themed("main-footer-bottom") !important;
        }
    }
}

.overlay-enabled {
    bottom: 0 !important;
}
.mobile-sticky-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1030;
    transition: 0.5s;
    transition-timing-function: ease-in;

    .footer-block {
        padding: 0 px2rem(4) px2rem(4) px2rem(4);
        flex-flow: row nowrap;
        @include themify($themes) {
            height: auto;
        }
        background-color: $white;

        .btn-primary,
        .btn-secondary {
            white-space: nowrap;
        }

        .btn-primary {
            width: 100%;
            margin-left: px2rem(2);
        }

        .btn-secondary {
            width: 30%;
            margin-right: px2rem(2);
        }
    }
}
</style>
