<script>
export default {
    data() {
        return {
            saveReminderModalConfig: {
                eventPostCancel: null,
                eventOnHidden: null,
                redirectPath: null,
            },
        };
    },

    computed: {
        currentRouteName() {
            return this.$route.name;
        },
        isModalEnabled() {
            const enabledPages = ["no-profile-deal"];

            return enabledPages.indexOf(this.currentRouteName) !== -1;
        },
    },
    methods: {
        openNoProfileSaveWorkReminderModal() {
            this.$root.$emit("show-no-profile-save-modal", this.saveReminderModalConfig);
        },
    },
};
</script>
