<template>
    <div>
        <modal ref="modal" v-bind="$attrs" size="md" :title="title" centered hide-footer no-stacking>
            <div>
                <h2 class="text-center">Looking forward to speaking with you</h2>
                <div class="confirmation-box d-flex justify-content-center align-items-center">
                    <div class="conf d-flex justify-content-center">
                        <confirmation-message :scheduled-call-back="upcomingCallBack" />
                    </div>
                </div>
                <h2 class="text-center pt-2">What would you like to do?</h2>
                <primary-btn block @click.native="$refs.modal.hide()"> Talk to you then! </primary-btn>
                <secondary-btn block @click.native="$emit('reschedule')"> Reschedule </secondary-btn>
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from "Components/Modal";
import ConfirmationMessage from "./ConfirmationMessage";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import SecondaryBtn from "Components/Buttons/SecondaryBtn";
export default {
    components: {
        SecondaryBtn,
        PrimaryBtn,
        ConfirmationMessage,
        Modal,
    },
    props: {
        upcomingCallBack: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return "You've already scheduled a call back";
        },
    },
};
</script>

<style lang="scss" scoped>
.confirmation-box {
    height: px2rem(93);
    width: 100%;
    background-color: $gray-200;
    margin-bottom: px2rem(16);
    font-size: px2rem(18);
    line-height: px2rem(23);
    color: #2e2e2e;

    @include media-breakpoint-up(sm) {
        font-size: px2rem(24);
        line-height: px2rem(32);
    }
}
</style>
