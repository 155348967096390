/**
 * This inserts Adobe scripts for web analytics
 **/

// check the global environment variable to set local or production
import lodashGet from "lodash/get";
const environment = lodashGet(window, "_APP_CONFIG.env[0]", "local") || "local";

// script links for local and production
const scriptUrl = {
    dev: "//assets.adobedtm.com/de7bac0b5081/27c21525372e/launch-5aa400a7ccab-development.min.js",
    prod: "//assets.adobedtm.com/de7bac0b5081/27c21525372e/launch-2ca28bd2afa2.min.js",
};

// use the script link that matches the global environment
const getEnvironment = () => {
    if (environment === "prod") return scriptUrl.prod;
    return scriptUrl.dev;
};

// call with addAdobeScripts() - takes no arguments
export const addAdobeScripts = () => {
    try {
        let addScript = document.createElement("script");
        addScript.setAttribute("src", getEnvironment());
        addScript.setAttribute("id", "adobeScript");
        if (!document.getElementById("adobeScript")) {
            document.head.appendChild(addScript);
        }
    } catch (error) {
        console.log(`Logger: adding Adobe script failed. ${error}`);
    }
};
