<template>
    <div id="calculating-steps" class="d-flex justify-content-center mt-n3">
        <div class="steps-list">
            <div v-for="(step, idx) in stepsProgress" :key="idx" class="d-flex step-wrapper">
                <div class="d-flex">
                    <div v-if="step.status === undefined" class="d-flex flex-row">
                        <div class="mr-1">
                            <i class="material-icons pending" aria-hidden="true">autorenew</i>
                        </div>
                        <div class="step-text pending">
                            {{ step.defaultText }}
                        </div>
                    </div>

                    <div v-else-if="step.status === 'loading'" class="d-flex flex-row">
                        <div class="loader-wrapper mr-1">
                            <loading-spinner :size="20" class="step-loader" />
                        </div>
                        <div class="step-text">{{ step.defaultText }}</div>
                    </div>

                    <div v-else class="d-flex flex-row">
                        <div class="mr-1">
                            <i class="material-icons finished" aria-hidden="true"> check_circle </i>
                        </div>
                        <div class="step-text">{{ step.finishedText }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import LoadingSpinner from "@carsaver/loading-spinner";
import lodashMap from "lodash/map";
import lodashInRange from "lodash/inRange";
import lodashLast from "lodash/last";

export default {
    components: { LoadingSpinner },
    props: {
        steps: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            counter: 0,
            stepsData: [],
        };
    },
    computed: {
        stepsProgress() {
            return lodashMap(this.stepsData, (step) => {
                if (lodashInRange(this.counter, step.start, step.end)) {
                    step.status = "loading";
                } else if (this.counter >= step.end) {
                    step.status = "finished";
                }

                return step;
            });
        },
    },
    mounted() {
        this.beginSmokeAndMirrors();
    },
    created() {
        this.initStepsData();
    },
    methods: {
        beginSmokeAndMirrors() {
            const delay = 1000; // run every second
            const progressInterval = setInterval(() => {
                this.counter++;
                if (lodashLast(this.stepsData).status === "finished") {
                    clearInterval(progressInterval);
                    this.$emit("calculating-steps-finished");
                }
            }, delay);
        },
        initStepsData() {
            //initialize steps with random start/end times
            let last = 0;
            this.stepsData = lodashMap(this.steps, (step) => {
                let stepData = {}; //dont mutate steps prop
                stepData.defaultText = step.defaultText;
                stepData.finishedText = step.finishedText;
                stepData.start = last;

                //each step lasts 1 or 2 seconds
                stepData.end = last + Math.floor(Math.random() * 2 + 1);

                last = stepData.end;

                return stepData;
            });
        },
    },
};
</script>
<style lang="scss">
#calculating-steps {
    .step-wrapper {
        &:not(:last-child) {
            padding-bottom: 0.5rem;
        }
    }

    .step-loader {
        @include themify($themes) {
            .circle-1 {
                border-top-color: themed("primary-color") !important;
            }

            .circle-2 {
                border-bottom-color: themed("primary-color") !important;
            }
        }
    }
}
</style>
<style lang="scss" scoped>
#calculating-steps {
    .steps-list {
        font-size: px2rem(14);

        .material-icons.pending {
            background-color: #ffffff;
            color: #dddddd;
        }

        .material-icons.loading {
            background-color: #ffffff;
            @include themify($themes) {
                color: themed("primary-color");
            }
        }

        .material-icons.finished {
            font-weight: 600;
            background-color: #ffffff;
            @include themify($themes) {
                color: themed("primary-color");
            }
        }

        .step-text {
            padding-top: 3px;
        }

        .step-text.pending {
            color: #dddddd;
        }

        .loader-wrapper {
            margin-top: 2px;
        }
    }
}
</style>
