<template>
    <modal id="new-ride-promotions-modal" divider hide-footer hide-header>
        <div class="lender-hero-image">
            <primary-link class="position-relative link">
                <i aria-hidden="true" class="close-icon material-icons" @click="closeModal"> close </i>
            </primary-link>
        </div>
        <div class="body-section">
            <div class="header">
                <p class="text-center title">Your New Ride is Waiting for You!</p>
                <p class="text-center sub-title">
                    We have expanded our inventory to include a wide selection of Toyota vehicles.
                </p>
                <hr class="divider" />
            </div>
            <div class="body-content">
                <p class="text text-center">
                    Whether you're eyeing a sleek sedan, a versatile SUV, or a reliable truck, you can find the perfect
                    Toyota that fits your lifestyle and needs.
                </p>
            </div>
        </div>
        <div class="d-flex flex-column footer-section">
            <div class="w-100">
                <primary-btn class="shop-new-btn w-100" @click.native="handleShopNewBtnClick">
                    Shop New Toyotas
                </primary-btn>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "Components/Modal";
import PrimaryLink from "Components/Links/primaryLink";
import { get } from "vuex-pathify";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import CampaignProgramEnum from "@/libs/CampaignProgramEnum";
import MfaRedirectionMixin from "Modules/Home/components/Home/MfaRedirectionMixin.vue";

export default {
    name: "NewRidePromotionsModal",
    components: {
        PrimaryBtn,
        Modal,
        PrimaryLink,
    },
    mixins: [MfaRedirectionMixin],
    computed: {
        isLoggedIn: get("context/isLoggedIn"),
    },
    methods: {
        closeModal() {
            this.$bvModal.hide("new-ride-promotions-modal");
        },
        handleShopNewBtnClick() {
            const queryParams = {
                stockTypeConditions: ["NEW"],
                makes: ["Toyota"],
            };

            if (this.isLoggedIn) {
                // THIS IS ONLY DONE TO NON BMW BANNERS AS FOR BMW WE ARE TAKEN TO LISTING PAGE BY DEFAULT
                window.location = "/listings?" + new URLSearchParams(queryParams);
            } else {
                this._mixin_handleRedirectionsWithFilters(queryParams);
            }
            this.closeModal();
        },
    },
};
</script>

<style lang="scss">
#new-ride-promotions-modal {
    .modal-dialog {
        @include media-breakpoint-down(sm) {
            margin: 90px 16px 0 16px !important;
        }
        .modal-content {
            padding: 0 !important;

            .modal-body {
                padding: 0 !important;
            }
        }
    }

    .body-section {
        padding: 22px 30px 32px 30px;
        @include media-breakpoint-down(sm) {
            padding: 16px 28px 16px 28px;
        }
        .header {
            .title {
                color: $secondary-gray;
                font-size: px2rem(28);
                margin-bottom: px2rem(8) !important;
                @include media-breakpoint-down(sm) {
                    font-size: px2rem(26);
                }
            }
            .sub-title {
                margin-top: 8px;
                font-size: px2rem(15);
                color: $primary-gray;
                @include media-breakpoint-down(sm) {
                    font-size: px2rem(14);
                }
            }
        }
        .body-content {
            line-height: 1.2;
            margin-top: px2rem(34);
            max-width: 452px;
        }
        .divider {
            width: 178px;
            height: 2px;
            margin-top: px2rem(18);
            @include themify($themes) {
                background-color: themed("primary-color");
            }
        }
        p {
            margin: 0 !important;
        }
        .text {
            font-size: px2rem(16);
            color: $primary-gray;
        }
    }

    .footer-section {
        padding: 0 30px 30px 30px;
        @include media-breakpoint-down(sm) {
            padding: 0 16px 16px 16px;
        }

        .shop-new-btn {
            padding: 12px;
            font-size: px2rem(16);
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            @include media-breakpoint-down(sm) {
                font-size: px2rem(16);
            }
        }
    }

    .lender-hero-image {
        position: relative;
        width: 100%;
        height: 220px;
        background-size: cover;
        background-repeat: no-repeat;
        @include media-breakpoint-down(sm) {
            height: 200px;
        }
        @include themify($themes) {
            background-image: themed("new-ride-promotional-banner-lg");
            @include media-breakpoint-down(sm) {
                background-image: themed("new-ride-promotional-banner-md");
            }
        }
    }

    .link {
        z-index: 3;
        top: 10px;
        right: 10px;
        position: absolute !important;
    }

    .lender-hero-image:after {
        top: 0;
        left: 0;
        z-index: 2;
    }

    .close-icon {
        color: $black;
    }
}
</style>
