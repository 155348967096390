import { make } from "vuex-pathify";
import api from "@/api";

const state = {
    formServerErrors: null,
    profileForm: null,
};

const actions = {
    ...make.actions(state),

    registerProfile({ commit }, profileForm) {
        return api.post("/profile/register", profileForm).then((response) => {
            return response;
        });
    },
    validateProfileStepOne({ commit }, profileForm) {
        return api.post("/profile/validate-step-one", profileForm).then((response) => {
            return response;
        });
    },
    registerGhostProfile({ commit }, profileForm) {
        // Reason for register-ghost? @Validate on Controller endpoint validates if the email exists already,
        // before the controller method itself can choose to handle a duplicate email. Want the endpoint to determine
        // next steps, not the @Validate.
        // Did not want to change existing code.
        const result = api.post("/profile/register-ghost", profileForm).then((response) => {
            return response;
        });

        return result;
    },
};

// const mutations = make.mutations(state);
const mutations = {
    ...make.mutations(state),
};
const getters = {
    ...make.getters(state),
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
