<template>
    <base-link v-bind="$attrs" class="primary-link">
        <slot> message</slot>
    </base-link>
</template>

<script>
import baseLink from "./baseLink";

export default {
    name: "PrimaryLink",
    components: { baseLink },
};
</script>

<style lang="scss" scoped>
.primary-link {
    @include themify($themes) {
        color: themed("primary-color");
    }
}

.primary-link:hover {
    @include themify($themes) {
        color: darken(themed("primary-color"), 10%);
    }
}
</style>
