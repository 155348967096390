import { dispatch, make } from "vuex-pathify";
import api from "@/api";
import loader from "@/api/loader";
import Vue from "vue";
import lodashGet from "lodash/get";
import lodashSize from "lodash/size";
import lodashToNumber from "lodash/toNumber";
import lodashFind from "lodash/find";
import lodashIsNil from "lodash/isNil";
import lodashHead from "lodash/head";
import isEqual from "lodash/isEqual";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import filter from "lodash/filter";
import lodashSet from "lodash/set";
import sumBy from "lodash/sumBy";
import lodashOmit from "lodash/omit";
import cloneDeep from "lodash/cloneDeep";
import isNumber from "lodash/isNumber";
import lodashFindIndex from "lodash/findIndex";

const checkComplete = (state, commit) => {
    // If all loading complete
    if (state.deal.loader.isComplete && state.financeQuotes.loader.isComplete && state.leaseQuotes.loader.isComplete) {
        if (lodashSize(state.financeQuotes.data) === 0 && lodashSize(state.leaseQuotes.data) === 0) {
            commit("SET_CASH_DEAL");
        }
    } else {
        if (
            state.deal.loader.isComplete &&
            state.financeQuotes.loader.isComplete &&
            lodashSize(state.financeQuotes.data) === 0 &&
            lodashSize(state.leaseQuotes.data) === 0
        ) {
            commit("SET_CASH_DEAL");
        }
    }
};

const checkProtectionProduct = (state, commit, value) => {
    const hasProtectionProducts = !lodashIsNil(value) && value.length > 0;
    commit("SET_PROTECTION_PRODUCTS_SELECTED", hasProtectionProducts);
};

const state = {
    vehicle: {
        data: null,
        loader: loader.defaultState(),
    },
    deal: {
        data: null,
        loader: loader.defaultState(),
    },
    cash: {
        data: null,
        loader: loader.defaultState(),
    },
    financeQuotes: {
        data: [],
        loader: loader.defaultState(),
    },
    leaseQuotes: {
        data: [],
        loader: loader.defaultState(),
    },
    // Payment Tabs
    tabIndex: 0,
    rebatesTabIndex: 0,

    dealType: null,

    // Set this to true if the deal has been changed.  This will tell the UI to show the save
    // deal button to save the changes.
    // Two things should set dirty to true.
    // 1. Recalculating with a new down payment
    // 2. Selecting a different term
    dirty: false,
    saveDealOpen: false,
    minFinanceDownPayment: 0,
    requestedFinanceDownPayment: 0,
    minLeaseDownPayment: 0,
    requestedLeaseDownPayment: 0,
    lastRequestedDownPayment: null,
    requestedMileage: 12000,
    previouslyRequestedMileage: 12000,
    previouslyRequestedLeaseDownPayment: 0,
    showDownPaymentChangeModal: false,
    previousRequestedDownPayment: 0,
    selectedFinancierType: null,
    savedFinancierType: null,
    scrollToTradeSection: false,
    protectionProductSelected: false,

    //external offers form store
    externalOffers: {
        data: {
            lineItems: [],
            providerType: null,
        },
        loader: loader.defaultState(),
    },
    creditProfileSelfSelected: false,
    creditProfileEvaluator: null,
    preApprovals: {
        data: null,
        loader: loader.defaultState(),
    },
    accessories: {
        data: null,
    },
    driveAtHomeEnabled: {
        data: false,
        loader: loader.defaultState(),
    },
    routeOneProtectionProductsFeatureEnabled: {
        data: null,
        loader: loader.defaultState(),
    },
    protectionProducts: {
        data: null,
        loader: loader.defaultState(),
    },
    routeOneDealJacket: {
        data: null,
        loader: loader.defaultState(),
    },
    userCreditInfo: {
        data: null,
        loader: loader.defaultState(),
    },
    userCreditScore: 300,
    creditScoreTiers: {
        worst: {
            floor: 0,
            ceiling: 599,
            description: "Worst",
        },
        poor: {
            floor: 600,
            ceiling: 619,
            description: "Poor",
        },
        fair: {
            floor: 620,
            ceiling: 659,
            description: "Fair",
        },
        good: {
            floor: 660,
            ceiling: 699,
            description: "Good",
        },
        great: {
            floor: 700,
            ceiling: 739,
            description: "Great",
        },
        excellent: {
            floor: 740,
            ceiling: 850,
            description: "Excellent",
        },
    },
};

const actions = {
    ...make.actions(state),

    setTab({ commit, state }, { name, index, dirty }) {
        if (name === "FINANCE") {
            // FINANCE
            if (lodashSize(state.financeQuotes.data) > 0) {
                const financePrefs = lodashGet(state.vehicle, "data.prefs.financePreferences");
                if (financePrefs) {
                    const matcher = {
                        quote: {
                            downPayment: financePrefs.downPayment,
                            term: financePrefs.term,
                            financier: {
                                captiveLender: financePrefs.captiveFinancier,
                            },
                        },
                    };

                    const foundDeal = lodashFind(state.financeQuotes.data, matcher);

                    if (foundDeal) {
                        commit("SET_DEAL_JACKET", foundDeal);
                    } else {
                        // Finance prefs set but quote not found that matches
                        commit("SET_DEAL_JACKET", state.financeQuotes.data[0]);
                    }
                } else {
                    // No finance prefs so choose the first quote
                    commit("SET_DEAL_JACKET", state.financeQuotes.data[0]);
                }
            } else {
                // No finance quotes
                commit("SET_DEAL_JACKET", null);
            }
        } else if (name === "LEASE") {
            // LEASE
            if (lodashSize(state.leaseQuotes.data) > 0) {
                const leasePrefs = lodashGet(state.vehicle, "data.prefs.leasePreferences");
                if (leasePrefs) {
                    const matcher = {
                        quote: {
                            downPayment: leasePrefs.downPayment,
                            term: leasePrefs.term,
                            mileageAllowed: leasePrefs.mileage,
                        },
                    };

                    const foundDeal = lodashFind(state.leaseQuotes.data, matcher);

                    if (foundDeal) {
                        commit("SET_DEAL_JACKET", foundDeal);
                    } else {
                        // Lease prefs set but quote not found that matches
                        commit("SET_DEAL_JACKET", state.leaseQuotes.data[0]);
                    }
                } else {
                    // No lease preferences so choose the first quote
                    commit("SET_DEAL_JACKET", state.leaseQuotes.data[0]);
                }
            } else {
                // No lease quotes
                commit("SET_DEAL_JACKET", null);
            }
        } else {
            commit("SET_CASH_DEAL");
        }

        commit("SET_DIRTY", dirty);
        commit("SET_TAB_INDEX", index);
        commit("SET_DEAL_TYPE", name);
    },

    fetchVehicle({ commit, state }, dealId) {
        commit("SET_VEHICLE_LOADER", loader.started());
        return api
            .get(`/deals/${dealId}/vehicle`)
            .then((response) => {
                let leaseDownPayment = lodashGet(response.data, "prefs.leasePreferences.downPayment");
                let downPaymentVal = Number.isNaN(leaseDownPayment) ? 0 : leaseDownPayment;
                let financeDownPayment = lodashGet(response.data, "prefs.financePreferences.downPayment");
                let financePaymentVal = Number.isNaN(financeDownPayment) ? 0 : financeDownPayment;
                commit("SET_VEHICLE", response.data);
                commit("SET_REQUESTED_MILEAGE", lodashGet(response.data, "prefs.leasePreferences.mileage"));
                commit(
                    "SET_PREVIOUSLY_REQUESTED_MILEAGE_VALUE",
                    lodashGet(response.data, "prefs.leasePreferences.mileage")
                );
                commit("SET_REQUESTED_LEASE_DOWN_PAYMENT", downPaymentVal);
                commit("SET_PREVIOUSLY_REQUESTED_LEASE_DOWN_PAYMENT_VALUE");
                commit("SET_REQUESTED_FINANCE_DOWN_PAYMENT", financePaymentVal);
                commit(
                    "SET_SELECTED_FINANCIER_TYPE",
                    lodashGet(response.data, "prefs.financePreferences.financierType")
                );
                commit("SET_VEHICLE_LOADER", loader.successful());
            })
            .catch((error) => {
                console.error("fetchVehicle error: ", error);
                commit("SET_VEHICLE_LOADER", loader.error(error));
                return Promise.reject(error);
            });
    },

    fetchDeal({ commit, state }, dealId) {
        commit("SET_DEAL_LOADER", loader.started());

        return api
            .get(`/deals/${dealId}`)
            .then((response) => {
                const deal = response.data;

                const dealPrefs = lodashGet(state, "vehicle.data.prefs");
                const dealOptions = lodashGet(state, "vehicle.data.options");
                const quote = lodashGet(deal, "quote");
                const isLease = lodashGet(quote, "lease");
                const financeQuotesData = lodashSize(state.financeQuotes?.data || []);
                const isLeaseEnabled = lodashGet(dealOptions, "leaseEnabled");
                const leaseQuotesData = lodashSize(state.leaseQuotes?.data || []);
                let dealType;

                if (dealPrefs.dealType && !lodashIsNil(quote)) {
                    dealType = dealPrefs.dealType;
                } else {
                    if (quote) {
                        if (isLease) {
                            dealType = "LEASE";
                        } else {
                            dealType = "FINANCE";
                        }
                    } else {
                        dealType = "CASH";
                    }
                }

                commit("SET_DEAL", deal);
                commit("SET_DEAL_TYPE", dealType);
                commit("SET_DEAL_JACKET", deal);
                // Determine selected tab
                if (dealType === "LEASE") {
                    if (financeQuotesData > 0) {
                        // If finance tab is there, lease tab index will be 1 else 0
                        commit("SET_TAB_INDEX", 1);
                    } else {
                        commit("SET_TAB_INDEX", 0);
                    }
                } else if (dealType === "CASH") {
                    // financeQuotesData available = finance tab enabled
                    if (financeQuotesData > 0 && isLeaseEnabled && leaseQuotesData > 0) {
                        // If finance tab and lease tab both are there, cash tab index will be 2
                        commit("SET_TAB_INDEX", 2);
                    } else if (financeQuotesData > 0 || (isLeaseEnabled && leaseQuotesData > 0)) {
                        // If finance tab or lease tab is there (any one), cash tab index will be 1
                        commit("SET_TAB_INDEX", 1);
                    } else {
                        // If there is no finance tab or lease tab, cash tab index will be 0
                        commit("SET_TAB_INDEX", 0);
                    }
                    commit("SET_CASH_DEAL");
                } else {
                    // dealType could still be empty so checks if the quote stored in the deal is a lease
                    if (isLease && financeQuotesData > 0) {
                        commit("SET_TAB_INDEX", 1);
                    } else {
                        commit("SET_TAB_INDEX", 0);
                    }
                }

                commit("SET_DEAL_LOADER", loader.successful());
                checkComplete(state, commit);
                return lodashGet(response, "data");
            })
            .catch((error) => {
                console.error("fetchDeal error: ", error);
                commit("SET_DEAL_LOADER", loader.error(error));
                checkComplete(state, commit);
                return Promise.reject(error);
            });
    },

    fetchCashTab({ commit, state }, { certificateId }) {
        if (state.cash.loader.isLoading) {
            return;
        }

        commit("SET_CASH_LOADER", loader.started());

        return api
            .get(`/deals/${certificateId}/cash`)
            .then((response) => {
                const cashData = lodashGet(response, "data");

                commit("SET_CASH", cashData);

                if (state.dealType === "CASH") {
                    commit("SET_CASH_DEAL");
                }

                commit("SET_CASH_LOADER", loader.successful());

                checkComplete(state, commit);
            })
            .catch((error) => {
                console.error("fetchCashTab error: ", error);
                commit("SET_CASH_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },

    fetchFinanceQuotes({ commit, state }, { certificateId, isRecalculate }) {
        if (state.financeQuotes.loader.isLoading) {
            return;
        }

        commit("SET_FINANCE_QUOTES_LOADER", loader.started());

        return api
            .get(`/deals/${certificateId}/finance/quotes`, {
                downPayment: Math.round(state.requestedFinanceDownPayment),
            })
            .then((response) => {
                const quotes = lodashGet(response.data, "quotes");

                commit("SET_FINANCE_QUOTES", quotes);

                const currentDeal = lodashGet(state, "deal.data.quote");
                if (currentDeal && !lodashGet(currentDeal, "lease") && lodashSize(quotes) > 0) {
                    let newSelectedDeal = lodashFind(quotes, function (o) {
                        return (
                            lodashGet(o, "quote.term") === currentDeal.term &&
                            lodashGet(o, "quote.lenderNumber") === currentDeal.lenderNumber
                        );
                    });

                    if (lodashIsNil(newSelectedDeal)) {
                        newSelectedDeal = lodashHead(quotes);
                    }

                    if (isRecalculate) {
                        commit("SET_DIRTY", true);
                        const requestedDownPayment = lodashToNumber(state.requestedFinanceDownPayment);
                        const actualDownPayment = Math.round(lodashGet(newSelectedDeal, "quote.downPayment"));

                        commit("SET_PREVIOUS_REQUESTED_DOWN_PAYMENT", requestedDownPayment);

                        if (!isEqual(requestedDownPayment, actualDownPayment)) {
                            commit("SET_SHOW_DOWN_PAYMENT_CHANGE_MODAL", true);
                        }

                        if (requestedDownPayment < actualDownPayment) {
                            commit("SET_MIN_FINANCE_DOWN_PAYMENT", actualDownPayment);
                        }

                        commit("SET_REQUESTED_FINANCE_DOWN_PAYMENT", actualDownPayment);
                    }

                    commit("SET_DEAL_JACKET", newSelectedDeal);
                }

                commit("SET_FINANCE_QUOTES_LOADER", loader.successful());

                checkComplete(state, commit);

                return response;
            })
            .catch((error) => {
                console.error("fetchFinanceQuotes error: ", error);
                commit("SET_FINANCE_QUOTES_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },

    fetchLeaseQuotes({ commit, state }, { certificateId, mileage, isRecalculate }) {
        if (state.leaseQuotes.loader.isLoading) {
            return;
        }

        commit("SET_LEASE_QUOTES_LOADER", loader.started());

        return api
            .get(`/deals/${certificateId}/lease/quotes`, {
                downPayment: Math.round(state.requestedLeaseDownPayment),
                mileage,
            })
            .then((response) => {
                const quotes = lodashGet(response.data, "quotes");

                commit("SET_LEASE_QUOTES", quotes);

                let currentDeal = lodashGet(state, "deal.data.quote");

                // When miles reCalculate and return empty, the code will reverts back to previous selected miles
                // but before doing that it sets the whole deal to a CASH and deal.data.quote becomes null.
                // This creates a bug on the display side because there is no quote set
                // to display in the payments section. The fix will select the first quote from the reverted
                // reCalculate and display the monthly payment.
                if (
                    !lodashIsNil(isRecalculate) &&
                    isRecalculate &&
                    lodashIsNil(currentDeal) &&
                    lodashSize(quotes) > 0
                ) {
                    let headDeal = lodashHead(quotes);
                    currentDeal = lodashGet(headDeal, "quote");
                }

                if (currentDeal && lodashGet(currentDeal, "lease") && lodashSize(quotes) > 0) {
                    let newSelectedDeal = lodashFind(quotes, function (o) {
                        return (
                            lodashGet(o, "quote.term") === currentDeal.term &&
                            lodashGet(o, "quote.lenderNumber") === currentDeal.lenderNumber
                        );
                    });

                    if (lodashIsNil(newSelectedDeal)) {
                        newSelectedDeal = lodashHead(quotes);
                    }

                    if (isRecalculate) {
                        commit("SET_DIRTY", true);
                        const requestedDownPayment = lodashToNumber(state.requestedLeaseDownPayment);
                        const actualDownPayment = Math.round(lodashGet(newSelectedDeal, "quote.downPayment"));

                        commit("SET_PREVIOUS_REQUESTED_DOWN_PAYMENT", requestedDownPayment);

                        if (!isEqual(requestedDownPayment, actualDownPayment)) {
                            commit("SET_SHOW_DOWN_PAYMENT_CHANGE_MODAL", true);
                        }

                        if (requestedDownPayment < actualDownPayment) {
                            commit("SET_MIN_LEASE_DOWN_PAYMENT", actualDownPayment);
                        }
                    }

                    commit("SET_DEAL_JACKET", newSelectedDeal);
                    commit("SET_PREVIOUSLY_REQUESTED_MILEAGE_VALUE", mileage);
                    commit("SET_PREVIOUSLY_REQUESTED_LEASE_DOWN_PAYMENT_VALUE");
                }

                commit("SET_LEASE_QUOTES_LOADER", loader.successful());

                checkComplete(state, commit);

                return response;
            })
            .catch((error) => {
                console.error("fetchLeaseQuotes error: ", error);
                commit("SET_LEASE_QUOTES_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },

    setSelectedDealJacket({ commit, state }, selectedDealJacket) {
        commit("SET_DIRTY", true);
        commit("SET_DEAL_JACKET", selectedDealJacket);
    },

    /***
     * Called by editing deal name
     */
    saveDeal({ commit, state }, dealName) {
        const certificateId = lodashGet(state, "deal.data.dealId");

        const dealPreferencesDeepCopy = lodashGet(state.vehicle, "data.prefs", null);
        let dealPreferences = cloneDeep(dealPreferencesDeepCopy);

        if (lodashIsNil(certificateId)) {
            console.error("Unable to find a certificateId");
            return;
        }
        commit("SET_SAVED", true);
        commit("SET_DIRTY", false);

        let financePreferences = lodashGet(dealPreferences, "financePreferences");
        if (financePreferences) {
            let downPayment = lodashGet(financePreferences, "downPayment");
            if (isNumber(downPayment)) {
                lodashSet(dealPreferences, "financePreferences.downPayment", Math.round(downPayment));
            }
        }

        const oldDealName = lodashGet(state, "deal.data.dealName");
        commit("SET_DEAL_NAME", dealName);

        return api
            .post(`/deals/${certificateId}/update`, {
                updateQuote: true,
                dealName,
                dealPreferences,
                sendLead: true,
            })
            .then(() => {
                // updates deal count in context
                dispatch("context/fetchSavedDealCount");
            })
            .catch((error) => {
                commit("SET_DEAL_NAME", oldDealName);
                console.error("saveDeal error: ", error);
                commit("SET_SAVED", false);

                // rethrow exception in case the caller wants to handle it
                throw new Error(error);
            });
    },

    /***
     * Called by save button
     */
    updateDeal({ commit, state }) {
        const certificateId = lodashGet(state, "deal.data.dealId");

        const dealPreferencesDeepCopy = lodashGet(state.vehicle, "data.prefs", null);
        let dealPreferences = cloneDeep(dealPreferencesDeepCopy);

        if (lodashIsNil(certificateId)) {
            console.error("Unable to find a certificateId");
            return;
        }

        // Optimistic Update
        commit("SET_SAVED", true);
        commit("SET_DIRTY", false);

        let financePreferences = lodashGet(dealPreferences, "financePreferences");
        if (financePreferences) {
            let downPayment = lodashGet(financePreferences, "downPayment");
            if (isNumber(downPayment)) {
                lodashSet(dealPreferences, "financePreferences.downPayment", Math.round(downPayment));
            }
        }

        const dealName = lodashGet(state.deal, "data.dealName", null);

        return api
            .post(`/deals/${certificateId}/update`, {
                updateQuote: true, // This is now always required (garage now uses the quote from the save)
                dealPreferences,
                dealName,
            })
            .then(() => {
                // updates deal count in context
                dispatch("context/fetchSavedDealCount");
            })
            .catch((error) => {
                console.error("Deal updateDeal error: ", error);
                commit("SET_SAVED", false);
                commit("SET_DIRTY", true);

                // rethrow exception in case the caller wants to handle it
                throw new Error(error);
            });
    },

    fetchExternalOffers({ commit, state }, { dealId }) {
        commit("SET_EXTERNAL_OFFERS_LOADER", loader.started());

        return api
            .get(`/deals/${dealId}/external-offers`)
            .then((response) => {
                commit("SET_EXTERNAL_OFFERS", response.data);
                commit("SET_EXTERNAL_OFFERS_LOADER", loader.successful());
            })
            .catch((error) => {
                console.error("fetchExternalOffers error: ", error);
                commit("SET_EXTERNAL_OFFERS_LOADER", loader.error(error));
                return Promise.reject(error);
            });
    },
    updateExternalOffers({ commit, state }, { dealId }) {
        commit("SET_EXTERNAL_OFFERS_LOADER", loader.started());
        const providerType = "NISSAN";

        // Optimistic Update
        commit("SET_DIRTY", true);

        let lineItems = lodashGet(state, "externalOffers.data.lineItems", []);
        // filter out invalid lineItems, each lineItem should have an amount, other
        lineItems = filter(lineItems, (lineItem) => {
            return !lodashIsNil(lineItem) && !lodashIsNil(lineItem.amount) && lineItem.amount > 0;
        });

        const externalOffers = { providerType, lineItems };
        return api
            .post(`/deals/${dealId}/external-offers`, externalOffers)
            .then((response) => {
                commit("SET_EXTERNAL_OFFERS", response.data);
                commit("SET_EXTERNAL_OFFERS_LOADER", loader.successful());
            })
            .catch((error) => {
                console.error("updateExternalOffers error: ", error);
                commit("SET_EXTERNAL_OFFERS_LOADER", loader.error(error));
                commit("SET_SAVED", false);
                commit("SET_DIRTY", true);

                return Promise.reject(error);
            });
    },
    addOrReplaceExternalOffersLineItem({ commit, state }, lineItem) {
        commit("ADD_OR_REPLACE_EXTERNAL_OFFERS_LINE_ITEM", lineItem);
    },
    removeExternalOffersLineItemsByType({ commit, state }, type) {
        commit("REMOVE_EXTERNAL_OFFERS_LINE_ITEMS_BY_TYPE", type);
    },

    fetchUserCreditProfileEvaluator({ commit }) {
        return api
            .get("/soft-pull/user/credit-profile/evaluator")
            .then((response) => {
                const isSelfSelected = response.data === "CUSTOMER";
                commit("SET_CREDIT_PROFILE_EVALUATOR", response.data);
                commit("SET_CREDIT_PROFILE_SELF_SELECTED", isSelfSelected);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    commit("SET_CREDIT_PROFILE_EVALUATOR", null);
                    commit("SET_CREDIT_PROFILE_SELF_SELECTED", false);
                } else {
                    console.error(error);
                }
            });
    },

    isPreApprovalCheck({ commit }, certificateId) {
        return api
            .get(`/deal/${certificateId}/applied-pre-approval`)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
                return false;
            });
    },
    fetchPreApprovals({ commit }) {
        commit("SET_PRE_APPROVALS_LOADER", loader.started());
        return api
            .get("/pre-approvals")
            .then((response) => {
                commit("SET_PRE_APPROVALS", isEmpty(response.data) ? null : response.data);
                commit("SET_PRE_APPROVALS_LOADER", loader.successful());
                const appliedPreApproval = lodashGet(response.data, "preApproval");
                console.log("preapproval = " + appliedPreApproval);
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    commit("SET_PRE_APPROVALS", null);
                    commit("SET_PRE_APPROVALS_LOADER", false);
                } else {
                    console.error(error);
                }

                commit("SET_PRE_APPROVALS_LOADER", loader.error(error));
            });
    },
    fetchPreApprovalFinanceQuotes({ commit, state }, { certificateId, isRecalculate, downPaymentVal }) {
        if (state.financeQuotes.loader.isLoading) {
            return;
        }
        commit("SET_FINANCE_QUOTES_LOADER", loader.started());

        return api
            .get(`/deals/${certificateId}/finance/quotes`, {
                downPayment: Math.round(downPaymentVal),
            })
            .then((response) => {
                const quotesResponse = lodashGet(response.data, "quotes");

                commit("SET_FINANCE_QUOTES", quotesResponse);
                const currentDealQuote = lodashGet(state, "deal.data.quote");
                if (currentDealQuote && !lodashGet(currentDealQuote, "lease") && lodashSize(quotesResponse) > 0) {
                    let newSelectedDealFromResponse = lodashFind(quotesResponse, function (o) {
                        return (
                            lodashGet(o, "quote.term") === currentDealQuote.term &&
                            lodashGet(o, "quote.lenderNumber") === currentDealQuote.lenderNumber
                        );
                    });

                    if (lodashIsNil(newSelectedDealFromResponse)) {
                        newSelectedDealFromResponse = lodashHead(quotesResponse);
                    }
                    if (isRecalculate) {
                        commit("SET_DIRTY", true);
                        const requestedPreApprovalDownPayment = lodashToNumber(downPaymentVal);
                        const actualDownPaymentFromQuote = Math.round(
                            lodashGet(newSelectedDealFromResponse, "quote.downPayment")
                        );
                        commit("SET_PREVIOUS_REQUESTED_DOWN_PAYMENT", requestedPreApprovalDownPayment);
                        if (!isEqual(requestedPreApprovalDownPayment, actualDownPaymentFromQuote)) {
                            commit("SET_SHOW_DOWN_PAYMENT_CHANGE_MODAL", true);
                        }
                        if (requestedPreApprovalDownPayment < actualDownPaymentFromQuote) {
                            commit("SET_MIN_FINANCE_DOWN_PAYMENT", actualDownPaymentFromQuote);
                        }
                        commit("SET_REQUESTED_FINANCE_DOWN_PAYMENT", actualDownPaymentFromQuote);
                    }
                    commit("SET_DEAL_JACKET", newSelectedDealFromResponse);
                }
                commit("SET_FINANCE_QUOTES_LOADER", loader.successful());
                checkComplete(state, commit);
                return response;
            })
            .catch((error) => {
                console.error("fetchFinanceQuotes error: ", error);
                commit("SET_FINANCE_QUOTES_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },
    fetchPreApprovalLeaseQuotes({ commit, state }, { certificateId, mileage, isRecalculate, downPaymentVal }) {
        if (state.leaseQuotes.loader.isLoading) {
            return;
        }

        commit("SET_LEASE_QUOTES_LOADER", loader.started());
        return api
            .get(`/deals/${certificateId}/lease/quotes`, {
                downPayment: Math.round(downPaymentVal),
                mileage,
            })
            .then((response) => {
                const responseQuotes = lodashGet(response.data, "quotes");
                commit("SET_LEASE_QUOTES", responseQuotes);

                const currentStateDeal = lodashGet(state, "deal.data.quote");
                if (currentStateDeal && lodashGet(currentStateDeal, "lease") && lodashSize(responseQuotes) > 0) {
                    let newDealSelected = lodashFind(responseQuotes, function (o) {
                        return (
                            lodashGet(o, "quote.term") === currentStateDeal.term &&
                            lodashGet(o, "quote.lenderNumber") === currentStateDeal.lenderNumber
                        );
                    });

                    if (lodashIsNil(newDealSelected)) {
                        newDealSelected = lodashHead(responseQuotes);
                    }

                    if (isRecalculate) {
                        commit("SET_DIRTY", true);
                        const requestedPreApprovedDownPayment = lodashToNumber(downPaymentVal);
                        const actualQuoteDownPayment = Math.round(lodashGet(newDealSelected, "quote.downPayment"));
                        commit("SET_PREVIOUS_REQUESTED_DOWN_PAYMENT", requestedPreApprovedDownPayment);
                        if (!isEqual(requestedPreApprovedDownPayment, actualQuoteDownPayment)) {
                            commit("SET_SHOW_DOWN_PAYMENT_CHANGE_MODAL", true);
                        }
                        if (requestedPreApprovedDownPayment < actualQuoteDownPayment) {
                            commit("SET_MIN_LEASE_DOWN_PAYMENT", actualQuoteDownPayment);
                        }
                    }

                    commit("SET_DEAL_JACKET", newDealSelected);
                    commit("SET_PREVIOUSLY_REQUESTED_MILEAGE_VALUE", mileage);
                    commit("SET_PREVIOUSLY_REQUESTED_LEASE_DOWN_PAYMENT_VALUE");
                }

                commit("SET_LEASE_QUOTES_LOADER", loader.successful());

                checkComplete(state, commit);

                return response;
            })
            .catch((error) => {
                console.error("fetchLeaseQuotes error: ", error);
                commit("SET_LEASE_QUOTES_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },

    fetchAccessoriesSelected({ commit, state }, certificateId) {
        return api
            .get(`/deals/${certificateId}/savedAccessories`)
            .then((response) => {
                commit("SET_ACCESSORIES", response.data);
            })
            .catch((error) => {
                commit("SET_ACCESSORIES", null);
                console.error(error);
            });
    },

    fetchDriveAtHomeEnabled({ commit }, dealerId) {
        commit("SET_DRIVE_AT_HOME_ENABLED_LOADER", loader.started());

        return api
            .get(`/drive-at-home/dealer-in-program/${dealerId}`)
            .then((response) => {
                commit("SET_DRIVE_AT_HOME_ENABLED", response.data);
                commit("SET_DRIVE_AT_HOME_ENABLED_LOADER", loader.successful());
            })
            .catch((error) => {
                console.log("error", error);
                commit("SET_DRIVE_AT_HOME_ENABLED", false);
                commit("SET_DRIVE_AT_HOME_ENABLED_LOADER", loader.error());
            });
    },

    fetchRouteOneProtectionProductsFeatureEnabled({ commit }, dealerId) {
        commit("SET_ROUTE_ONE_PROTECTION_PRODUCTS_FEATURE_ENABLED_LOADER", loader.started());

        const result = api
            .get(`/protection-products/dealer/${dealerId}`)
            .then((response) => {
                commit("SET_ROUTE_ONE_PROTECTION_PRODUCTS_FEATURE_ENABLED", response.data);
                commit("SET_ROUTE_ONE_PROTECTION_PRODUCTS_FEATURE_ENABLED_LOADER", loader.successful());
            })
            .catch((error) => {
                console.error("error= ", error);
                commit("SET_ROUTE_ONE_PROTECTION_PRODUCTS_FEATURE_ENABLED_LOADER", loader.error(error));
            });

        return result;
    },

    fetchDealProtectionProducts({ commit, state }, certificateId) {
        commit("SET_PROTECTION_PRODUCTS_LOADER", loader.started());

        const result = api
            .get(`/protection-products/deals/${certificateId}`)
            .then((response) => {
                if (response.data) {
                    commit("SET_ROUTE_ONE_DEAL_JACKET", response.data);
                    commit("SET_PROTECTION_PRODUCTS", response.data.insuranceWarranties);
                    commit("SET_PROTECTION_PRODUCTS_LOADER", loader.successful());
                    checkProtectionProduct(state, commit, response.data.insuranceWarranties);
                }
            })
            .catch((error) => {
                commit("SET_PROTECTION_PRODUCTS", null);
                commit("SET_PROTECTION_PRODUCTS_LOADER", loader.error(error));
                commit("SET_ROUTE_ONE_DEAL_JACKET", null);
                checkProtectionProduct(state, commit, null);
                console.error(error);
            });

        return result;
    },

    updateRouteOneDealProtectionProducts({ commit, state }, { routeOneDealJacket }) {
        commit("SET_PROTECTION_PRODUCTS_LOADER", loader.started());

        const result = api
            .put(`/protection-products/update`, routeOneDealJacket)
            .then((response) => {
                if (response.data) {
                    commit("SET_ROUTE_ONE_DEAL_JACKET", response.data.customerAndDealData);
                    commit("SET_PROTECTION_PRODUCTS", response.data.customerAndDealData.insuranceWarranties);
                    checkProtectionProduct(state, commit, response.data.customerAndDealData.insuranceWarranties);
                    commit("SET_PROTECTION_PRODUCTS_LOADER", loader.successful());
                }
            })
            .catch((error) => {
                commit("SET_ROUTE_ONE_DEAL_JACKET", null);
                commit("SET_PROTECTION_PRODUCTS", null);
                commit("SET_PROTECTION_PRODUCTS_LOADER", loader.error(error));
                checkProtectionProduct(state, commit, null);
                console.error(error);
            });

        return result;
    },

    fetchFinanceQuotesWithProtectedProduct({ commit, state }, { certificateId, isRecalculate, routeOneDealJacket }) {
        if (state.financeQuotes.loader.isLoading) {
            return;
        }
        commit("SET_FINANCE_QUOTES_LOADER", loader.started());

        return api
            .post(`/deals/${certificateId}/finance/quotes`, {
                downPayment: Math.round(state.requestedFinanceDownPayment),
                protectionProducts: routeOneDealJacket,
            })
            .then((response) => {
                const quotes = lodashGet(response.data, "quotes");

                commit("SET_FINANCE_QUOTES", quotes);

                const currentDeal = lodashGet(state, "deal.data.quote");
                if (currentDeal && !lodashGet(currentDeal, "lease") && lodashSize(quotes) > 0) {
                    let newSelectedDeal = lodashFind(quotes, function (o) {
                        return (
                            lodashGet(o, "quote.term") === currentDeal.term &&
                            lodashGet(o, "quote.lenderNumber") === currentDeal.lenderNumber
                        );
                    });

                    if (lodashIsNil(newSelectedDeal)) {
                        newSelectedDeal = lodashHead(quotes);
                    }

                    if (isRecalculate) {
                        commit("SET_DIRTY", true);
                        const requestedDownPayment = lodashToNumber(state.requestedFinanceDownPayment);
                        const actualDownPayment = Math.round(lodashGet(newSelectedDeal, "quote.downPayment"));

                        commit("SET_PREVIOUS_REQUESTED_DOWN_PAYMENT", requestedDownPayment);

                        if (!isEqual(requestedDownPayment, actualDownPayment)) {
                            commit("SET_SHOW_DOWN_PAYMENT_CHANGE_MODAL", true);
                        }

                        if (requestedDownPayment < actualDownPayment) {
                            commit("SET_MIN_FINANCE_DOWN_PAYMENT", actualDownPayment);
                        }

                        commit("SET_REQUESTED_FINANCE_DOWN_PAYMENT", actualDownPayment);
                    }

                    if (state.dealType === "FINANCE") {
                        commit("SET_DEAL_JACKET", newSelectedDeal);
                        checkProtectionProduct(state, commit, routeOneDealJacket.insuranceWarranties);
                    }
                }

                commit("SET_FINANCE_QUOTES_LOADER", loader.successful());

                checkComplete(state, commit);

                return response;
            })
            .catch((error) => {
                console.error("fetchFinanceQuotes error: ", error);
                commit("SET_FINANCE_QUOTES_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },

    fetchLeaseQuotesWithProtectedProduct(
        { commit, state },
        { certificateId, mileage, isRecalculate, routeOneDealJacket }
    ) {
        if (state.leaseQuotes.loader.isLoading) {
            return;
        }

        commit("SET_LEASE_QUOTES_LOADER", loader.started());
        return api
            .post(`/deals/${certificateId}/lease/quotes`, {
                downPayment: Math.round(state.requestedLeaseDownPayment),
                mileage: mileage,
                protectionProducts: routeOneDealJacket,
            })
            .then((response) => {
                const quotes = lodashGet(response.data, "quotes");

                commit("SET_LEASE_QUOTES", quotes);

                let currentDeal = lodashGet(state, "deal.data.quote");

                // When miles reCalculate and return empty, the code will reverts back to previous selected miles
                // but before doing that it sets the whole deal to a CASH and deal.data.quote becomes null.
                // This creates a bug on the display side because there is no quote set
                // to display in the payments section. The fix will select the first quote from the reverted
                // reCalculate and display the monthly payment.
                if (
                    !lodashIsNil(isRecalculate) &&
                    isRecalculate &&
                    lodashIsNil(currentDeal) &&
                    lodashSize(quotes) > 0
                ) {
                    let headDeal = lodashHead(quotes);
                    currentDeal = lodashGet(headDeal, "quote");
                }

                if (currentDeal && lodashGet(currentDeal, "lease") && lodashSize(quotes) > 0) {
                    let newSelectedDeal = lodashFind(quotes, function (o) {
                        return (
                            lodashGet(o, "quote.term") === currentDeal.term &&
                            lodashGet(o, "quote.lenderNumber") === currentDeal.lenderNumber
                        );
                    });

                    if (lodashIsNil(newSelectedDeal)) {
                        newSelectedDeal = lodashHead(quotes);
                    }

                    if (isRecalculate) {
                        commit("SET_DIRTY", true);
                        const requestedDownPayment = lodashToNumber(state.requestedLeaseDownPayment);
                        const actualDownPayment = Math.round(lodashGet(newSelectedDeal, "quote.downPayment"));

                        commit("SET_PREVIOUS_REQUESTED_DOWN_PAYMENT", requestedDownPayment);

                        if (!isEqual(requestedDownPayment, actualDownPayment)) {
                            commit("SET_SHOW_DOWN_PAYMENT_CHANGE_MODAL", true);
                        }

                        if (requestedDownPayment < actualDownPayment) {
                            commit("SET_MIN_LEASE_DOWN_PAYMENT", actualDownPayment);
                        }
                    }

                    if (state.dealType === "LEASE") {
                        commit("SET_DEAL_JACKET", newSelectedDeal);
                        commit("SET_PREVIOUSLY_REQUESTED_MILEAGE_VALUE", mileage);
                        commit("SET_PREVIOUSLY_REQUESTED_LEASE_DOWN_PAYMENT_VALUE");
                        checkProtectionProduct(state, commit, routeOneDealJacket.insuranceWarranties);
                    }
                }

                commit("SET_LEASE_QUOTES_LOADER", loader.successful());

                checkComplete(state, commit);

                return response;
            })
            .catch((error) => {
                console.error("fetchLeaseQuotes error: ", error);
                commit("SET_LEASE_QUOTES_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },

    fetchCashDealWithProtectionProducts({ commit, state }, { certificateId, routeOneDealJacket }) {
        if (state.cash.loader.isLoading) {
            return;
        }
        commit("SET_CASH_LOADER", loader.started());

        return api
            .post(`/deals/${certificateId}/cash`, {
                protectionProducts: routeOneDealJacket,
            })
            .then((response) => {
                const cashData = lodashGet(response, "data");

                commit("SET_CASH", cashData);

                if (state.dealType === "CASH") {
                    commit("SET_CASH_DEAL");
                    checkProtectionProduct(state, commit, routeOneDealJacket.insuranceWarranties);
                }

                commit("SET_CASH_LOADER", loader.successful());

                checkComplete(state, commit);
            })
            .catch((error) => {
                console.error("fetchCashTab error: ", error);
                commit("SET_CASH_LOADER", loader.error(error));

                checkComplete(state, commit);
            });
    },

    fetchUserCreditInfo({ commit }) {
        commit("SET_USER_CREDIT_INFO_LOADER", loader.started());
        return api
            .get("/soft-pull/credit-score")
            .then((response) => {
                commit("SET_USER_CREDIT_INFO", response.data);
                commit("SET_USER_CREDIT_SCORE", response.data.creditScore);
                commit("SET_USER_CREDIT_INFO_LOADER", loader.successful());
            })
            .catch((error) => {
                commit("SET_USER_CREDIT_INFO_LOADER", loader.error(error));
                if (error.response.status === 404) {
                    commit("SET_USER_CREDIT_INFO", null);
                } else {
                    console.error(error);
                }
            });
    },

    logFEMessage({ commit, state }, message) {
        const result = api
            .post("/log-fe-message", { message })
            .then((response) => {
                // nothing to do
            })
            .catch((error) => {
                console.error("logFEMessage error: ", error);
            });

        return result;
    },
};

const mutations = {
    ...make.mutations(state),

    SET_DEAL_NAME: (state, payload) => {
        state.deal.data.dealName = payload;
    },

    SET_SAVED: (state, payload) => {
        state.deal.data.saved = payload;
    },

    SET_CASH: (state, payload) => {
        state.cash.data = payload;
    },

    SET_CASH_LOADER: (state, payload) => {
        state.cash.loader = payload;
    },

    SET_VEHICLE: (state, payload) => {
        state.vehicle.data = payload;
    },

    SET_VEHICLE_LOADER: (state, payload) => {
        state.vehicle.loader = payload;
    },

    SET_DEAL: (state, payload) => {
        state.deal.data = payload;
    },

    SET_DEAL_LOADER: (state, payload) => {
        state.deal.loader = payload;
    },

    SET_FINANCE_QUOTES: (state, payload) => {
        state.financeQuotes.data = payload;
    },

    SET_FINANCE_QUOTES_LOADER: (state, payload) => {
        state.financeQuotes.loader = payload;
    },

    SET_LEASE_QUOTES: (state, payload) => {
        state.leaseQuotes.data = payload;
    },

    SET_LEASE_QUOTES_LOADER: (state, payload) => {
        state.leaseQuotes.loader = payload;
    },

    SET_CASH_DEAL: (state) => {
        const tradeDifference = lodashGet(state.deal, "data.trade.netCashOffer") || 0;

        if (state.deal.data === null) {
            lodashSet(state, "deal.data", {});
        }

        lodashSet(state, "deal.data.quote", null);
        lodashSet(state, "deal.data.pricing", {
            ...lodashOmit(state.cash.data, ["outTheDoor"]),
            outTheDoor: lodashGet(state.cash, "data.outTheDoor") - tradeDifference,
        });
        lodashSet(state, "deal.data.features", {
            contractingEnabled: false,
            financeAppEnabled: false,
        });
        lodashSet(state.vehicle, "data.prefs.dealType", "CASH");
        state.dealType = "CASH";

        if (lodashGet(state, "vehicle.data.options.leaseEnabled")) {
            state.rebatesTabIndex = 2;
        } else {
            state.rebatesTabIndex = 1;
        }
    },

    SET_DEAL_JACKET: (state, payload) => {
        const downPayment = lodashGet(payload, "quote.downPayment");
        const lease = lodashGet(payload, "quote.lease");

        if (lease) {
            state.requestedLeaseDownPayment = downPayment;
            state.dealType = "LEASE";
        } else if (downPayment) {
            state.requestedFinanceDownPayment = downPayment;
            state.dealType = "FINANCE";
        }

        if (!lodashIsNil(payload)) {
            lodashSet(state, "deal.data.quote", payload.quote);
            lodashSet(state, "deal.data.pricing", payload.pricing);
            lodashSet(state, "deal.data.features", payload.features);
            lodashSet(state, "deal.data.offerDetails", payload.offerDetails);

            const captiveLender = lodashGet(payload, "quote.financier.captiveLender");

            if (!lodashIsNil(captiveLender)) {
                const financierType = captiveLender ? "CAPTIVE" : "NON_CAPTIVE";
                lodashSet(state, "savedFinancierType", financierType);

                const currentCaptiveLender = lodashGet(state, "selectedFinancierType");
                // UP-2185 need to prevent the selectedFinancierType to change when ALL is currently selected
                if (currentCaptiveLender === "ALL") {
                    lodashSet(state, "selectedFinancierType", "ALL");
                } else {
                    lodashSet(state, "selectedFinancierType", financierType);
                }
            }
        }

        lodashSet(state.vehicle, "data.prefs.dealType", lease ? "LEASE" : "FINANCE");
        let appliedPreApproval = null;
        if (lease) {
            const existingExternalOffers = lodashGet(state.vehicle, "data.prefs.leasePreferences.externalOffers", null);
            const newLeasePreferences = {
                downPayment: lodashGet(payload, "quote.downPayment"),
                term: lodashGet(payload, "quote.term"),
                mileage: lodashGet(payload, "quote.mileageAllowed"),
                externalOffers: existingExternalOffers,
            };
            lodashSet(state.vehicle, "data.prefs.leasePreferences", newLeasePreferences);
            state.rebatesTabIndex = 1;
            appliedPreApproval = lodashGet(state, "preApprovals.data.preApproval");
        } else {
            const captiveFinancier = lodashGet(payload, "quote.financier.captiveLender") || false;
            appliedPreApproval = captiveFinancier === true ? lodashGet(state, "preApprovals.data.preApproval") : null;

            const existingExternalOffers = lodashGet(
                state.vehicle,
                "data.prefs.financePreferences.externalOffers",
                null
            );
            const newFinancePreferences = {
                financierType: captiveFinancier ? "CAPTIVE" : "NON_CAPTIVE",
                captiveFinancier: captiveFinancier,
                downPayment: lodashGet(payload, "quote.downPayment"),
                term: lodashGet(payload, "quote.term"),
                externalOffers: existingExternalOffers,
            };

            lodashSet(state.vehicle, "data.prefs.financePreferences", newFinancePreferences);

            state.rebatesTabIndex = captiveFinancier ? 0 : 2;
        }

        lodashSet(state.vehicle, "data.prefs.appliedPreApproval", appliedPreApproval);
    },

    SET_DOWN_PAYMENT_CHANGE_STATUS: (state, payload) => {
        state.downPaymentChangeStatus = payload;
    },

    SET_REQUESTED_MILEAGE: (state, payload) => {
        const valueChanged = state.requestedMileage != payload;

        if (valueChanged && !lodashIsNil(payload)) {
            state.requestedMileage = payload;
        }
    },

    SET_PREVIOUSLY_REQUESTED_MILEAGE_VALUE: (state, payload) => {
        if (!lodashIsNil(payload)) {
            state.previouslyRequestedMileage = payload;
            state.requestedMileage = payload;
        }
    },

    SET_PREVIOUSLY_REQUESTED_LEASE_DOWN_PAYMENT_VALUE: (state) => {
        const valueChanged = state.previouslyRequestedLeaseDownPayment != state.requestedLeaseDownPayment;
        if (valueChanged) {
            state.previouslyRequestedLeaseDownPayment = state.requestedLeaseDownPayment;
        }
    },

    SET_EXTERNAL_OFFERS_LOADER: (state, payload) => {
        state.externalOffers.loader = payload;
    },

    SET_EXTERNAL_OFFERS: (state, payload) => {
        if (lodashIsNil(payload) || isEmpty(payload.lineItems)) {
            state.externalOffers.data = { providerType: "NISSAN", lineItems: [] };
        } else {
            state.externalOffers.data = payload;
        }
    },

    ADD_OR_REPLACE_EXTERNAL_OFFERS_LINE_ITEM: (state, payload) => {
        if (payload) {
            const lineItems = lodashGet(state, "externalOffers.data.lineItems", []);
            const idxToRemove = lodashFindIndex(lineItems, (lineItem) => {
                const listItemType = lodashGet(lineItem, "type", null);
                return listItemType === payload.type;
            });

            if (!lodashIsNil(idxToRemove)) {
                Vue.delete(lineItems, idxToRemove);
            }

            state.externalOffers.data.lineItems.push(payload);

            let description = payload.description
                ? `External Offers - Adding ${payload.description} Rebate - Click`
                : `External Offers - Collapse Toggle ${payload.type} Rebate - Click`;

            Vue.gtm.trackEvent({
                action: "click",
                category: "External Offers",
                event: "mixpanel",
                name: description,
                value: payload,
            });
        }
    },

    REMOVE_EXTERNAL_OFFERS_LINE_ITEMS_BY_TYPE: (state, type) => {
        const lineItems = lodashGet(state, "externalOffers.data.lineItems", []);
        const idxToRemove = lodashFindIndex(lineItems, (lineItem) => {
            const listItemType = lodashGet(lineItem, "type", null);
            return listItemType === type;
        });

        if (!lodashIsNil(idxToRemove)) {
            Vue.delete(lineItems, idxToRemove);

            Vue.gtm.trackEvent({
                action: "click",
                category: "External Offers",
                event: "mixpanel",
                name: `External Offers - Removing ${type} Rebate`,
                value: type,
            });
        }
    },

    SET_PRE_APPROVALS: (state, payload) => {
        state.preApprovals.data = payload;
    },

    SET_PRE_APPROVALS_LOADER: (state, payload) => {
        state.preApprovals.loader = payload;
    },

    SET_ACCESSORIES: (state, payload) => {
        state.accessories.data = payload;
    },

    SET_DRIVE_AT_HOME_ENABLED: (state, payload) => {
        state.driveAtHomeEnabled.data = payload;
    },

    SET_DRIVE_AT_HOME_ENABLED_LOADER: (state, payload) => {
        state.driveAtHomeEnabled.loader = payload;
    },

    SET_ROUTE_ONE_PROTECTION_PRODUCTS_FEATURE_ENABLED: (state, payload) => {
        state.routeOneProtectionProductsFeatureEnabled.data = payload;
    },

    SET_ROUTE_ONE_PROTECTION_PRODUCTS_FEATURE_ENABLED_LOADER: (state, payload) => {
        state.routeOneProtectionProductsFeatureEnabled.loader = payload;
    },

    SET_PROTECTION_PRODUCTS: (state, payload) => {
        state.protectionProducts.data = payload;
    },

    SET_PROTECTION_PRODUCTS_LOADER: (state, payload) => {
        state.protectionProducts.loader = payload;
    },
    SET_ROUTE_ONE_DEAL_JACKET: (state, payload) => {
        state.routeOneDealJacket.data = payload;
    },
    SET_PROTECTION_PRODUCTS_SELECTED: (state, payload) => {
        state.protectionProductSelected = payload;
    },
    SET_USER_CREDIT_INFO_LOADER: (state, payload) => {
        state.userCreditInfo.loader = payload;
    },
    SET_USER_CREDIT_INFO: (state, payload) => {
        state.userCreditInfo.data = payload;
    },
    SET_SELECTED_CREDIT_RANGE_LOADER: (state, payload) => {
        state.selectedCreditRange.loader = payload;
    },
    SET_SELECTED_CREDIT_RANGE: (state, payload) => {
        state.selectedCreditRange.data = payload;
    },
};

const getters = {
    ...make.getters(state),

    isCashDeal: (state) => {
        return lodashGet(state.vehicle, "data.prefs.dealType") === "CASH";
    },

    isLeaseDeal: (state) => {
        return lodashGet(state.vehicle, "data.prefs.dealType") === "LEASE";
    },

    isFinanceDeal: (state) => {
        return lodashGet(state.vehicle, "data.prefs.dealType") === "FINANCE";
    },

    isNewStockType: (state) => {
        const stockType = lodashGet(state, "vehicle.data.stockType");
        return "NEW" === stockType;
    },

    getAddedAndRemovedExternalOfferLineItems: (state) => {
        const changeSummary = {
            priceDifference: 0,
            priceDifferenceLabel: null,
            ADDED: [],
            UPDATED: [],
            REMOVED: [],
        };
        const currentLineItems = lodashGet(state, "externalOffers.data.lineItems", []);
        const savedLineItems = lodashGet(state, "deal.data.pricing.externalOffers.lineItems", []);

        each(currentLineItems, (currentLineItem) => {
            if (
                !lodashFind(
                    savedLineItems,
                    (savedLineItem) => savedLineItem.description === currentLineItem.description
                )
            ) {
                if (currentLineItem.amount > 0) {
                    changeSummary.ADDED.push(currentLineItem);
                }
            }
        });

        each(currentLineItems, (currentLineItem) => {
            if (
                lodashFind(
                    savedLineItems,
                    (savedLineItem) =>
                        savedLineItem.description === currentLineItem.description &&
                        currentLineItem.amount > 0 &&
                        (currentLineItem.amount > savedLineItem.amount || currentLineItem.amount < savedLineItem.amount)
                )
            ) {
                changeSummary.UPDATED.push(currentLineItem);
            }
        });

        each(savedLineItems, (savedLineItem) => {
            if (
                !lodashFind(
                    currentLineItems,
                    (currentLineItem) => currentLineItem.description === savedLineItem.description
                )
            ) {
                changeSummary.REMOVED.push(savedLineItem);
            }
        });

        const sumOfCurrentLineItems = sumBy(currentLineItems, (currentLineItem) => currentLineItem.amount);
        const sumOfSelectedLineItems = sumBy(savedLineItems, (saveLineItem) => saveLineItem.amount);

        changeSummary.priceDifference = sumOfSelectedLineItems - sumOfCurrentLineItems;
        changeSummary.priceDifferenceLabel = changeSummary.priceDifference < 0 ? "lower" : "higher";

        return changeSummary;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
