import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import VueMq from "vue-mq";
import Vuex from "vuex";
import BootstrapVue from "bootstrap-vue";
import LoadScript from "vue-plugin-load-script";
import pageConfig from "@/mixins/pageConfig";
import vueMoment from "vue-moment";
import moment from "moment-timezone";
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from "vee-validate";
import { digits, regex, email, min, max, confirmed, integer, length, numeric } from "vee-validate/dist/rules";
import get from "lodash/get";
import isNil from "lodash/isNil";
import numeral from "numeral";

class UpgradePlugin {
    install(Vue) {
        const environment = get(window, "_APP_CONFIG.env[0]", "local") || "local";
        const userId = get(window, "_CONTEXT.userId", null);

        const checkPassword = (value) => {
            if (!value) return false; // Handle empty input

            const hasNumber = /\d/.test(value);
            const hasLower = /[a-z]/.test(value);
            const hasUpper = /[A-Z]/.test(value);
            const hasSpecialChar = /[!@#-$%^&*()_+~=`{}\[\]:";'<>?,.]/.test(value);

            const requiredNumberOfCriteriaMet = 3;

            // Count how many criteria are met
            const criteriaMet = [hasNumber, hasLower, hasUpper, hasSpecialChar].filter(Boolean).length;

            return criteriaMet >= requiredNumberOfCriteriaMet;
        };

        Bugsnag.start({
            apiKey: "1ff5d7199758b58104abcc874ac3ea9c",
            plugins: [new BugsnagPluginVue(Vue)],
            releaseStage: environment,
            enabledReleaseStages: ["prod", "beta", "dev"],
            user: {
                id: userId,
                loggedIn: !isNil(userId),
                name: get(window, "_CONTEXT.firstName", "") + " " + get(window, "_CONTEXT.lastName", ""),
                email: get(window, "_CONTEXT.email", null),
            },
            campaign: {
                productId: get(window, "_CAMPAIGN.productId", null),
                campaignName: get(window, "_CAMPAIGN.name", null),
            },
            context: {
                dealerId: get(window, "_CONTEXT.dealerId", null),
                vehicleOfInterestId: get(window, "_VEHICLE_OF_INTEREST_ID", null),
            },
            onError: function (event) {
                // Make sure FullStory object exists.
                if (window.FS && window.FS.getCurrentSessionURL) {
                    event.addMetadata("fullstory", {
                        urlAtTime: window.FS.getCurrentSessionURL(true),
                    });
                }
            },
        });

        // start vee-validate
        setInteractionMode("eager");
        Vue.component("ValidationProvider", ValidationProvider);
        Vue.component("ValidationObserver", ValidationObserver);

        extend("integer", integer);
        extend("digits", digits);
        extend("length", length);
        extend("regex", {
            ...regex,
            message: `A minimum 6  and maximum 16 character password is required that contains a combination
            of uppercase and lowercase letters, a number, and a special character excepts whitespace and | .`,
        });
        extend("email", email);
        extend("min", min);
        extend("max", max);
        extend("numeric", numeric);
        extend("confirmed", {
            params: ["target"],
            validate(value, { target }) {
                return value === target;
            },
            message: "{_field_} does not match {target}.",
        });
        extend("phone", {
            validate(value) {
                const reg = /^[1-9]{1}[0-9]{9}$/;
                return reg.test(value);
            },
            message: "Enter a valid 10 digit phone number",
        });
        extend("required", {
            validate(value) {
                return {
                    required: true,
                    valid: ["", null, undefined].indexOf(value) === -1,
                };
            },
            computesRequired: true,
            message: "{_field_} is required",
        });
        extend("minmax", {
            validate(value, { min, max }) {
                return value.length >= min && value.length <= max;
            },
            params: ["min", "max"],
            message: "{_field_} must be between {min} and {max} characters",
        });
        extend("min_value", {
            validate(value, { min }) {
                return value >= min;
            },
            params: ["min"],
            message: "{_field_} must be greater than or equal to {min}",
        });
        extend("max_value", {
            validate(value, { max }) {
                return value <= max;
            },
            params: ["max"],
            message: "{_field_} must be less than or equal to {max}",
        });
        extend("min_miles", {
            validate(value, { min }) {
                let numeralValue = numeral(value);
                const minValue = numeral(min);
                return numeralValue.value() >= minValue.value();
            },
            params: ["min"],
            message: "Must be at least 1,000 miles",
        });
        extend("min_char", {
            validate(value, { min }) {
                return value == null || value.length >= min;
            },
            params: ["min"],
            message: "min",
        });
        extend("max_char", {
            validate(value, { max }) {
                return value == null || value.length <= max;
            },
            params: ["max"],
            message: "{_field_} must be less than or equal to {max} characters.",
        });
        extend("passwordValidate", checkPassword);

        if (environment === "local") {
            const VueAxe = require("vue-axe").default;
            Vue.use(VueAxe, {
                allowConsoleClears: false,
                auto: true,
            });
        }

        Vue.use(VueMq, {
            breakpoints: {
                // synced with Bootstrap
                xs: 576,
                sm: 767,
                md: 991,
                lg: 1199,
                xl: Infinity,
            },
            defaultBreakpoint: "xs", // customize this for SSR
        });
        Vue.use(Vuex);
        Vue.use(BootstrapVue);
        Vue.use(LoadScript);
        Vue.use(vueMoment, {
            moment,
        });

        Vue.mixin(pageConfig);

        Vue.filter("abs", (value) => {
            return Math.abs(value);
        });
    }
}

export default new UpgradePlugin();
