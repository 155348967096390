<template>
    <div>
        <modal
            ref="modal"
            v-bind="$attrs"
            size="md"
            :title="title"
            :subtitle="subtitle"
            centered
            hide-footer
            no-stacking
            @show="onShow"
        >
            <div v-if="showConfirmation" class="d-flex flex-column justify-content-center">
                <primary-btn block @click.native="$refs.modal.hide()"> Close Window</primary-btn>
            </div>
            <div v-else>
                <div class="form-body d-flex justify-content-center">
                    <personal-shopper
                        name="Claire R."
                        :employment-start-year="2016"
                        :photo="personalShopperPhoto"
                        spanish-speaking
                    />
                </div>
                <div v-if="!loaded" class="text-center my-5">
                    <b-spinner variant="primary" />
                </div>
                <validation-observer v-else-if="callCenterIsOpen" ref="observer" v-slot="{ handleSubmit }">
                    <b-form id="call-me-now-form" novalidate @submit.prevent="handleSubmit(submit)">
                        <div class="d-flex justify-content-center">
                            <div class="form-body d-flex">
                                <div class="phone">
                                    <b-form-group class="mt-1" label="First Name" label-for="first-name">
                                        <validation-provider
                                            v-slot="validationContext"
                                            vid="firstName"
                                            name="First Name"
                                            :rules="{
                                                required: true,
                                            }"
                                            mode="passive"
                                        >
                                            <b-input id="first-name" v-model="form.firstName" />
                                            <div v-if="validationContext.invalid" class="invalid-feedback d-block">
                                                {{ validationContext.errors[0] }}
                                            </div>
                                        </validation-provider>
                                    </b-form-group>

                                    <b-form-group class="mt-1" label="Last Name" label-for="last-name">
                                        <validation-provider
                                            v-slot="validationContext"
                                            vid="lastName"
                                            name="Last Name"
                                            :rules="{
                                                required: true,
                                            }"
                                            mode="passive"
                                        >
                                            <b-input id="last-name" v-model="form.lastName" />
                                            <div v-if="validationContext.invalid" class="invalid-feedback d-block">
                                                {{ validationContext.errors[0] }}
                                            </div>
                                        </validation-provider>
                                    </b-form-group>

                                    <b-form-group class="mt-1" label="You can call me at" label-for="phone-number">
                                        <validation-provider
                                            v-slot="validationContext"
                                            vid="phoneNumber"
                                            name="Phone Number"
                                            :rules="{
                                                required: true,
                                                regex: /^([0-9]{10})$/,
                                            }"
                                            mode="passive"
                                        >
                                            <!-- prettier-ignore-attribute :state -->
                                            <input-mask
                                                id="phoneNumber"
                                                ref="phoneNumber"
                                                v-model="form.phoneNumber"
                                                :mask="'(###) ###-####'"
                                                maxlength="14"
                                                class="form-control d-inline-block"
                                                :state="validationContext.invalid && validationContext.validated ? false : null"
                                                placeholder="(XXX) XXX-XXXX"
                                                autocomplete="tel-area-code"
                                                @blur="validationContext.validate()"
                                            />
                                            <div v-if="validationContext.invalid" class="invalid-feedback d-block">
                                                {{ validationContext.errors[0] }}
                                            </div>
                                        </validation-provider>
                                    </b-form-group>
                                </div>
                            </div>
                        </div>
                        <primary-btn ref="loadingBtn" block type="submit" class="mb-2" :loading="submitting">
                            Call Me
                        </primary-btn>
                    </b-form>
                </validation-observer>
                <div v-else class="text-center">
                    Sorry, the Call Center is currently closed, please try again later.
                </div>
            </div>
            <div v-if="isError" class="d-block invalid-feedback text-center">
                Sorry, there was an unexpected error, please close this window and try again shortly.
            </div>
        </modal>
    </div>
</template>

<script>
import Modal from "Components/Modal";
import api from "@/api";
import veeValidateUtils from "@/libs/veeValidateUtils";
import formValidation from "@/mixins/formValidation";
import PersonalShopper from "./PersonalShopper";
import PersonalShopperImage from "../images/Claire.png";
import { TheMask as InputMask } from "vue-the-mask";
import PrimaryBtn from "Components/Buttons/PrimaryBtn";
import lodashGet from "lodash/get";
import lodashIsNil from "lodash/isNil";

export default {
    name: "CallMeNowModal",
    components: {
        PrimaryBtn,
        Modal,
        PersonalShopper,
        InputMask,
    },
    mixins: [formValidation],
    data() {
        return {
            form: {
                firstName: null,
                lastName: null,
                phoneNumber: null,
                userTimeZone: null,
            },
            submitting: false,
            personalShopperPhoto: PersonalShopperImage,
            callCenterModel: {
                currentlyOpen: null,
            },
            showConfirmation: false,
            isError: false,
        };
    },
    computed: {
        loaded() {
            return !lodashIsNil(this.callCenterModel.currentlyOpen);
        },
        callCenterIsOpen() {
            return lodashGet(this.callCenterModel, "currentlyOpen", false);
        },
        title() {
            return this.showConfirmation ? "Call in Queue" : "Please call me";
        },
        subtitle() {
            return this.showConfirmation
                ? "Your call is in queue. Someone will be with you shortly."
                : "We are looking forward to speaking with you!";
        },
    },
    methods: {
        onShow() {
            this.reset();
            this.form.userTimeZone = this.$moment.tz.guess(true);
            this.loadCallCenterModel();
        },
        loadCallCenterModel() {
            const userTimeZone = this.form.userTimeZone;
            api.get("/lead/call-center", { userTimeZone })
                .then((resp) => {
                    this.callCenterModel = lodashGet(resp, "data", this.callCenterModel);
                })
                .catch((err) => {
                    this.isError = true;
                    console.error("error loading contact me now modal", err);
                });
        },
        reset() {
            Object.assign(this.$data, this.$options.data.apply(this));
        },

        submit() {
            this.submitting = true;
            const endPoint = `/lead/contact-shopper/call-me-back/now/unauthenticated`;
            api.post(endPoint, this.form)
                .then(() => {
                    this.submitting = false;
                    this.showConfirmation = true;
                })
                .catch((err) => {
                    this.submitting = false;
                    console.error("error submitting email dealer form", err);
                    if (err && err.response && err.response.status === 400) {
                        //validation error
                        this.$refs.observer.setErrors(veeValidateUtils.convertErrorToObserverErrors(err));
                    } else {
                        this.isError = true;
                    }
                });
        },
        handlePhoneNumberInputComplete(evt, validationContext) {
            validationContext.validate();
            this.$refs.loadingBtn.$el.focus();
        },
    },
};
</script>

<style lang="scss" scoped>
#call-me-now-form {
    .form-body {
        width: px2rem(330);

        .phone {
            width: 100%;
            margin-bottom: px2rem(24);
        }
    }
}
</style>
