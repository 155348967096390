<template>
    <div class="stock-type-flag text-capitalize">{{ formatStockType }}</div>
</template>
<script>
export default {
    name: "StockTypeFlag",
    props: {
        stockType: {
            type: String,
            required: true,
        },
    },
    computed: {
        formatStockType() {
            return this.stockType.toLowerCase();
        },
    },
};
</script>
<style lang="scss">
.stock-type-flag {
    color: $gray-600;
    font-size: px2rem(12);
    line-height: px2rem(14);
}
</style>
